import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './AdminPanelSidebar.scss';

const AdminPanelSideBar = () => {
    const [isExpanded, setIsExpanded] = useState(true);

    const menuItems = [
        { 
            path: '/admin-panel/home', 
            name: 'Dashboard', 
            icon: <span className="material-symbols-outlined">dashboard</span> 
        },
        {
            path:'/admin-panel/services',
            name:"Services",
            icon:<span className="material-symbols-outlined">category</span>
        },
        { 
            path: '/admin-panel/orders', 
            name: 'Orders', 
            icon: <span className="material-symbols-outlined">shopping_cart</span> 
        },
        { 
            path: '/admin-panel/drivers', 
            name: 'Drivers', 
            icon: <span className="material-symbols-outlined">local_shipping</span> 
        },
        { 
            path: '/admin-panel/locations', 
            name: 'Locations', 
            icon: <span className="material-symbols-outlined">store</span> 
        },
    ];

    return (
        <div className={`admin-panel-sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <button 
                className="toggle-button"
                onClick={() => setIsExpanded(!isExpanded)}
                title={isExpanded ? "Collapse menu" : "Expand menu"}
            >
                <span className="material-symbols-outlined">
                    {isExpanded ? 'chevron_left' : 'chevron_right'}
                </span>
            </button>

            {/* <div className="sidebar-header">
                {isExpanded && <h2 className="logo">Admin</h2>}
            </div> */}

            <nav className="admin-panel-sidebar-menu">
                {menuItems.map((item, index) => (
                    <NavLink 
                        key={index}
                        to={item.path}
                        className={({ isActive }) => 
                            `admin-panel-menu-item ${isActive ? 'active' : ''}`
                        }
                        end={item.path === '/admin-panel'}
                    >
                        <span className="icon">{item.icon}</span>
                        <span className="label" style={{
                            opacity: isExpanded ? 1 : 0,
                            transition: 'opacity 0.3s'
                        }}>
                            {item.name}
                        </span>
                        {!isExpanded && (
                            <div className="tooltip">
                                {item.name}
                            </div>
                        )}
                    </NavLink>
                ))}
            </nav>
        </div>
    );
};

export default AdminPanelSideBar;
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useDocument } from "../firebase files/hooks/useDocument";
import OwlCarousel from "react-owl-carousel";
import "aos/dist/aos.css";
import AOS from "aos";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
    projectFirestore,
    projectStorage,
    timestamp,
} from "../firebase config/config";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaStar } from "react-icons/fa";
import useCart from "../firebase files/hooks/useCart";
import useWishlist from "../firebase files/hooks/useWishlist";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import "./ProductsVersion.scss";
import CustomSnackbar from './CustomSnackbar';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// const ratings = ["⭐", "⭐⭐", "⭐⭐⭐", "⭐⭐⭐⭐", "⭐⭐⭐⭐⭐"];

const Products = ({ maxProductsToShow, showInactive }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    // Add these new states
    const [categoryScrollPosition, setCategoryScrollPosition] = useState(0);
    const categoryScrollRef = useRef(null);

    // Add these new functions
    const handleScrollLeft = () => {
        if (categoryScrollRef.current) {
            const itemWidth = categoryScrollRef.current.clientWidth / 3; // Width of one item
            categoryScrollRef.current.scrollBy({
                left: -itemWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleScrollRight = () => {
        if (categoryScrollRef.current) {
            const itemWidth = categoryScrollRef.current.clientWidth / 3; // Width of one item
            categoryScrollRef.current.scrollBy({
                left: itemWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleScroll = () => {
        if (categoryScrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = categoryScrollRef.current;
            setCategoryScrollPosition(scrollLeft);
        }
    };

    const queryParams = new URLSearchParams(location.search);
    const subCategoryFromUrl = queryParams.get("subcategory");
    const categoryFromUrl = queryParams.get("category");

    const { user } = useAuthContext();

    // Fetch data from the "products" collection
    const { documents: products, error: productsError } = useCollection(
        "products",
        null,
        ["order"]
    );
    console.log("products", products);

    // Fetch products category and sub category
    const { documents: productsCategories, error: productsCategoriesError } =
        useCollection("m_categories");

    const { document: dbProductDetailsDocuments, error: dbProductDetailsError } =
        useDocument("settings", "serviceDetails");

    // Ensure only the specified number of products are rendered
    const limitedProducts = products && products.slice(0, maxProductsToShow);

    const { updateDocument } = useFirestore("products");

    // cart
    const {
        addDocument: addCartDocument,
        updateDocument: updateCartDocument,
        deleteDocument: deleteCartDocument,
    } = useFirestore("cart");

    const {
        addDocument: addWishlistDocument,
        deleteDocument: deleteWishlistDocument,
    } = useFirestore("wishlist");

    const { document, error } = useDocument("settings", "currency");
    const { document: apptype, error: apptypeerror } = useDocument(
        "settings",
        "AppType"
    );

    // CATEGORIES OF PRODUCTS
    const [selectedFilterCategory, setSelectedFilterCategory] = useState(
        categoryFromUrl || ""
    );
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownSearch, setDropdownSearch] = useState("");
    const [isSubCategories, setSubCategories] = useState([]);
    const [selectedFilterSubCategory, setSelectedFilterSubCategory] = useState(
        subCategoryFromUrl || ""
    );
    console.log("all sub cat : ", isSubCategories);

    const [productName, setProductName] = useState("");
    const [selectedProductImage, setProductSelectedImage] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");

    // State for add booking card dropdown
    const [selectedAddCategory, setSelectedAddCategory] = useState("");
    const [selectedAddSubCategory, setSelectedAddSubCategory] = useState("");
    const [isCardVisible, setIsCardVisible] = useState(false);

    const [query, setQuery] = useState("");


    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, []);

    // SEARCH AND OTHER FILTERS CODE START

    const handleSearchInputChange = (event) => {
        const searchTerm = event.target.value;
        setQuery(searchTerm);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
        if (!dropdownOpen) {
            setDropdownSearch(""); // Clear dropdown search input when opening
            // Filter to only include categories with status "active"
            const activeCategories = productsCategories?.filter(
                (categoryDoc) => categoryDoc.status === "active"
            );
            setFilteredCategories(activeCategories); // Set filtered categories to active ones
        }
    };

    const handleFilterDropdownSearch = (event) => {
        const searchValue = event.target.value;
        setDropdownSearch(searchValue);

        // Filter categories based on search term and ensure they're active
        const filtered = productsCategories?.filter(
            (categoryDoc) =>
                categoryDoc.status === "active" &&
                categoryDoc.category.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredCategories(filtered);
    };

    const handleFilterDropdownItemClick = (categoryValue) => {
        console.log("clicked cat : ", categoryValue);

        setSelectedFilterCategory(categoryValue);
        setSelectedFilterSubCategory(""); // Reset subcategory when category changes

        const selectedCategoryDoc = productsCategories.find(
            (cat) => cat.category === categoryValue
        );

        console.log("selected cat doc : ", selectedCategoryDoc);

        const subCategories = selectedCategoryDoc?.subCategories || [];

        console.log("sub cat : ", subCategories);

        setSubCategories(subCategories);

        setDropdownOpen(false);
    };

    // Handle click on subcategory
    const handleSubCategoryClick = (subCategories) => {
        console.log("clicked sub cat name : ", subCategories);

        setSelectedFilterSubCategory(subCategories);
    };

    useEffect(() => {
        setSelectedFilterCategory(categoryFromUrl || "");
        setSelectedFilterSubCategory(subCategoryFromUrl || "");
    }, [categoryFromUrl, subCategoryFromUrl]);

    // Filter products based on subcategory from URL, selected category, and search query
    const filteredProducts =
        products &&
        products
            .filter((product) => {
                if (
                    selectedFilterCategory &&
                    product.category !== selectedFilterCategory
                ) {
                    return false;
                }
                if (
                    selectedFilterSubCategory &&
                    product.subCategory !== selectedFilterSubCategory
                ) {
                    return false;
                }
                if (
                    query &&
                    !(
                        product.name.toLowerCase().includes(query.toLowerCase()) ||
                        product.short_description
                            .toLowerCase()
                            .includes(query.toLowerCase()) ||
                        product.subCategory.toLowerCase().includes(query.toLowerCase()) ||
                        product.category.toLowerCase().includes(query.toLowerCase())
                    )
                ) {
                    return false;
                }
                return true;
            })
            .slice(0, maxProductsToShow);

    const toggleCardVisibility = () => {
        setIsCardVisible(!isCardVisible);
    };
    // SEARCH AND OTHER FILTERS CODE END

    const handleProductClick = (subcategory) => {
        // Get the existing subcategories array from localStorage or initialize an empty array if it doesn't exist
        let subcategories =
            JSON.parse(localStorage.getItem("clickedSubcategories")) || [];

        // Check if the subcategory already exists in the array
        const index = subcategories.indexOf(subcategory);

        // If the subcategory exists, remove it from its current position
        if (index !== -1) {
            subcategories.splice(index, 1);
        }

        // Add the new subcategory to the end of the array
        subcategories.push(subcategory);

        // If the array length exceeds 2, remove the first element
        if (subcategories.length > 2) {
            subcategories.shift();
        }

        // Update localStorage with the new subcategories array
        localStorage.setItem("clickedSubcategories", JSON.stringify(subcategories));

        console.log(`Subcategory: ${subcategory}`);
        console.log(`Recent subcategories: ${subcategories}`);
    };



    // snack bar functionality
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'error'
    });

    // Add snackbar close handler
    const handleSnackbarClose = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    // modal functionality

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // add new product functionality

    // Update the newProduct state to include buyerType
    const [newProduct, setNewProduct] = useState({
        name: "",
        shortDescription: "",
        price: "",
        category: "",
        subCategory: "",
        images: [],
        buyerType: "", // Add this line
    });
    const [imageFiles, setImageFiles] = useState([]);
    const [imageUploadProgress, setImageUploadProgress] = useState(0);

    const { addDocument } = useFirestore("products");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewProduct((prev) => ({ ...prev, [name]: value }));
    };

    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setImageFiles((prevFiles) => {
                const newFiles = [...prevFiles];
                newFiles[index] = file;
                return newFiles;
            });
        }
    };

    const handleRemoveImage = (index) => {
        setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    // Add handler for radio button changes
    const handleBuyerTypeChange = (e) => {
        setNewProduct((prev) => ({ ...prev, buyerType: e.target.value }));
    };

    // Update the handleAddProductNew function to include buyerType
    const handleAddProductNew = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            // First, add the product to Firestore to get the product ID
            const productRef = await projectFirestore.collection("products").add({
                name: newProduct.name,
                short_description: newProduct.shortDescription,
                price: newProduct.price,
                discount: "",
                rating: "4.5",
                order: 0,
                external_url: "",
                description: "Add Full Description About Product",
                status: "inactive",
                createdAt: timestamp.fromDate(new Date()),
                images: [],
                category: newProduct.category,
                subCategory: newProduct.subCategory,
                buyerType: newProduct.buyerType, // Add this line
                newArrivals: false,
                popularProducts: false,
                topSellingProducts: false,
                city: "",
            });

            const productId = productRef.id;

            // Now upload images to the product-specific folder
            const imageUrls = await Promise.all(
                imageFiles.map((file) => uploadImage(file, productId))
            );

            // Update the product document with the image URLs
            await productRef.update({ images: imageUrls });

            // Reset form and close modal
            setNewProduct({
                name: "",
                shortDescription: "",
                price: "",
                category: "",
                subCategory: "",
            });
            setImageFiles([]);
            setImageUploadProgress(0);
            closeModal();
        } catch (error) {
            console.error("Error adding product:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const uploadImage = async (file, productId) => {
        const storageRef = projectStorage.ref(
            `product_img/${productId}/${file.name}`
        );
        const uploadTask = storageRef.put(file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setImageUploadProgress(progress);
                },
                (error) => reject(error),
                async () => {
                    const url = await uploadTask.snapshot.ref.getDownloadURL();
                    resolve(url);
                }
            );
        });
    };

    // hook for wishlist
    const { wishlistItems, addToWishlist, removeFromWishlist } = useWishlist();

    const findWishlistItem = (productId) => {
        return wishlistItems.find((item) => item.productId === productId);
    };

    const handleAddToWishlist = (productId) => {
        addToWishlist(productId);
        setSnackbar({
            open: true,
            message: "Added to Wishlist",
            severity: 'success'
        });
    };

    const handleWishlistRemove = (wishlistItem) => {
        removeFromWishlist(wishlistItem.productId);
        setSnackbar({
            open: true,
            message: "Removed from Wishlist",
            severity: 'success'
        });
    };

    const isProductInWishlist = (productId) => {
        const product =
            wishlistItems &&
            wishlistItems.find((item) => item.productId === productId);
        if (product) {
            return true;
        } else {
            return false;
        }
    };

    // ADD TO CART Function Start
    const { cartItems, addToCart, updateCartItemQuantity, removeFromCart } =
        useCart();

    const isProductInCart = (productId) => {
        return cartItems.some((item) => item.productId === productId);
    };

    const findCartItem = (productId) => {
        return cartItems.find((item) => item.productId === productId);
    };

    const handleAddToCart = async (productId) => {
        try {
            await addToCart(productId);
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error'
            });
        }
    };

    const handleIncrementQuantity = async (cartItem) => {
        try {
            await updateCartItemQuantity(cartItem.id, cartItem.quantity + 1);
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error'
            });
        }
    };

    const handleDecrementQuantity = async (cartItem, removeItem = false) => {
        try {
            if (removeItem) {
                await updateCartItemQuantity(cartItem.id, 0); // This will remove the item
            } else {
                await updateCartItemQuantity(cartItem.id, cartItem.quantity - 1);
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error'
            });
        }
    };

    const handleProductCardClick = (productId) => {
        // Navigate to product details page
        if (
            dbProductDetailsDocuments &&
            dbProductDetailsDocuments.status === true
        ) {
            navigate(`/product-details/${productId}`);
        }
    };

    // Add this check before rendering the add to cart button
    const isOutOfStock = (product) => {
        return product.stock <= 0;
    };

    const isValidQuantity = (product, currentQuantity) => {
        if (!product.minQuantity && !product.maxQuantity) return true;
        const min = product.minQuantity || 1;
        const max = product.maxQuantity || Infinity;
        return currentQuantity >= min && currentQuantity <= max && currentQuantity <= product.stock;
    };

    // Add this new function near your other handlers
    const handleClearCategory = () => {
        setSelectedFilterCategory("");
        setSelectedFilterSubCategory("");
        setDropdownSearch("");
    };

    return (
        <div>
            <section>
                {/* SEARCH AND OTHER FILTERS START  */}
                <div className="ProductsFilterAndSearch">
                    <div className="ProductsFilterAndSearch-filter-card">
                        <div className="ProductsFilterAndSearch-search-bar">
                            <input
                                type="text"
                                value={query}
                                onChange={handleSearchInputChange}
                                placeholder="Search products..."
                            />
                        </div>
                        <div className="ProductsFilterAndSearch-category-dropdown">
                            <div className="dropdown-container">
                                <div
                                    className={`ProductsFilterAndSearch-dropdown-header ${dropdownOpen ? "open" : ""
                                        }`}
                                    onClick={toggleDropdown}
                                >
                                    <span className="ProductsFilterAndSearch-dropdown-header-text">
                                        {selectedFilterCategory || "Select Category"}
                                    </span>
                                </div>
                                {dropdownOpen && (
                                    <div className="ProductsFilterAndSearch-dropdown-content">
                                        <input
                                            type="text"
                                            value={dropdownSearch}
                                            onChange={handleFilterDropdownSearch}
                                            placeholder="Search categories..."
                                            className="ProductsFilterAndSearch-dropdown-search-input"
                                        />
                                        <ul className="ProductsFilterAndSearch-dropdown-list">
                                            {filteredCategories.map((categoryDoc) => (
                                                <li
                                                    key={categoryDoc.id}
                                                    className="ProductsFilterAndSearch-dropdown-item"
                                                    onClick={() =>
                                                        handleFilterDropdownItemClick(categoryDoc.category)
                                                    }
                                                >
                                                    {categoryDoc.category}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>


                    </div>

                    {/* show subcategories name below */}
                    {isSubCategories.length > 0 && (
                        <div className="ProductsFilterAndSearch-subcategory-container">
                            <ul className="ProductsFilterAndSearch-subcategory-list">
                                {isSubCategories.map((subCategories, index) => (
                                    <li
                                        key={index}
                                        className={`ProductsFilterAndSearch-subcategory-item ${subCategories.subCategoryName ===
                                            selectedFilterSubCategory
                                            ? "active"
                                            : ""
                                            }`}
                                        onClick={() =>
                                            handleSubCategoryClick(subCategories.subCategoryName)
                                        }
                                    >
                                        <img
                                            src={subCategories.subCategoryImgUrl}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "50%",
                                            }}
                                            alt={subCategories.subCategoryName}
                                        />
                                        &nbsp;
                                        <p>{subCategories.subCategoryName}</p>
                                        <span className="ProductsFilterAndSearch-arrow-icon">
                                            {subCategories.subCategoryName ===
                                                selectedFilterSubCategory ? (
                                                <span className="material-symbols-outlined">
                                                    arrow_downward
                                                </span>
                                            ) : (
                                                <span className="material-symbols-outlined">north</span>
                                            )}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                {/* SEARCH AND OTHER FILTERS END  */}

                <div className="category-scroll-container">
                    <div className="category-scroll-header">
                        <div className="category-header-left">
                            <span>Categories</span>
                            <IconButton
                                onClick={handleClearCategory}
                                title="Show all products"
                                className="refresh-button"
                            >
                                <span className="material-symbols-outlined">refresh</span>
                            </IconButton>
                        </div>
                        <div className="category-scroll-controls">
                            <IconButton
                                onClick={handleScrollLeft}
                                disabled={categoryScrollPosition <= 0}
                                className="scroll-button"
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                            <div
                                className="category-chips-container"
                                ref={categoryScrollRef}
                                onScroll={handleScroll}
                            >
                                {productsCategories?.filter(cat => cat.status === "active").map((category) => (
                                    <div
                                        key={category.id}
                                        className={`category-chip ${selectedFilterCategory === category.category ? 'active' : ''}`}
                                        onClick={() => handleFilterDropdownItemClick(category.category)}
                                    >
                                        <img
                                            src={category.categoryImgUrl}
                                            alt={category.category}
                                        />
                                        <span>{category.category}</span>
                                    </div>
                                ))}
                            </div>
                            <IconButton
                                onClick={handleScrollRight}
                                disabled={
                                    categoryScrollRef.current &&
                                    categoryScrollPosition + categoryScrollRef.current.clientWidth >=
                                    categoryScrollRef.current.scrollWidth
                                }
                                className="scroll-button"
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>

                {/* ADD NEW PRODUCT BUTTON START */}
                {user && user.role === "admin" ? (
                    <div className="add-new-product-btn-container" onClick={openModal}>
                        <button title="Add New Product">+</button>
                    </div>
                ) : (
                    ""
                )}
                {/* ADD NEW PRODUCT BUTTON END */}

                {/*ADD PRODUCT MODAL START */}
                {isModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h2>Add New Product</h2>
                            <form onSubmit={handleAddProductNew} className="add-product-form">
                                <div className="product-buyer-type-container">
                                    <div>
                                        <span>Select a Role </span>
                                        <span>(Only selected role can see this product)</span>
                                    </div>
                                    <div className="product-buyer-type-options">
                                        <label>
                                            <input
                                                type="radio"
                                                name="buyerType"
                                                value="dealer"
                                                checked={newProduct.buyerType === "dealer"}
                                                onChange={handleBuyerTypeChange}
                                                required
                                            />
                                            Dealer
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="buyerType"
                                                value="retailer"
                                                checked={newProduct.buyerType === "retailer"}
                                                onChange={handleBuyerTypeChange}
                                                required
                                            />
                                            Retailer
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="buyerType"
                                                value="customer"
                                                checked={newProduct.buyerType === "customer"}
                                                onChange={handleBuyerTypeChange}
                                                required
                                            />
                                            Customer
                                        </label>
                                    </div>
                                </div>
                                <div className="image-upload-container">
                                    {[0, 1, 2, 3, 4].map((index) => (
                                        <div key={index} className="image-upload-box">
                                            {imageFiles[index] ? (
                                                <div className="image-preview">
                                                    <img
                                                        src={URL.createObjectURL(imageFiles[index])}
                                                        alt={`Preview ${index}`}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveImage(index)}
                                                        className="remove-image-btn"
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ) : (
                                                <>
                                                    <input
                                                        type="file"
                                                        id={`image-upload-${index}`}
                                                        accept="image/*"
                                                        onChange={(e) => handleImageChange(e, index)}
                                                        style={{ display: "none" }}
                                                    />
                                                    <label
                                                        htmlFor={`image-upload-${index}`}
                                                        className="upload-label"
                                                    >
                                                        +<span>Add Image</span>
                                                    </label>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <input
                                    type="text"
                                    name="name"
                                    value={newProduct.name}
                                    onChange={handleInputChange}
                                    placeholder="Product Name"
                                    required
                                    className="form-input"
                                />
                                <textarea
                                    name="shortDescription"
                                    value={newProduct.shortDescription}
                                    onChange={handleInputChange}
                                    placeholder="Short Description"
                                    className="form-input"
                                    rows="3"
                                ></textarea>
                                <input
                                    type="number"
                                    name="price"
                                    value={newProduct.price}
                                    onChange={handleInputChange}
                                    placeholder="Price"
                                    required
                                    className="form-input"
                                />
                                <div className="dropdown-row">
                                    <select
                                        name="category"
                                        value={newProduct.category}
                                        onChange={handleInputChange}
                                        required
                                        className="form-input"
                                    >
                                        <option value="">Select Category</option>
                                        {productsCategories &&
                                            productsCategories
                                                .filter((cat) => cat.status === "active") // Only show active categories
                                                .map((cat) => (
                                                    <option key={cat.id} value={cat.category}>
                                                        {cat.category}
                                                    </option>
                                                ))}
                                    </select>
                                    <select
                                        name="subCategory"
                                        value={newProduct.subCategories}
                                        onChange={handleInputChange}
                                        required
                                        className="form-input"
                                    >
                                        <option value="">Select Subcategory</option>
                                        {productsCategories &&
                                            productsCategories
                                                .find((cat) => cat.category === newProduct.category)
                                                ?.subCategories.filter(
                                                    (subCat) => subCat.status === "active"
                                                ) // Only show active subcategories
                                                .map((subCat) => (
                                                    <option
                                                        key={subCat.subCategoryName}
                                                        value={subCat.subCategoryName}
                                                    >
                                                        {subCat.subCategoryName}
                                                    </option>
                                                ))}
                                    </select>
                                </div>
                                <div className="form-actions">
                                    <button
                                        type="button"
                                        onClick={closeModal}
                                        className="btn-cancel"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn-save"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Adding..." : "Save"}
                                    </button>
                                </div>
                            </form>
                            {imageUploadProgress > 0 && (
                                <p>Upload progress: {imageUploadProgress.toFixed(2)}%</p>
                            )}
                        </div>
                    </div>
                )}
                {/*add product modal end */}

                <div className="container">
                    {/* ABOVE ACTIVE PRODUCTS START*/}
                    <div>
                        {user && user.role === "admin" && (
                            <div className="product-page-heading-title">
                                <br />
                                <h2
                                    style={{
                                        fontSize: "20px",
                                        marginLeft: "0px",
                                        color: "var(--heading-color)",
                                    }}
                                >
                                    Active Products
                                </h2>
                            </div>
                        )}
                        <div className="row">
                            {filteredProducts &&
                                filteredProducts.map((doc, index) =>
                                    (user &&
                                        user.role === "admin" &&
                                        doc &&
                                        doc.status === "active") ||
                                        (doc && doc.status === "active") ? (
                                        <div className="col-6 col-sm-6 col-lg-3 mt-4 corporateTwo-cards-container">
                                            <div className="corporateTwo-card-2">
                                                <div
                                                    className="card-2-clickable-area"
                                                    onClick={() => handleProductCardClick(doc.id)}
                                                />
                                                <div className="card-2-image-container">
                                                    <OwlCarousel
                                                        key={doc.id} // Adding key to force re-render
                                                        nav={false}
                                                        dots={false}
                                                        loop
                                                        autoplay
                                                        autoplayTimeout={3000}
                                                        items={1}
                                                    >
                                                        {doc.images &&
                                                            doc.images.map((imageUrl, index) => (
                                                                <div key={index}>
                                                                    <img
                                                                        src={imageUrl}
                                                                        alt={`Product Image ${index}`}
                                                                    />
                                                                </div>
                                                            ))}
                                                    </OwlCarousel>

                                                    {user && user.role === "admin" ? (
                                                        <div
                                                            className={`${doc.status && doc.status == "inactive"
                                                                ? "card-2-inactive-status"
                                                                : "card-2-active-status"
                                                                }`}
                                                        >
                                                            <div
                                                                className={`${doc.status && doc.status == "inactive"
                                                                    ? "card-2-inactive-flag"
                                                                    : "card-2-active-flag"
                                                                    }`}
                                                            ></div>
                                                            <span>
                                                                {doc.status && doc.status == "inactive"
                                                                    ? "Inactive"
                                                                    : "Active"}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="card-2-discount-info-text">
                                                            {doc.discount ? `${doc.discount} off` : ""}
                                                        </div>
                                                    )}



                                                    <div className="card-2-rating-info">
                                                        <span>{doc.rating && doc.rating}</span>
                                                        <FaStar color="#fca503" size={20} />
                                                    </div>
                                                </div>

                                                <div className="card-2-info-container">
                                                    <div className="corporatetwo-card-2-title-info">
                                                        <h4 className="corporateTwo-card-2-title">
                                                            {doc.name && doc.name}
                                                        </h4>
                                                        <p className="corporateTwo-card-2-short-desc">
                                                            {doc.short_description && doc.short_description}
                                                        </p>
                                                    </div>

                                                    <div className="card-2-price-info">
                                                        <div className="card-2-selling-price">
                                                            <h3>
                                                                {document && document.symbol}{" "}
                                                                {
                                                                    doc.price
                                                                    -
                                                                    Math.ceil(
                                                                        (doc.price *
                                                                            doc.discount.split("%")[0]) /
                                                                        100
                                                                    )
                                                                }
                                                            </h3>
                                                        </div>

                                                        <div className="card-2-discount-info">
                                                            <p>
                                                                <del>
                                                                    {document && document.symbol} {doc.price && doc.price}
                                                                </del>
                                                            </p>
                                                            {user && user.role == "admin" ? (
                                                                <span>{doc.discount && doc.discount} Off</span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="card-2-important-info">
                                                        {user && user ? (
                                                            <>
                                                                {isOutOfStock(doc) ? (
                                                                    <button
                                                                        className="card-2-cart-btn out-of-stock"
                                                                        disabled
                                                                        title="Out of Stock"
                                                                    >
                                                                        Out of Stock
                                                                    </button>
                                                                ) : isProductInCart(doc.id) ? (
                                                                    <div className="corporateTwo-card-2-quantity">
                                                                        <button
                                                                            className="corporateTwo-card-2-btn"
                                                                            onClick={() => {
                                                                                // If at minimum quantity, remove the item
                                                                                const cartItem = findCartItem(doc.id);
                                                                                if (cartItem.quantity <= (doc.minQuantity || 1)) {
                                                                                    handleDecrementQuantity(cartItem, true); // Pass true to indicate removal
                                                                                } else {
                                                                                    handleDecrementQuantity(cartItem);
                                                                                }
                                                                            }}
                                                                        // Remove the disabled condition
                                                                        // disabled={findCartItem(doc.id).quantity <= (doc.minQuantity || 1)}
                                                                        >
                                                                            -
                                                                        </button>
                                                                        <input
                                                                            type="text"
                                                                            value={findCartItem(doc.id).quantity}
                                                                            readOnly
                                                                        />
                                                                        <button
                                                                            className="corporateTwo-card-2-btn"
                                                                            onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                                                            disabled={findCartItem(doc.id).quantity >= (doc.maxQuantity || doc.stock)}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <button
                                                                        className="card-2-cart-btn"
                                                                        onClick={() => handleAddToCart(doc.id)}
                                                                        title="Add to Cart"
                                                                    >
                                                                        <span>Cart</span>
                                                                        <span class="material-symbols-outlined">
                                                                            shopping_cart
                                                                        </span>
                                                                    </button>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Link to="/login">
                                                                    <button
                                                                        className="card-2-cart-btn"
                                                                        title="Add to Cart"
                                                                    >
                                                                        <span>Cart</span>
                                                                        <span class="material-symbols-outlined">
                                                                            shopping_cart
                                                                        </span>
                                                                    </button>
                                                                </Link>
                                                            </>
                                                        )}

                                                        {isProductInWishlist(doc.id) ? (
                                                            <button
                                                                className="card-2-detail-btn removewishlist-btn "
                                                                title="Remove from Wishlist"
                                                                onClick={() =>
                                                                    handleWishlistRemove(findWishlistItem(doc.id))
                                                                }
                                                            >
                                                                <span class="material-symbols-outlined">
                                                                    bookmark
                                                                </span>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="card-2-detail-btn addwishlist-btn"
                                                                title="Add to Wishlist"
                                                                onClick={() => handleAddToWishlist(doc.id)}
                                                            >
                                                                <span class="material-symbols-outlined">
                                                                    bookmark
                                                                </span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                )}
                        </div>
                    </div>
                    {/* ABOVE ACTIVE PRODUCTS END*/}

                    {/* POPULAR PRODUCTS START*/}
                    {showInactive || (
                        <>
                            {filteredProducts &&
                                filteredProducts.some(
                                    (doc) => doc.popularProducts === true
                                ) && (
                                    <div>
                                        <br />
                                        <hr />
                                        <div className="product-page-heading-title">
                                            <h2
                                                style={{
                                                    fontSize: "20px",
                                                    marginLeft: "0px",
                                                    color: "var(--heading-color)",
                                                }}
                                            >
                                                Popular Products
                                            </h2>
                                            <br />
                                        </div>
                                        <div className="row">
                                            {filteredProducts.map((doc, index) =>
                                                (user &&
                                                    user.role === "admin" &&
                                                    doc &&
                                                    doc.popularProducts === true) ||
                                                    (doc && doc.popularProducts === true) ? (
                                                    <div className=" col-6 col-sm-6 col-lg-3 corporateTwo-cards-container">
                                                        <div className="corporateTwo-card-2">
                                                            <div
                                                                className="card-2-clickable-area"
                                                                onClick={() => handleProductCardClick(doc.id)}
                                                            />
                                                            <div className="card-2-image-container">
                                                                <OwlCarousel
                                                                    key={doc.id} // Adding key to force re-render
                                                                    nav={false}
                                                                    dots={false}
                                                                    loop
                                                                    autoplay
                                                                    autoplayTimeout={3000}
                                                                    items={1}
                                                                >
                                                                    {doc.images &&
                                                                        doc.images.map((imageUrl, index) => (
                                                                            <div key={index}>
                                                                                <img
                                                                                    src={imageUrl}
                                                                                    alt={`Product Image ${index}`}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                </OwlCarousel>

                                                                {user && user.role === "admin" ? (
                                                                    <div
                                                                        className={`${doc.status && doc.status == "inactive"
                                                                            ? "card-2-inactive-status"
                                                                            : "card-2-active-status"
                                                                            }`}
                                                                    >
                                                                        <div
                                                                            className={`${doc.status && doc.status == "inactive"
                                                                                ? "card-2-inactive-flag"
                                                                                : "card-2-active-flag"
                                                                                }`}
                                                                        ></div>
                                                                        <span>
                                                                            {doc.status && doc.status == "inactive"
                                                                                ? "InActive"
                                                                                : "Active"}
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="card-2-discount-info-text">
                                                                        {doc.discount ? `${doc.discount} off` : ""}
                                                                    </div>
                                                                )}

                                                                <div className="card-2-rating-info">
                                                                    <span>{doc.rating && doc.rating}</span>
                                                                    <FaStar color="#fca503" size={20} />
                                                                </div>
                                                            </div>

                                                            <div className="card-2-info-container">
                                                                <div className="corporatetwo-card-2-title-info">
                                                                    <h4 className="corporateTwo-card-2-title">
                                                                        {doc.name && doc.name}
                                                                    </h4>
                                                                    <p className="corporateTwo-card-2-short-desc">
                                                                        {doc.short_description &&
                                                                            doc.short_description}
                                                                    </p>
                                                                </div>

                                                                <div className="card-2-price-info">
                                                                    <div className="card-2-selling-price">
                                                                        <h3>
                                                                            {document && document.symbol}{" "}
                                                                            {
                                                                                doc.price
                                                                                -
                                                                                Math.ceil(
                                                                                    (doc.price *
                                                                                        doc.discount.split("%")[0]) /
                                                                                    100
                                                                                )
                                                                            }
                                                                        </h3>
                                                                    </div>

                                                                    <div className="card-2-discount-info">
                                                                        <p>
                                                                            <del>
                                                                                {document && document.symbol} {doc.price && doc.price}
                                                                            </del>
                                                                        </p>
                                                                        {user && user.role == "admin" ? (
                                                                            <span>
                                                                                {doc.discount && doc.discount} Off
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="card-2-important-info">
                                                                    {user && user ? (
                                                                        <>
                                                                            {isOutOfStock(doc) ? (
                                                                                <button
                                                                                    className="card-2-cart-btn out-of-stock"
                                                                                    disabled
                                                                                    title="Out of Stock"
                                                                                >
                                                                                    Out of Stock
                                                                                </button>
                                                                            ) : isProductInCart(doc.id) ? (
                                                                                <div className="corporateTwo-card-2-quantity">
                                                                                    <button
                                                                                        className="corporateTwo-card-2-btn"
                                                                                        onClick={() => {
                                                                                            // If at minimum quantity, remove the item
                                                                                            const cartItem = findCartItem(doc.id);
                                                                                            if (cartItem.quantity <= (doc.minQuantity || 1)) {
                                                                                                handleDecrementQuantity(cartItem, true); // Pass true to indicate removal
                                                                                            } else {
                                                                                                handleDecrementQuantity(cartItem);
                                                                                            }
                                                                                        }}
                                                                                    // Remove the disabled condition
                                                                                    // disabled={findCartItem(doc.id).quantity <= (doc.minQuantity || 1)}
                                                                                    >
                                                                                        -
                                                                                    </button>
                                                                                    <input
                                                                                        type="text"
                                                                                        value={findCartItem(doc.id).quantity}
                                                                                        readOnly
                                                                                    />
                                                                                    <button
                                                                                        className="corporateTwo-card-2-btn"
                                                                                        onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                                                                        disabled={findCartItem(doc.id).quantity >= (doc.maxQuantity || doc.stock)}
                                                                                    >
                                                                                        +
                                                                                    </button>
                                                                                </div>
                                                                            ) : (
                                                                                <button
                                                                                    className="card-2-cart-btn"
                                                                                    onClick={() => handleAddToCart(doc.id)}
                                                                                    title="Add to Cart"
                                                                                >
                                                                                    <span>Cart</span>
                                                                                    <span class="material-symbols-outlined">
                                                                                        shopping_cart
                                                                                    </span>
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Link to="/login">
                                                                                <button
                                                                                    className="card-2-cart-btn"
                                                                                    title="Add to Cart"
                                                                                >
                                                                                    <span>Cart</span>
                                                                                    <span class="material-symbols-outlined">
                                                                                        shopping_cart
                                                                                    </span>
                                                                                </button>
                                                                            </Link>
                                                                        </>
                                                                    )}

                                                                    {isProductInWishlist(doc.id) ? (
                                                                        <button
                                                                            className="card-2-detail-btn removewishlist-btn "
                                                                            title="Remove from Wishlist"
                                                                            onClick={() =>
                                                                                handleWishlistRemove(
                                                                                    findWishlistItem(doc.id)
                                                                                )
                                                                            }
                                                                        >
                                                                            <span class="material-symbols-outlined">
                                                                                bookmark
                                                                            </span>
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className="card-2-detail-btn addwishlist-btn"
                                                                            title="Add to Wishlist"
                                                                            onClick={() =>
                                                                                handleAddToWishlist(doc.id)
                                                                            }
                                                                        >
                                                                            <span class="material-symbols-outlined">
                                                                                bookmark
                                                                            </span>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            )}
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                    {/* POPULAR PRODUCTS END*/}

                    {/* TOP SELLING PRODUCTS START*/}
                    {showInactive || (
                        <>
                            {filteredProducts &&
                                filteredProducts.some(
                                    (doc) => doc.topSellingProducts === true
                                ) && (
                                    <div>
                                        <br />
                                        <hr />
                                        <div className="product-page-heading-title">
                                            <h2
                                                style={{
                                                    fontSize: "20px",
                                                    marginLeft: "0px",
                                                    color: "var(--heading-color)",
                                                }}
                                            >
                                                Top Selling Products
                                            </h2>
                                        </div>

                                        <div className="row">
                                            {filteredProducts &&
                                                filteredProducts.map((doc, index) =>
                                                    (user &&
                                                        user.role === "admin" &&
                                                        doc &&
                                                        doc.topSellingProducts === true) ||
                                                        (doc && doc.topSellingProducts === true) ? (
                                                        <div className=" col-6 col-sm-6 col-lg-3 mt-4 corporateTwo-cards-container">
                                                            <div className="corporateTwo-card-2">
                                                                <div
                                                                    className="card-2-clickable-area"
                                                                    onClick={() => handleProductCardClick(doc.id)}
                                                                />
                                                                <div className="card-2-image-container">
                                                                    <OwlCarousel
                                                                        key={doc.id} // Adding key to force re-render
                                                                        nav={false}
                                                                        dots={false}
                                                                        loop
                                                                        autoplay
                                                                        autoplayTimeout={3000}
                                                                        items={1}
                                                                    >
                                                                        {doc.images &&
                                                                            doc.images.map((imageUrl, index) => (
                                                                                <div key={index}>
                                                                                    <img
                                                                                        src={imageUrl}
                                                                                        alt={`Product Image ${index}`}
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                    </OwlCarousel>

                                                                    {user && user.role === "admin" ? (
                                                                        <div
                                                                            className={`${doc.status && doc.status == "inactive"
                                                                                ? "card-2-inactive-status"
                                                                                : "card-2-active-status"
                                                                                }`}
                                                                        >
                                                                            <div
                                                                                className={`${doc.status && doc.status == "inactive"
                                                                                    ? "card-2-inactive-flag"
                                                                                    : "card-2-active-flag"
                                                                                    }`}
                                                                            ></div>
                                                                            <span>
                                                                                {doc.status && doc.status == "inactive"
                                                                                    ? "InActive"
                                                                                    : "Active"}
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="card-2-discount-info-text">
                                                                            {doc.discount
                                                                                ? `${doc.discount} off`
                                                                                : ""}
                                                                        </div>
                                                                    )}



                                                                    <div className="card-2-rating-info">
                                                                        <span>{doc.rating}</span>
                                                                        <FaStar color="#fca503" size={20} />
                                                                    </div>
                                                                </div>

                                                                <div className="card-2-info-container">
                                                                    <div className="corporatetwo-card-2-title-info">
                                                                        <h4 className="corporateTwo-card-2-title">
                                                                            {doc.name && doc.name}
                                                                        </h4>
                                                                        <p className="corporateTwo-card-2-short-desc">
                                                                            {doc.short_description &&
                                                                                doc.short_description}
                                                                        </p>
                                                                    </div>

                                                                    <div className="card-2-price-info">
                                                                        <div className="card-2-selling-price">
                                                                            <h3>
                                                                                {document && document.symbol}{" "}
                                                                                {
                                                                                    doc.price
                                                                                    -
                                                                                    Math.ceil(
                                                                                        (doc.price *
                                                                                            doc.discount.split("%")[0]) /
                                                                                        100
                                                                                    )
                                                                                }
                                                                            </h3>
                                                                        </div>

                                                                        <div className="card-2-discount-info">
                                                                            <p>
                                                                                <del>
                                                                                    {document && document.symbol} {doc.price && doc.price}
                                                                                </del>
                                                                            </p>
                                                                            {user && user.role == "admin" ? (
                                                                                <span>
                                                                                    {doc.discount && doc.discount} Off
                                                                                </span>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="card-2-important-info">
                                                                        {user && user ? (
                                                                            <>
                                                                                {isOutOfStock(doc) ? (
                                                                                    <button
                                                                                        className="card-2-cart-btn out-of-stock"
                                                                                        disabled
                                                                                        title="Out of Stock"
                                                                                    >
                                                                                        Out of Stock
                                                                                    </button>
                                                                                ) : isProductInCart(doc.id) ? (
                                                                                    <div className="corporateTwo-card-2-quantity">
                                                                                        <button
                                                                                            className="corporateTwo-card-2-btn"
                                                                                            onClick={() => {
                                                                                                // If at minimum quantity, remove the item
                                                                                                const cartItem = findCartItem(doc.id);
                                                                                                if (cartItem.quantity <= (doc.minQuantity || 1)) {
                                                                                                    handleDecrementQuantity(cartItem, true); // Pass true to indicate removal
                                                                                                } else {
                                                                                                    handleDecrementQuantity(cartItem);
                                                                                                }
                                                                                            }}
                                                                                        // Remove the disabled condition
                                                                                        // disabled={findCartItem(doc.id).quantity <= (doc.minQuantity || 1)}
                                                                                        >
                                                                                            -
                                                                                        </button>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={findCartItem(doc.id).quantity}
                                                                                            readOnly
                                                                                        />
                                                                                        <button
                                                                                            className="corporateTwo-card-2-btn"
                                                                                            onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                                                                            disabled={findCartItem(doc.id).quantity >= (doc.maxQuantity || doc.stock)}
                                                                                        >
                                                                                            +
                                                                                        </button>
                                                                                    </div>
                                                                                ) : (
                                                                                    <button
                                                                                        className="card-2-cart-btn"
                                                                                        onClick={() => handleAddToCart(doc.id)}
                                                                                        title="Add to Cart"
                                                                                    >
                                                                                        <span>Cart</span>
                                                                                        <span class="material-symbols-outlined">
                                                                                            shopping_cart
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Link to="/login">
                                                                                    <button
                                                                                        className="card-2-cart-btn"
                                                                                        title="Add to Cart"
                                                                                    >
                                                                                        <span>Cart</span>
                                                                                        <span class="material-symbols-outlined">
                                                                                            shopping_cart
                                                                                        </span>
                                                                                    </button>
                                                                                </Link>
                                                                            </>
                                                                        )}

                                                                        {isProductInWishlist(doc.id) ? (
                                                                            <button
                                                                                className="card-2-detail-btn removewishlist-btn "
                                                                                title="Remove from Wishlist"
                                                                                onClick={() =>
                                                                                    handleWishlistRemove(
                                                                                        findWishlistItem(doc.id)
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span class="material-symbols-outlined">
                                                                                    bookmark
                                                                                </span>
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                className="card-2-detail-btn addwishlist-btn"
                                                                                title="Add to Wishlist"
                                                                                onClick={() =>
                                                                                    handleAddToWishlist(doc.id)
                                                                                }
                                                                            >
                                                                                <span class="material-symbols-outlined">
                                                                                    bookmark
                                                                                </span>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                )}
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                    {/* TOP SELLING PRODUCTS END*/}

                    {/* NEW ARRIVALS PRODUCTS START*/}
                    {showInactive || (
                        <>
                            {filteredProducts &&
                                filteredProducts.some((doc) => doc.newArrivals === true) && (
                                    <div>
                                        <br />
                                        <hr />
                                        <div className="product-page-heading-title">
                                            <h2
                                                style={{
                                                    fontSize: "20px",
                                                    marginLeft: "0px",
                                                    color: "var(--heading-color)",
                                                }}
                                            >
                                                New Arrivals Products
                                            </h2>
                                        </div>
                                        <div className="row">
                                            {filteredProducts &&
                                                filteredProducts.map((doc, index) =>
                                                    (user &&
                                                        user.role === "admin" &&
                                                        doc &&
                                                        doc.newArrivals === true) ||
                                                        (doc && doc.newArrivals === true) ? (
                                                        <div className=" col-6 col-sm-6 col-lg-3 mt-4 corporateTwo-cards-container">
                                                            <div className="corporateTwo-card-2">
                                                                <div
                                                                    className="card-2-clickable-area"
                                                                    onClick={() => handleProductCardClick(doc.id)}
                                                                />
                                                                <div className="card-2-image-container">
                                                                    <OwlCarousel
                                                                        key={doc.id} // Adding key to force re-render
                                                                        nav={false}
                                                                        dots={false}
                                                                        loop
                                                                        autoplay
                                                                        autoplayTimeout={3000}
                                                                        items={1}
                                                                    >
                                                                        {doc.images &&
                                                                            doc.images.map((imageUrl, index) => (
                                                                                <div key={index}>
                                                                                    <img
                                                                                        src={imageUrl}
                                                                                        alt={`Product Image ${index}`}
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                    </OwlCarousel>

                                                                    {user && user.role === "admin" ? (
                                                                        <div
                                                                            className={`${doc.status && doc.status == "inactive"
                                                                                ? "card-2-inactive-status"
                                                                                : "card-2-active-status"
                                                                                }`}
                                                                        >
                                                                            <div
                                                                                className={`${doc.status && doc.status == "inactive"
                                                                                    ? "card-2-inactive-flag"
                                                                                    : "card-2-active-flag"
                                                                                    }`}
                                                                            ></div>
                                                                            <span>
                                                                                {doc.status && doc.status == "inactive"
                                                                                    ? "InActive"
                                                                                    : "Active"}
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="card-2-discount-info-text">
                                                                            {doc.discount
                                                                                ? `${doc.discount} off`
                                                                                : ""}
                                                                        </div>
                                                                    )}

                                                                    {/* <div className="corporateTwo-card-wishlist">
                            <span class="material-symbols-outlined">
                              favorite
                            </span>
                          </div> */}

                                                                    <div className="card-2-rating-info">
                                                                        <span>{doc.rating && doc.rating}</span>
                                                                        <FaStar color="#fca503" size={20} />
                                                                    </div>
                                                                </div>

                                                                <div className="card-2-info-container">
                                                                    <div className="corporatetwo-card-2-title-info">
                                                                        <h4 className="corporateTwo-card-2-title">
                                                                            {doc.name && doc.name}
                                                                        </h4>
                                                                        <p className="corporateTwo-card-2-short-desc">
                                                                            {doc.short_description &&
                                                                                doc.short_description}
                                                                        </p>
                                                                    </div>

                                                                    <div className="card-2-price-info">
                                                                        <div className="card-2-selling-price">
                                                                            <h3>
                                                                                {document && document.symbol}{" "}
                                                                                {
                                                                                    doc.price
                                                                                    -
                                                                                    Math.ceil(
                                                                                        (doc.price *
                                                                                            doc.discount.split("%")[0]) /
                                                                                        100
                                                                                    )
                                                                                }
                                                                            </h3>
                                                                        </div>

                                                                        <div className="card-2-discount-info">
                                                                            <p>
                                                                                <del>
                                                                                    {document && document.symbol} {doc.price && doc.price}
                                                                                </del>
                                                                            </p>
                                                                            {user && user.role == "admin" ? (
                                                                                <span>
                                                                                    {doc.discount && doc.discount} Off
                                                                                </span>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="card-2-important-info">
                                                                        {user && user ? (
                                                                            <>
                                                                                {isOutOfStock(doc) ? (
                                                                                    <button
                                                                                        className="card-2-cart-btn out-of-stock"
                                                                                        disabled
                                                                                        title="Out of Stock"
                                                                                    >
                                                                                        Out of Stock
                                                                                    </button>
                                                                                ) : isProductInCart(doc.id) ? (
                                                                                    <div className="corporateTwo-card-2-quantity">
                                                                                        <button
                                                                                            className="corporateTwo-card-2-btn"
                                                                                            onClick={() => {
                                                                                                // If at minimum quantity, remove the item
                                                                                                const cartItem = findCartItem(doc.id);
                                                                                                if (cartItem.quantity <= (doc.minQuantity || 1)) {
                                                                                                    handleDecrementQuantity(cartItem, true); // Pass true to indicate removal
                                                                                                } else {
                                                                                                    handleDecrementQuantity(cartItem);
                                                                                                }
                                                                                            }}
                                                                                        // Remove the disabled condition
                                                                                        // disabled={findCartItem(doc.id).quantity <= (doc.minQuantity || 1)}
                                                                                        >
                                                                                            -
                                                                                        </button>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={findCartItem(doc.id).quantity}
                                                                                            readOnly
                                                                                        />
                                                                                        <button
                                                                                            className="corporateTwo-card-2-btn"
                                                                                            onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                                                                            disabled={findCartItem(doc.id).quantity >= (doc.maxQuantity || doc.stock)}
                                                                                        >
                                                                                            +
                                                                                        </button>
                                                                                    </div>
                                                                                ) : (
                                                                                    <button
                                                                                        className="card-2-cart-btn"
                                                                                        onClick={() => handleAddToCart(doc.id)}
                                                                                        title="Add to Cart"
                                                                                    >
                                                                                        <span>Cart</span>
                                                                                        <span class="material-symbols-outlined">
                                                                                            shopping_cart
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Link to="/login">
                                                                                    <button
                                                                                        className="card-2-cart-btn"
                                                                                        title="Add to Cart"
                                                                                    >
                                                                                        <span>Cart</span>
                                                                                        <span class="material-symbols-outlined">
                                                                                            shopping_cart
                                                                                        </span>
                                                                                    </button>
                                                                                </Link>
                                                                            </>
                                                                        )}

                                                                        {isProductInWishlist(doc.id) ? (
                                                                            <button
                                                                                className="card-2-detail-btn removewishlist-btn "
                                                                                title="Remove from Wishlist"
                                                                                onClick={() =>
                                                                                    handleWishlistRemove(
                                                                                        findWishlistItem(doc.id)
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span class="material-symbols-outlined">
                                                                                    bookmark
                                                                                </span>
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                className="card-2-detail-btn addwishlist-btn"
                                                                                title="Add to Wishlist"
                                                                                onClick={() =>
                                                                                    handleAddToWishlist(doc.id)
                                                                                }
                                                                            >
                                                                                <span class="material-symbols-outlined">
                                                                                    bookmark
                                                                                </span>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                )}
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                    {/* NEW ARRIVALS PRODUCTS END*/}

                    {/* BELOW INACTIVE PRODUCTS START*/}
                    <div>
                        {showInactive ||
                            (user && user.role === "admin" && (
                                <>
                                    <br />
                                    <hr />
                                    <div className="product-page-heading-title">
                                        <h2
                                            style={{
                                                fontSize: "20px",
                                                marginLeft: "0px",
                                                color: "var(--heading-color)",
                                            }}
                                        >
                                            Inactive Products
                                        </h2>
                                    </div>
                                    <div className="row">

                                        {limitedProducts &&
                                            limitedProducts.map((doc, index) =>
                                                doc && doc.status === "inactive" ? (
                                                    <div className=" col-6 col-sm-6 col-lg-3 mt-4 corporateTwo-cards-container">
                                                        <div className="corporateTwo-card-2">
                                                            <div
                                                                className="card-2-clickable-area"
                                                                onClick={() => handleProductCardClick(doc.id)}
                                                            />
                                                            <div className="card-2-image-container">
                                                                <OwlCarousel
                                                                    key={doc.id} // Adding key to force re-render
                                                                    nav={false}
                                                                    dots={false}
                                                                    loop
                                                                    autoplay
                                                                    autoplayTimeout={3000}
                                                                    items={1}
                                                                >
                                                                    {doc.images &&
                                                                        doc.images.map((imageUrl, index) => (
                                                                            <div key={index}>
                                                                                <img
                                                                                    src={imageUrl}
                                                                                    alt={`Product Image ${index}`}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                </OwlCarousel>

                                                                {user && user.role === "admin" ? (
                                                                    <div
                                                                        className={`${doc.status && doc.status == "inactive"
                                                                            ? "card-2-inactive-status"
                                                                            : "card-2-active-status"
                                                                            }`}
                                                                    >
                                                                        <div
                                                                            className={`${doc.status && doc.status == "inactive"
                                                                                ? "card-2-inactive-flag"
                                                                                : "card-2-active-flag"
                                                                                }`}
                                                                        ></div>
                                                                        <span>
                                                                            {doc.status && doc.status == "inactive"
                                                                                ? "InActive"
                                                                                : "Active"}
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="card-2-discount-info-text">
                                                                        {doc.discount ? `${doc.discount} off` : ""}
                                                                    </div>
                                                                )}



                                                                <div className="card-2-rating-info">
                                                                    <span>{doc.rating && doc.rating}</span>
                                                                    <FaStar color="#fca503" size={20} />
                                                                </div>
                                                            </div>

                                                            <div className="card-2-info-container">
                                                                <div className="corporatetwo-card-2-title-info">
                                                                    <h4 className="corporateTwo-card-2-title">
                                                                        {doc.name && doc.name}
                                                                    </h4>
                                                                    <p className="corporateTwo-card-2-short-desc">
                                                                        {doc.short_description &&
                                                                            doc.short_description}
                                                                    </p>
                                                                </div>

                                                                <div className="card-2-price-info">
                                                                    <div className="card-2-selling-price">
                                                                        <h3>
                                                                            {document && document.symbol}{" "}
                                                                            {
                                                                                doc.price
                                                                                -
                                                                                Math.ceil(
                                                                                    (doc.price *
                                                                                        doc.discount.split("%")[0]) /
                                                                                    100
                                                                                )
                                                                            }
                                                                        </h3>
                                                                    </div>

                                                                    <div className="card-2-discount-info">
                                                                        <p>
                                                                            <del>
                                                                                {document && document.symbol} {doc.price && doc.price}
                                                                            </del>
                                                                        </p>
                                                                        {user && user.role == "admin" ? (
                                                                            <span>
                                                                                {doc.discount && doc.discount} Off
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="card-2-important-info">
                                                                    {user && user ? (
                                                                        <>
                                                                            {isOutOfStock(doc) ? (
                                                                                <button
                                                                                    className="card-2-cart-btn out-of-stock"
                                                                                    disabled
                                                                                    title="Out of Stock"
                                                                                >
                                                                                    Out of Stock
                                                                                </button>
                                                                            ) : isProductInCart(doc.id) ? (
                                                                                <div className="corporateTwo-card-2-quantity">
                                                                                    <button
                                                                                        className="corporateTwo-card-2-btn"
                                                                                        onClick={() => {
                                                                                            // If at minimum quantity, remove the item
                                                                                            const cartItem = findCartItem(doc.id);
                                                                                            if (cartItem.quantity <= (doc.minQuantity || 1)) {
                                                                                                handleDecrementQuantity(cartItem, true); // Pass true to indicate removal
                                                                                            } else {
                                                                                                handleDecrementQuantity(cartItem);
                                                                                            }
                                                                                        }}
                                                                                    // Remove the disabled condition
                                                                                    // disabled={findCartItem(doc.id).quantity <= (doc.minQuantity || 1)}
                                                                                    >
                                                                                        -
                                                                                    </button>
                                                                                    <input
                                                                                        type="text"
                                                                                        value={findCartItem(doc.id).quantity}
                                                                                        readOnly
                                                                                    />
                                                                                    <button
                                                                                        className="corporateTwo-card-2-btn"
                                                                                        onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                                                                        disabled={findCartItem(doc.id).quantity >= (doc.maxQuantity || doc.stock)}
                                                                                    >
                                                                                        +
                                                                                    </button>
                                                                                </div>
                                                                            ) : (
                                                                                <button
                                                                                    className="card-2-cart-btn"
                                                                                    onClick={() => handleAddToCart(doc.id)}
                                                                                    title="Add to Cart"
                                                                                >
                                                                                    <span>Cart</span>
                                                                                    <span class="material-symbols-outlined">
                                                                                        shopping_cart
                                                                                    </span>
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Link to="/login">
                                                                                <button
                                                                                    className="card-2-cart-btn"
                                                                                    title="Add to Cart"
                                                                                >
                                                                                    <span>Cart</span>
                                                                                    <span class="material-symbols-outlined">
                                                                                        shopping_cart
                                                                                    </span>
                                                                                </button>
                                                                            </Link>
                                                                        </>
                                                                    )}

                                                                    {isProductInWishlist(doc.id) ? (
                                                                        <button
                                                                            className="card-2-detail-btn removewishlist-btn "
                                                                            title="Remove from Wishlist"
                                                                            onClick={() =>
                                                                                handleWishlistRemove(
                                                                                    findWishlistItem(doc.id)
                                                                                )
                                                                            }
                                                                        >
                                                                            <span class="material-symbols-outlined">
                                                                                bookmark
                                                                            </span>
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className="card-2-detail-btn addwishlist-btn"
                                                                            title="Add to Wishlist"
                                                                            onClick={() =>
                                                                                handleAddToWishlist(doc.id)
                                                                            }
                                                                        >
                                                                            <span class="material-symbols-outlined">
                                                                                bookmark
                                                                            </span>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            )}
                                    </div>
                                </>
                            ))}
                    </div>

                    {/* BELOW INACTIVE PRODUCTS END */}
                </div>

                <CustomSnackbar
                    open={snackbar.open}
                    message={snackbar.message}
                    severity={snackbar.severity}
                    onClose={handleSnackbarClose}
                />
            </section>
        </div>
    );
};

export default Products;

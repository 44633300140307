import React from "react";
import { useEffect, useState } from "react";
import "./CorporateTwoFeatures.scss";
import { useCollection } from "../../../../../firebase files/hooks/useCollection";
import { useFirestore } from "../../../../../firebase files/hooks/useFirestore";
import { useAuthContext } from "../../../../../firebase files/hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

const SkeletonLoader = () => {
  return (
    <div className="corporate-two-features-features-content">
      {[1, 2, 3, 4].map((item) => (
        <div className="corporate-two-features-feature-card skeleton" key={item}>
          <div className="corporate-two-features-image-container skeleton-circle"></div>
          <div className="corporate-two-features-skeleton-text"></div>
          <div className="corporate-two-features-skeleton-text-short"></div>
        </div>
      ))}
    </div>
  );
};

const CorporateTwoFeatures = () => {
  const [localCategories, setLocalCategories] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isCols, setCols] = useState(5); // Default number of columns
console.log("isCols",isCols);

  const { user } = useAuthContext();

  const { documents: categories, error: categoriesError } = useCollection("m_categories"
    , ["status", "==", "active"]
  );
  const { updateDocument } = useFirestore("m_categories");
  const navigate = useNavigate();

  useEffect(() => {
    if (categories) {
      // Initialize local categories with Firestore data
      const sorted = categories
        .slice()
        .sort((a, b) => (a.categoryOrder || Infinity) - (b.categoryOrder || Infinity));
      setLocalCategories(sorted);
    }
  }, [categories]);

    // Update the number of columns dynamically based on the screen size
    useEffect(() => {
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1400) setCols(5); // lg
        // else if (screenWidth >= 1200) setCols(5);
        else if (screenWidth >= 996) setCols(4); // md
        else if (screenWidth >= 768) setCols(2); // sm
        else if (screenWidth >= 480) setCols(2); // xs
        else setCols(2); // Smaller screens
      };
  
      // Initialize on mount and attach resize listener
      handleResize();
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const handleDragStop = (layout) => {
      const updatedCategories = localCategories.map((category) => {
        const layoutItem = layout.find((item) => item.i === category.id);
        return layoutItem
          ? { ...category, categoryOrder: parseInt(layoutItem.y * isCols + layoutItem.x + 1) }
          : category;
      });
    
      updatedCategories.sort((a, b) => a.categoryOrder - b.categoryOrder);
    
      setLocalCategories(updatedCategories);
      setHasChanges(JSON.stringify(updatedCategories) !== JSON.stringify(originalCategories));
    };
    
  const handleUpdate = async () => {
    setIsUpdating(true);
    try {
      const updatePromises = localCategories.map((category) =>
        updateDocument(category.id, { categoryOrder: category.categoryOrder })
      );
      await Promise.all(updatePromises);
      console.log("Category order updated successfully!");
      setOriginalCategories(JSON.stringify(localCategories)); // Update original state
      setHasChanges(false); // Reset change flag
    } catch (error) {
      console.error("Error updating category order:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  if (categoriesError) {
    return (
      <div className="corporate-two-features-container">
        <div className="corporate-two-features-error-message">
          <span className="material-symbols-outlined">error</span>
          <p>Error loading services. Please try again later.</p>
        </div>
      </div>
    );
  }

  const sortedCategories = localCategories.slice().sort((a, b) => {
    const orderA = a.categoryOrder || Infinity;
    const orderB = b.categoryOrder || Infinity;
    return orderA - orderB;
  });

  const layout = sortedCategories.map((category, index) => {
    const cols = isCols; // Default number of columns for large screens
    console.log("cols",cols);
    
    const x = index % cols; // Calculate the column index
    const y = Math.floor(index / cols); // Calculate the row index
  
    const order = index + 1; // Order based on the array position
    category.categoryOrder = order; // Assign the order back to the category
  
    return {
      i: category.id,
      x, // Fixed x position for the current breakpoint
      y, // Fixed y position for the current breakpoint
      w: 1, // Each card occupies 1 column width
      h: 1.5, // Consistent height (adjust as needed)
      order,
    };
  });
  

  return (
    <div className="corporate-two-features-container large_margin_div container">
      <div className="corporate-two-features-features-header-parent">
        <div className="corporate-two-features-features-header">
          <h1>Our Services</h1>
          <p>Discover the full range of our quality services</p>
        </div>
      </div>

      {user && user.role === "admin" && hasChanges && (
        <button className="btn_fill" onClick={handleUpdate} disabled={isUpdating}>
          {isUpdating ? "Updating..." : "Save Changes"}
        </button>
      )}
      {!categories ? (
        <SkeletonLoader />
      ) : (
        <ResponsiveGridLayout
          className="corporate-two-features-features-content"
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
          cols={{ lg: 5, md: 4, sm: 2, xs: 2 }}
          onDragStop={handleDragStop}
          isDraggable={true}
          isResizable={false}
          draggableHandle=".drag-handle" // Add this prop
        >
          {sortedCategories.map((category) => (
            <div key={category.id} className="corporate-two-features-feature-card small_margin_div">
              {/* Slip at the top */}
              <div className="card-slip">
                <span>{category.categoryOrder}</span>
              </div>
              {user && user.role === "admin" && (
                <div
                  className="corporate-two-features-drag-icon drag-handle" // Add "drag-handle" class
                  title="Drag to reorder"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    cursor: "grab",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="currentColor"
                  >
                    <path d="M240-160q-33 0-56.5-23.5T160-240q0-33 23.5-56.5T240-320q33 0 56.5 23.5T320-240q0 33-23.5 56.5T240-160Zm240 0q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm240 0q-33 0-56.5-23.5T640-240q0-33 23.5-56.5T720-320q33 0 56.5 23.5T800-240q0 33-23.5 56.5T720-160ZM240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400ZM240-640q-33 0-56.5-23.5T160-720q0-33 23.5-56.5T240-800q33 0 56.5 23.5T320-720q0 33-23.5 56.5T240-640Zm240 0q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Zm240 0q-33 0-56.5-23.5T640-720q0-33 23.5-56.5T720-800q33 0 56.5 23.5T800-720q0 33-23.5 56.5T720-640Z" />
                  </svg>
                </div>
              )}

              <div
                className="corporate-two-features-image-container"
                onClick={() =>
                  navigate(`/products?category=${encodeURIComponent(category.category)}`)
                }
              >
                <img
                  src={category.categoryImgUrl || "default-category-image.jpg"}
                  alt={category.category}
                  onError={(e) => {
                    e.target.src = "default-category-image.jpg";
                  }}
                />
              </div>
              <br />
              <h3>{category.category}</h3>
            </div>
          ))}
        </ResponsiveGridLayout>
      )
      }
      <div className="large_padding_div"></div>
    </div>
  );
};

export default CorporateTwoFeatures;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import "./BusinessThreeProfile.scss";
import DarkModeToggle from "react-dark-mode-toggle";
import Popup from "../../../../default components/Popup";
import { BeatLoader } from "react-spinners";
import { useDocument } from "../../../../firebase files/hooks/useDocument";

// IMPORT FIRESTORE
import { useLogout } from "../../../../firebase files/hooks/useLogout";
import {
  projectFirestore,
  projectStorage,
} from "../../../../firebase config/config";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
const BusinessThreeProfile = () => {
  const { user } = useAuthContext();

  const [AddRole, setAddRole] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selctedRole, setRole] = useState([]);
  const [selectedRoleIndex, setSelectedRolesIndex] = useState(null);
  const [isAddRole, setIsAddRole] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);

  //Popup Flags
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [popupReturn, setPopupReturn] = useState(false);
  const { logout, isPending } = useLogout();

  // change user details
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    city: "",
    email: "",
    phoneNumber: "",
  });

  // CHANGE PROFILE IMG CODE START
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(user ? user.photoURL : "");
  const [selectedDummyImage, setSelectedDummyImage] = useState(null);
  const [imgUploading, setImgUploading] = useState(false);
  const [dummyImages, setDummyImages] = useState([
    "/assets/img/profile-option-1.png",
    "/assets/img/profile-option-2.png",
    "/assets/img/profile-option-3.png",
    "/assets/img/profile-option-4.jfif",
    "/assets/img/profile-option-5.jpg",
    "/assets/img/profile-option-6.jfif",
  ]);
  const defaultImageUrl =
    "https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/userThumbnails%2F1default.png?alt=media&token=38880453-e642-4fb7-950b-36d81d501fe2";

  const { updateDocument, response } = useFirestore("users");
  const { updateDocument: updateMenuDocument } = useFirestore("settings");

  const { document: pageControlDoc, error: pageControlDocError } = useDocument(
    "settings",
    "pagesControl"
  );

  const { document: dynamicNavMenuDoc, error: dynamicNavMenuDocError } = useDocument(
    "settings",
    "EditNavbarMenus"
  );

  const { document: dbTemplatesPaletteDocuments, error: dbTemplatesPaletteError } =
    useDocument("settings", "themePalette");

  //Popup Flags
  useEffect(() => {
    if (popupReturn) {
      logout();
    }
  }, [popupReturn]);

  //Popup Flags
  const showPopup = async (e) => {
    e.preventDefault();
    setShowPopupFlag(true);
    setPopupReturn(false);
  };

  // DARK\LIGHT CODE FOR CUSTOMER LOCAL STAORAGE
  const toggleUserDarkMode = async () => {
    // Retrieving a value from localStorage
    const currentModeStatus = localStorage.getItem("mode");

    const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";
    // Storing a value in localStorage
    localStorage.setItem("mode", newDisplayMode);

    window.dispatchEvent(new Event("storage"));
  };
  // END CODE FOR LIGHT/DARK MODE

  // START CODE FOR ROLE
  const handleAddRoleClick = () => {
    setIsAddRole(true);
  };
  const handleSaveAddRole = async () => {
    const lowercaseRole = AddRole.toLowerCase(); // Convert role name to lowercase

    try {
      // Get the current roles array from the document
      const currentRoles = (user && user.roles) || [];

      // Add the new option to the array
      const updatedRoles = [...currentRoles, lowercaseRole];

      // Update the roles array in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ roles: updatedRoles });

      console.log("Roles updated successfully"); // Log success message

      // Reset the AddRole state and close the input field
      setAddRole("");
      setIsAddRole(false);
    } catch (error) {
      console.error("Error adding new option:", error);
      // Handle the error as needed
    }
  };
  const handleCancelAddRole = () => {
    setIsAddRole(false);
  };

  const handleRoleChange = async (roles) => {
    setSelectedRole(roles);
    setIsRoleSelected(true);
    try {
      // Update the user's role in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ role: roles });

      console.log("User role updated successfully to:", roles); // Log success message
    } catch (error) {
      console.error("Error updating user role:", error);
      // Handle the error as needed
    }
  };

  // Fetch roles array from the document state
  const roles =
    user && user
      ? ((user && user.roles) || []).map((role) => role.toLowerCase())
      : [];
  // delete option
  const handleDeleteRole = async (index) => {
    try {
      // Get the current roles array from the document
      const currentRole = (user && user.roles) || [];

      // Remove the selected option from the array
      const updatedRole = currentRole.filter((_, i) => i !== index);

      // Update the roles array in Firestore
      await projectFirestore
        .collection("users")
        .doc(user.uid)
        .update({ roles: updatedRole });
    } catch (error) {
      console.error("Error deleting role:", error);
      // Handle the error as needed
    }
  };
  // END CODE FOR CATEGORY ROLE

  // START EDIT USER DETAILS
  useEffect(() => {
    if (user) {
      setUserDetails({
        fullName: user.fullName,
        address: user.address,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });
    }
  }, [user]);

  const handleEditClick = () => {
    setShowEditPopup(true);
    setConfirmButtonDisabled(true); // Disable the confirm button initially
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setConfirmButtonDisabled(false); // Enable the confirm button when any input changes
  };

  const handleCancelClick = () => {
    setShowEditPopup(false);
  };

  // Confirm update
  const handleConfirmClick = async () => {
    try {
      await updateDocument(user.uid, userDetails);
      setShowEditPopup(false);
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };
  // END EDIT USER DETAILS

  // START CHANGE IMG CODE START
  const handleImageClick = () => {
    setShowImagePopup(true);
  };

  const handleRemoveImage = () => {
    setImagePreview(defaultImageUrl); // Set to default image URL
    setSelectedImage(null);
    setSelectedDummyImage(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedImage(file);
    }
  };

  const handleDummyImageClick = (imageUrl) => {
    setSelectedDummyImage(imageUrl);
    setImagePreview(imageUrl);
  };

  const handleCancelImgClick = () => {
    setShowImagePopup(false);
  };

  const handleConfirmImgClick = async () => {
    try {
      if (selectedImage) {
        setImgUploading(true); // Set loader on
        const uploadPath = `userThumbnails/${user.uid}/${selectedImage.name}`;
        const uploadTask = projectStorage.ref(uploadPath).put(selectedImage);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle progress
          },
          (error) => {
            console.error("Error uploading file:", error);
            setImgUploading(false); // Set loader off on error
          },
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            await updateDocument(user.uid, { photoURL: downloadURL });
            setImagePreview(downloadURL);
            setSelectedImage(null);
            setImgUploading(false); // Set loader off
            setShowImagePopup(false);
          }
        );
      } else if (selectedDummyImage) {
        setImgUploading(true);
        await updateDocument(user.uid, { photoURL: selectedDummyImage });
        setImagePreview(selectedDummyImage);
        setSelectedDummyImage(null);
        setImgUploading(false); // Set loader off
        setShowImagePopup(false);
      } else {
        setImgUploading(true);
        await updateDocument(user.uid, { photoURL: defaultImageUrl });
        setImagePreview(defaultImageUrl);
        setImgUploading(false); // Set loader off
        setShowImagePopup(false);
      }
    } catch (error) {
      console.error("Error updating image URL:", error);
      setImgUploading(false); // Set loader off on error
    }
  };

  //  START FETCH DYNAMIC MENUS FROM DB
  const handleVisibleInProfilePage = async (menuIndex, subMenuIndex = null) => {
    const updatedMenuDetails = [...dynamicNavMenuDoc.menuDetails];

    if (subMenuIndex === null) {
      // Toggle visibility for the main menu
      const currentVisibility = updatedMenuDetails[menuIndex]?.visibilityInProfilePage || false;
      updatedMenuDetails[menuIndex].visibilityInProfilePage = !currentVisibility;
    } else {
      // Toggle visibility for the submenu
      const currentVisibility = updatedMenuDetails[menuIndex]?.subMenu[subMenuIndex]?.visibilityInProfilePage || false;
      updatedMenuDetails[menuIndex].subMenu[subMenuIndex].visibilityInProfilePage = !currentVisibility;
    }

    try {
      // Update Firestore document
      await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });
      console.log("Visibility updated successfully");
    } catch (error) {
      console.error("Error updating visibility:", error);
      alert("There was an error updating the visibility. Please try again.");
    }
  };
  //  START FETCH DYNAMIC MENUS FROM DB

  return (
    <>
      <Popup
        showPopupFlag={showPopupFlag}
        setShowPopupFlag={setShowPopupFlag}
        setPopupReturn={setPopupReturn}
        msg={"Are you sure you want to logout?"}
      />

      <div className="business-three-profile">
        {(user || (pageControlDoc && pageControlDoc.login === true)) && (
          <div className="business-three-profile-sidebar">
            <div className="business-three-profile-image">
              {user && user ? (
                <div className="business-three-profile-img-camera">
                  <div style={{ position: "relative" }}>
                    <img src={user.photoURL} alt="Profile" className="business-three-profile-img-camera-img" />

                    {dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "defaultThemePalette" ? (
                      <div className="business-three-profile-img-camera-occation-img"></div>
                    ) : (
                      dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "diwaliThemePalette" ? (
                        <img
                          src="/assets/img/themes/diwali-rocket.gif"
                          className="business-three-profile-img-camera-occation-img"
                          alt="Diwali Theme"
                        />
                      ) : (
                        dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "holiThemePalette" ? (
                          <img
                            src="/assets/img/themes/holi-rangoli.webp"
                            className="business-three-profile-img-holi-occation-img"
                            alt="Holi Theme"
                          />
                        ) : null
                      ))}

                  </div>
                  <span
                    className="material-symbols-outlined business-three-profile-camera-icon"
                    onClick={handleImageClick}
                  >
                    photo_camera
                  </span>
                </div>
              ) : (
                <>
                  <Link to="/login">
                    <img src="assets/img/login-auth.gif" alt="login gif img" style={{ width: "100%" }} />
                    {/* <div className="business-three-profile-logout-span">
                    <span className="material-symbols-outlined ba_animation">
                      login
                    </span>
                  </div> */}
                    <br />
                    <div className="d-flex justify-content-center">
                      <button className="btn_fill d-flex align-items-center">
                        <span class="material-symbols-outlined ba_animation">
                          logout
                        </span>
                        &nbsp; Login / Sign-Up
                      </button>
                    </div>
                  </Link>
                </>
              )}
              {user && user && (
                <span
                  className="material-symbols-outlined business-three-profile-edit-icon"
                  onClick={handleEditClick}
                >
                  edit
                </span>
              )}
            </div>

            {user && user && (
              <>
                <div className="business-three-profile-info">
                  <h2>{user && user.fullName}</h2>
                  <p>
                    {user && user.city} , {user && user.country}
                  </p>
                  <div className="business-three-profile-details">
                    <p>
                      <strong>Email:</strong> {user && user.email}
                    </p>
                    <p>
                      <strong>Phone:</strong> +{" "}
                      {user &&
                        `${user.phoneNumber.slice(0, 2)} ${user.phoneNumber.slice(
                          2,
                          6
                        )} ${user.phoneNumber.slice(
                          6,
                          8
                        )} ${user.phoneNumber.slice(8)}`}
                    </p>
                    {user && user.address != "" && (
                      <p>
                        <strong>Address:</strong> {user && user.address}
                      </p>
                    )}

                  </div>
                </div>
                <div>
                  {/* <br /> */}
                  {user && user.roles.includes("admin") && (
                    <div className="profile-page-content role">
                      <p>
                        <strong>Role</strong>
                      </p>
                      <div className="radio-container">
                        {roles &&
                          roles.map((roles, index) => (
                            <label
                              key={index}
                              className={`radio-container ${selectedRoleIndex === index
                                ? "radio_btn_checked"
                                : "service_details_radion_btn"
                                }`}
                              style={{
                                color:
                                  user && user.role === roles
                                    ? "var(--white-color)"
                                    : "inherit",
                                backgroundColor:
                                  user && user.role === roles
                                    ? "var(--click-color)"
                                    : "inherit",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="role"
                                  value={roles}
                                  onChange={() => handleRoleChange(roles)}
                                  className="radio-button"
                                />
                                <span
                                  className="material-symbols-outlined"
                                  style={{ fontSize: "21px" }}
                                >
                                  {user && user.role === roles ? "done" : "add"}
                                </span>
                                {roles}
                                {user && user.role === "admin" ? (
                                  <span
                                    class="material-symbols-outlined"
                                    onClick={() => handleDeleteRole(index)}
                                  >
                                    close
                                  </span>
                                ) : null}
                              </div>
                            </label>
                          ))}
                        {isAddRole ? (
                          <div>
                            <input
                              type="text"
                              value={AddRole}
                              onChange={(e) => setAddRole(e.target.value)}
                            />
                            <div className="d-flex">
                              <button
                                className="product_edit_save_btn"
                                onClick={handleSaveAddRole}
                              >
                                Save
                              </button>
                              <button
                                className="product_edit_save_btn cancel-btn"
                                onClick={handleCancelAddRole}
                                style={{ marginLeft: "10px" }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            {/* {user && user.role == "admin" && (
                            <span
                              class="material-symbols-outlined"
                              onClick={handleAddRoleClick}
                              style={{ fontSize: "24px" }}
                            >
                              add_box
                            </span>
                          )} */}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="business-three-profile-tags">
              <div className="business-three-profile-button-container">
                {user && user ? (
                  <>
                    <button
                      className="btn_border"
                      style={{ minWidth: "100%" }}
                      onClick={showPopup}
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
        {/* RIGHT SECTION START */}
        <div className="business-three-profile-main container">
          <div className="business-three-profile-more-expand-div">
            <div className="business-three-profile-more-div-big-icon-div">
              {pageControlDoc && pageControlDoc.dashboard === true && (
                <div className="business-three-profile-more-div-big-icon-div-inner pointer">
                  <Link
                    to="/dashboard"
                    className="d-flex flex-column align-items-center"
                  >
                    <div>
                      <span className="material-symbols-outlined">
                        insert_chart
                      </span>
                    </div>
                    <h1 style={{ color: "var(--black-color)" }}>Dashboard</h1>
                  </Link>
                </div>
              )}


              {pageControlDoc && pageControlDoc.orders === true && (
                <div className="business-three-profile-more-div-big-icon-div-inner pointer">
                  <Link
                    to="/orders"
                    className="d-flex flex-column align-items-center"
                  >
                    <div>
                      <span className="material-symbols-outlined">
                        list_alt
                      </span>
                    </div>
                    <h1 style={{ color: "var(--black-color)" }}>My Orders</h1>
                  </Link>
                </div>
              )}


              <div className="business-three-profile-more-div-big-icon-div-inner pointer">
                <Link
                  to="/notifications"
                  className="d-flex flex-column align-items-center"
                >
                  <div>
                    <span className="material-symbols-outlined">
                      notifications
                    </span>
                  </div>
                  <h1 style={{ color: "var(--black-color)" }}>Notifications</h1>
                </Link>
              </div>
            </div>
          </div>

          {/* ADMIN MENUS START */}
          {user && user.role == "admin" && (
            <>
              <div className="business-three-profile-menus-list container">
                <div className="row">
                  <h3 style={{ fontWeight: "700" }}>Admin</h3>
                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/all-users">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span className="material-symbols-outlined">
                                group_add
                              </span>
                            </div>
                            <h6>All Users</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>

                    <div className="business-three-profile-menu">
                      <Link to="/sitesettings">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span className="material-symbols-outlined">
                                settings
                              </span>
                            </div>
                            <h6>Site Settings</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/aboutus">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span className="material-symbols-outlined">
                                info
                              </span>
                            </div>
                            <h6>About Us</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="business-three-profile-menu">
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <span class="material-symbols-outlined">
                              display_settings
                            </span>
                          </div>
                        </div>
                        <div
                          className="d-flex more_display_mode_card"
                          style={{ width: "100%" }}
                        >
                          <div>
                            <h6 style={{ color: "var(--black-color)" }}>
                              Display Mode
                            </h6>
                          </div>
                          <div>
                            <DarkModeToggle
                              onChange={() => toggleUserDarkMode()}
                              checked={localStorage.getItem("mode") === "dark"}
                              size={50}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/enquries-list">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span className="material-symbols-outlined">
                                view_list
                              </span>
                            </div>
                            <h6>Enquiries List</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* MASTER DATA CARD START */}
              <div className="business-three-profile-menus-list container">
                <div className="row">
                  <h3 style={{ fontWeight: "700" }}>Master Data</h3>
                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/add-categories">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span class="material-symbols-outlined">
                                dataset
                              </span>
                            </div>
                            <h6>Add Categories</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  {/* <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/address">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span class="material-symbols-outlined">
                                place
                              </span>
                            </div>
                            <h6>Manage Address</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </>
          )}
          {/* ADMIN MENUS END */}

          <div className="business-three-profile-menus-list">
            <div className="row">
              <div className="col-12 col-sm-6">
                <h3 style={{ fontWeight: "700" }}>Quick Links</h3>
                <div className="business-three-profile-menu">
                  <Link to="/">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            home
                          </span>
                        </div>
                        <h6>Home</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="business-three-profile-menu">
                  <Link to="/contact">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            connect_without_contact
                          </span>
                        </div>
                        <h6>Contact</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="business-three-profile-menu">
                  <Link to="/about">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            info
                          </span>
                        </div>
                        <h6>About Us</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                {pageControlDoc && pageControlDoc.dashboard === true && (
                  <div className="business-three-profile-menu">
                    <Link to="/customerdashboard">
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <span className="material-symbols-outlined">
                              insert_chart
                            </span>
                          </div>
                          <h6>Dashboard</h6>
                        </div>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </Link>
                  </div>
                )}

                {/* <div className="business-three-profile-menu">
                  <Link to="/services">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            add_shopping_cart
                          </span>
                        </div>
                        <h6>Add Booking</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div> */}

                {/* <div className="business-three-profile-menu">
                  <Link to="/bookinglist">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            add_shopping_cart
                          </span>
                        </div>
                        <h6>My Booking</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div> */}


              </div>

              <div className="col-12 col-sm-6">
                <h3 style={{ fontWeight: "700" }}>More Links</h3>

                <div className="business-three-profile-menu">
                  <Link to="/faq">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            quiz
                          </span>
                        </div>
                        <h6>FAQ</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>

                {pageControlDoc && pageControlDoc.gallery === true && (
                  <div className="business-three-profile-menu">
                    <Link to="/gallery">
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <span className="material-symbols-outlined">
                              image
                            </span>
                          </div>
                          <h6>Gallery</h6>
                        </div>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </Link>
                  </div>
                )}

                <div className="business-three-profile-menu">
                  <Link to="/privacy-policy">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            lock
                          </span>
                        </div>
                        <h6>Privacy Policy</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>



                <div className="business-three-profile-menu">
                  <Link to="/term-condition">
                    <div className="business-three-profile-content">
                      <div className="business-three-profile-icon">
                        <div className="business-three-profile-icon-bg">
                          <span className="material-symbols-outlined">
                            gavel
                          </span>
                        </div>
                        <h6>Terms & Conditions</h6>
                      </div>
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </div>










                <div className="business-three-profile-menu business-three-profile-logout-login-pc">
                  {user && user ? (
                    <div onClick={showPopup}>
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <span className="material-symbols-outlined">
                              logout
                            </span>
                          </div>
                          <h6>Logout</h6>
                        </div>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </div>
                  ) : (
                    <Link to="/login">
                      <div className="business-three-profile-content">
                        <div className="business-three-profile-icon">
                          <div className="business-three-profile-icon-bg">
                            <span className="material-symbols-outlined">
                              logout
                            </span>
                          </div>
                          <h6>Login</h6>
                        </div>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>


          {/* DYNAMIC MENUS START */}
          <>
            <div className="business-three-profile-menus-list container">
              <div className="row">
                <h3 style={{ fontWeight: "700" }}>More Pages</h3>
                {dynamicNavMenuDoc &&
                  dynamicNavMenuDoc.menuDetails &&
                  dynamicNavMenuDoc.menuDetails.map((menu, index) => {
                    // Skip menu if menuStatus is inactive
                    if (menu.menuStatus === "inactive") {
                      return null; // Don't render this menu
                    }

                    // For admin, show all menus and submenus (ignore visibility status)
                    if (user && user.role === "admin") {
                      return (
                        <React.Fragment key={`menu-${index}`}>
                          {/* Main Menu */}
                          <div className="col-12 col-sm-6">
                            <div className="business-three-profile-menu">
                              <Link to={menu.pageUrl}>
                                <div className="business-three-profile-content">
                                  <div className="business-three-profile-icon">
                                    <div className="business-three-profile-icon-bg">
                                      {/* <span className="material-symbols-outlined">
                                        {menu.iconName}
                                      </span> */}
                                      <span style={{color:"var(--logo-primary-color)"}}
                                      dangerouslySetInnerHTML={{ __html: menu.iconName }}/>
                                    </div>
                                    <h6>{menu.menuName}</h6>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p style={{ fontSize: "12px" }}>Hide</p>&nbsp;
                                    <DarkModeToggle
                                      onChange={() => handleVisibleInProfilePage(index)} // Pass the correct indices for the main menu
                                      checked={menu.visibilityInProfilePage || false}
                                      size={30}
                                    />
                                    &nbsp;<p style={{ fontSize: "12px" }}>Unhide</p>
                                    <span className="material-symbols-outlined">chevron_right</span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>

                          {/* Submenus */}
                          {menu.subMenu &&
                            menu.subMenu.map((subMenuItem, subIndex) => {
                              // Skip submenu if subMenuStatus is inactive
                              if (subMenuItem.subMenuStatus === "inactive") {
                                return null; // Don't render this submenu
                              }

                              return (
                                <div className="col-12 col-sm-6" key={`submenu-${index}-${subIndex}`}>
                                  <div className="business-three-profile-menu">
                                    <Link to={subMenuItem.subMenuPageUrl}>
                                      <div className="business-three-profile-content">
                                        <div className="business-three-profile-icon">
                                          <div className="business-three-profile-icon-bg">
                                            {/* <span className="material-symbols-outlined">
                                              {subMenuItem.subMenuIcon}
                                            </span> */}
                                            <span  style={{color:"var(--logo-primary-color)"}}
                                      dangerouslySetInnerHTML={{ __html: subMenuItem.subMenuIcon }}/>
                                          </div>
                                          <h6>{subMenuItem.subMenuName}</h6>
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <p style={{ fontSize: "12px" }}>Hide</p>&nbsp;
                                          <DarkModeToggle
                                            onChange={() => handleVisibleInProfilePage(index, subIndex)} // Pass indices for submenu
                                            checked={subMenuItem.visibilityInProfilePage || false}
                                            size={30}
                                          />
                                          &nbsp;<p style={{ fontSize: "12px" }}>Unhide</p>
                                          <span className="material-symbols-outlined">chevron_right</span>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                        </React.Fragment>
                      );
                    }

                    // For other users, show only menus and submenus with visibilityInProfilePage = true
                    const showMainMenu = menu.visibilityInProfilePage;
                    const subMenusToShow =
                      menu.subMenu &&
                      menu.subMenu.filter(
                        (subMenuItem) =>
                          subMenuItem.visibilityInProfilePage &&
                          subMenuItem.subMenuStatus !== "inactive"
                      );

                    if (!showMainMenu && (!subMenusToShow || subMenusToShow.length === 0)) {
                      return null; // Skip this menu entirely if nothing to show
                    }

                    return (
                      <React.Fragment key={`menu-${index}`}>
                        {/* Main Menu */}
                        {showMainMenu && (
                          <div className="col-12 col-sm-6">
                            <div className="business-three-profile-menu">
                              <Link to={menu.pageUrl}>
                                <div className="business-three-profile-content">
                                  <div className="business-three-profile-icon">
                                    <div className="business-three-profile-icon-bg">
                                      {/* <span className="material-symbols-outlined">
                                        {menu.iconName}
                                      </span> */}
                                      <span style={{color:"var(--logo-primary-color)"}}
                                      dangerouslySetInnerHTML={{ __html: menu.iconName }}/>
                                    </div>
                                    <h6>{menu.menuName}</h6>
                                  </div>
                                  <span className="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </div>
                              </Link>
                            </div>
                          </div>
                        )}

                        {/* Submenus */}
                        {subMenusToShow &&
                          subMenusToShow.map((subMenuItem, subIndex) => (
                            <div
                              className="col-12 col-sm-6"
                              key={`submenu-${index}-${subIndex}`}
                            >
                              <div className="business-three-profile-menu">
                                <Link to={subMenuItem.subMenuPageUrl}>
                                  <div className="business-three-profile-content">
                                    <div className="business-three-profile-icon">
                                      <div className="business-three-profile-icon-bg">
                                        {/* <span className="material-symbols-outlined">
                                          {subMenuItem.subMenuIcon}
                                        </span> */}
                                          <span  style={{color:"var(--logo-primary-color)"}}
                                      dangerouslySetInnerHTML={{ __html: subMenuItem.subMenuIcon }}/>
                                      </div>
                                      <h6>{subMenuItem.subMenuName}</h6>
                                    </div>
                                    <span className="material-symbols-outlined">
                                      chevron_right
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                      </React.Fragment>
                    );
                  })}

              </div>
            </div>
          </>
          {/* DYNAMIC MENUS END */}

          {/* FRONT OFFICE MENUS START */}
          {user && user.role == "frontoffice" && (
            <>
              <div className="business-three-profile-menus-list container">
                <div className="row">
                  <h3 style={{ fontWeight: "700" }}>Front Office</h3>
                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/enquries-list">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span className="material-symbols-outlined">
                                view_list
                              </span>
                            </div>
                            <h6>Enquiries List</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6">
                    <div className="business-three-profile-menu">
                      <Link to="/aboutus">
                        <div className="business-three-profile-content">
                          <div className="business-three-profile-icon">
                            <div className="business-three-profile-icon-bg">
                              <span className="material-symbols-outlined">
                                info
                              </span>
                            </div>
                            <h6>About Us</h6>
                          </div>
                          <span className="material-symbols-outlined">
                            chevron_right
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* FRONT OFFICE MENUS END */}
        </div>
      </div >

      {/* EDIT USER DETAILS START */}
      {
        showEditPopup && (
          <>
            <div
              className={`business-three-profile-edit-overlay ${showEditPopup
                ? "business-three-profile-overlay-open"
                : "business-three-profile-overlay-close"
                }`}
            />
            <div
              className={`business-three-profile-edit-popup ${showEditPopup
                ? "business-three-profile-open"
                : "business-three-profile-close"
                }`}
            >
              <div className="business-three-profile-edit-popup-content">
                <div className="business-three-profile-edit-popup-header">
                  <h2>Edit User Details</h2>
                  <span
                    className="business-three-profile-edit-popup-close"
                    onClick={handleCancelClick}
                  >
                    &times;
                  </span>
                </div>
                <div className="business-three-profile-edit-popup-body">
                  <input
                    type="text"
                    name="fullName"
                    value={userDetails.fullName}
                    onChange={handleInputChange}
                    placeholder="Full Name"
                  />
                  <input
                    type="textarea"
                    name="address"
                    value={userDetails.address}
                    onChange={handleInputChange}
                    placeholder="Address"
                  />
                  <input
                    type="email"
                    name="email"
                    value={userDetails.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                  />
                </div>
                <div className="business-three-profile-edit-popup-footer">
                  <button onClick={handleCancelClick} className="btn_border">
                    Cancel
                  </button>
                  <button
                    onClick={handleConfirmClick}
                    disabled={confirmButtonDisabled}
                    className="btn_fill"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }
      {/* END EDIT USER DETAILS  */}

      {/* Image Change Popup */}
      {
        showImagePopup && (
          <>
            <div
              className={`business-three-profile-edit-overlay ${showImagePopup
                ? "business-three-profile-overlay-open"
                : "business-three-profile-overlay-close"
                }`}
            />
            <div
              className={`business-three-profile-edit-popup ${showImagePopup
                ? "business-three-profile-open"
                : "business-three-profile-close"
                }`}
            >
              <div className="business-three-profile-edit-popup-header">
                <h2>Change Picture</h2>
                {!imgUploading && (
                  <span
                    className="business-three-profile-edit-popup-close"
                    onClick={handleCancelImgClick}
                  >
                    &times;
                  </span>
                )}
              </div>
              <div className="row">
                <div className="business-three-profile-popup-body col-12 col-md-4">
                  <div className="row">
                    <div className="business-three-profile-image-preview col-6 col-md-12">
                      <img src={imagePreview} alt="Preview" />
                      {!imgUploading && (
                        <span
                          className="material-symbols-outlined business-three-profile-delete-icon"
                          onClick={handleRemoveImage}
                        >
                          delete
                        </span>
                      )}
                    </div>
                    {imgUploading ? (
                      <div className="d-flex align-items-center justify-content-center col-6 col-md-12">
                        <BeatLoader color={"var(--click-color)"} loading={true} />
                      </div>
                    ) : (
                      <div className="business-three-profile-button-group col-6 col-md-12">
                        <input
                          type="file"
                          accept="image/*"
                          id="file-input"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <button
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                          className="btn_fill"
                        >
                          Browse
                        </button>
                        <button
                          onClick={handleConfirmImgClick}
                          className="btn_fill"
                        >
                          Confirm
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="business-three-profile-popup-footer col-12 col-md-8">
                  <div className="business-three-profile-line-container">
                    <div className="business-three-profile-line"></div>
                    <div className="business-three-profile-or-text">or</div>
                    <div className="business-three-profile-line"></div>
                  </div>
                  <div className="business-three-profile-dummy-images">
                    {dummyImages.map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        alt={`Dummy ${index + 1}`}
                        onClick={() => handleDummyImageClick(img)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};

export default BusinessThreeProfile;



import React, { useState } from 'react';
import './AdminDrivers.scss';
import { FiEdit2, FiPlus } from 'react-icons/fi';

const AdminDrivers = () => {
    const [showForm, setShowForm] = useState(false);

    const driverData = [
        {
            id: "EMP001",
            name: "DB Cooper",
            mobile: "+91 98765 43210",
            image: "https://randomuser.me/api/portraits/men/44.jpg"
        },
        {
            id: "EMP002",
            name: "Sam Smith",
            mobile: "+91 87654 32109",
            image: "https://randomuser.me/api/portraits/men/66.jpg"
        },
        {
            id: "EMP003",
            name: "John Doe",
            mobile: "+91 98765 43210",
            image: "https://randomuser.me/api/portraits/men/24.jpg"
        }
    ];

    const DriverForm = () => (
        <div className="driver-form">
            <h2>Add New Driver</h2>
            <form>
                <div className="form-group">
                    <label>Full Name</label>
                    <input type="text" placeholder="Enter full name" />
                </div>
                <div className="form-group">
                    <label>Mobile Number</label>
                    <input type="tel" placeholder="Enter mobile number" />
                </div>
                <div className="form-group">
                    <label>Employee ID</label>
                    <input type="text" placeholder="Enter employee ID" />
                </div>
                <div className="form-group">
                    <label>Profile Image</label>
                    <input type="file" accept="image/*" />
                </div>
                <div className="form-actions">
                    <button 
                        type="button" 
                        className="cancel-btn"
                        onClick={() => setShowForm(false)}
                    >
                        Cancel
                    </button>
                    <button 
                        type="button" 
                        className="save-btn"
                        onClick={() => setShowForm(false)}
                    >
                        Save Driver
                    </button>
                </div>
            </form>
        </div>
    );

    const DriverCards = () => (
        <>
            <div className="drivers-header">
                <h1>Drivers</h1>
                <button 
                    className="add-driver-btn"
                    onClick={() => setShowForm(true)}
                >
                    <FiPlus /> Add New Driver
                </button>
            </div>
            <div className="drivers-grid">
                {driverData.map((driver) => (
                    <div key={driver.id} className="driver-card">
                        <button className="edit-btn">
                            <FiEdit2 />
                        </button>
                        <div className="driver-image">
                            <img src={driver.image} alt={driver.name} />
                        </div>
                        <div className="driver-info">
                            <h3>{driver.name}</h3>
                            <p className="emp-id">ID: {driver.id}</p>
                            <p className="mobile">{driver.mobile}</p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );

    return (
        <div className="admin-drivers">
            {showForm ? <DriverForm /> : <DriverCards />}
        </div>
    );
};

export default AdminDrivers;
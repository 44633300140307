import React, { useEffect, useRef, useState } from "react";
import "./AppStoreOneDownloadApp.scss";

const AppStoreOneDownloadApp = () => {
    const sectionRef = useRef(null);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const section = sectionRef.current;
            if (!section) return;

            const rect = section.getBoundingClientRect();
            const sectionTop = rect.top + window.scrollY;
            const sectionHeight = section.offsetHeight;
            const scrollTop = window.scrollY;

            // Calculate scroll position within the section
            const scrollWithinSection = Math.max(0, scrollTop - sectionTop);
            const scrollPercent = scrollWithinSection / sectionHeight;

            if (scrollPercent <= 0.1) {
                setImageIndex(0);
            } else if (scrollPercent <= 0.3) {
                setImageIndex(1);
            } else {
                setImageIndex(2);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div ref={sectionRef} className="app-store-one-download-app">
            <div className="app-store-one-data-left">
                <div className="app-store-one-heading">
                    <h4>
                    <span>Designed for</span> allscreen resolutions
                    </h4>
                </div>

                <div className="app-store-one-subheading-area">
                    <p>
                        <b>Available on Mobile</b>
                    </p>
                    <br />
                    Enjoy our deliciously homely meals right from your mobile phone. Our
                    app is designed to make ordering easy and convenient. Pick and choose
                    from our varied menu and we'll serve it up, piping hot.
                    <div className="row d-flex">
                        <div className="col-6">
                            <img
                                src="./assets/img/play-store-icon.png"
                                alt="Download from Google Play"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                src="./assets/img/apple-store-icon.png"
                                alt="Download from App Store"
                            />
                        </div>
                    </div>
                </div>

                <div className="app-store-one-subheading-area-2">
                    <p>
                        <b>Available on PC</b>
                    </p>
                    <br />
                    Enjoy the convenience of ordering your favorite meals from your PC.
                    Our web platform ensures that you have full access to our menu and
                    customization options, making meal planning a breeze.
                    <div className="row d-flex">
                        <div className="col-6">
                            <img
                                src="assets/img/pwa.png"
                                alt="Download from Google Play"
                            />
                        </div>
                    </div>
                </div>

                <div className="app-store-one-subheading-area-3">
                    <p>
                        <b>Available on Tablet</b>
                    </p>
                    <br />
                    <h6>
                        Whether at home or on the go, our app provides a seamless experience
                        on your tablet. Customize your meals and order with ease, knowing
                        that we'll deliver them hot and fresh.
                    </h6>
                    <div className="row d-flex">
                        <div className="col-6">
                            <img
                                src="assets/img/pwa-icon.png"
                                alt="Download from Google Play"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-store-one-data-right">
                <img
                    className={`app-store-one-image ${imageIndex === 0 ? "show" : "hide"}`}
                    src="assets/img/phone-ss.png"
                    alt="example"
                />
                <img
                    className={`app-store-one-image ${imageIndex === 1 ? "show" : "hide"}`}
                    src="assets/img/pc-ss.png"
                    alt="example"
                />
                <img
                    className={`app-store-one-image ${imageIndex === 2 ? "show" : "hide"}`}
                    src="assets/img/tablet-ss.png"
                    alt="example"
                />
            </div>
        </div>
    );
};

export default AppStoreOneDownloadApp;

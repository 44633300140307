import React, { useState, useRef, useEffect } from "react";
import Popup from "./Popup";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import ReactSwitch from "react-switch";
import { FaExpand } from "react-icons/fa";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { useCollection } from "../firebase files/hooks/useCollection";
import "./Gallery.css";
import AddMedia from "./AddMedia";
import YoutubeVideos from "./YoutubeVideos";
import Team from "./Team";
import CorporateTwoCallus from "../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoCallus/CorporateTwoCallus";
import CorporateTwoClients from "../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoClients/CorporateTwoClients";
import CorporateTwoDownloadApp from "../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoDownloadApp/CorporateTwoDownloadApp";

export default function Gallery({ section, showAddMoreButton = true }) {
  const location = useLocation();
  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();
  // Define isAdmin here
  const isAdmin = user && user.role == "admin";

  const { documents: dbMediaDocuments, error: dbMediaError } =
    useCollection("media");

  // allImagesSection
  const teamSection =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "TEAM"
    );

  // allImagesSection
  const allImagesSection =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "GALLERY"
    );

  // UpcomingEvents
  const upcomingEvents =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "UPCOMINGEVENTS"
    );

  // BlogsNews
  const blogsNews =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "BLOGSNEWS"
    );

  // certificates
  const certificates =
    dbMediaDocuments &&
    dbMediaDocuments.filter(
      (item) =>
        item.mediaType?.toUpperCase() == "IMAGE" &&
        item.section?.toUpperCase() == "CERTIFICATES"
    );

  const { updateDocument, deleteDocument } = useFirestore("media");
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const videoRef = useRef(null);

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("Offer status updated successfully");
    } else {
      console.error("Error updating status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      console.log("Deleting with productId:", productIdToDelete);

      if (productIdToDelete) {
        await deleteDocument(productIdToDelete);
        console.log("Offer deleted successfully", productIdToDelete);
      } else {
        console.error("No productId specified for deletion");
      }
    } catch (error) {
      console.error("Error deleting:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  useEffect(() => {
    const handleInteraction = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
      document.removeEventListener("click", handleInteraction);
    };

    document.addEventListener("click", handleInteraction);

    return () => {
      document.removeEventListener("click", handleInteraction);
    };
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeFullScreen = () => {
    setSelectedImage(null);
  };

  return (
    <>
    <div className="gallery_parent_div">
      <div className="d-flex align-items-center flex-column pvcy_polcy_head_div">
          <h2>Gallery</h2>
          <p>Snapshots of Our Achievements</p>
        </div>  
        <br/>
      <div className="container">
        <div className="row">
          <Popup
            showPopupFlag={isDeletePopupOpen}
            setShowPopupFlag={setIsDeletePopupOpen}
            msg="Are you sure you want to delete this?"
            setPopupReturn={(confirmed) => {
              if (confirmed) {
                handleConfirmDeleteProduct();
              }
            }}
          />

          {/* START TEAM SECTION */}
          {/* {teamSection && teamSection.length >= 1 ? (
            <div className="section-title">
              <h2>Our Team</h2>
            </div>
          ) : isAdmin ? (
            <div className="section-title">
              <h2>Our Team</h2>
            </div>
          ) : (
            ""
          )}
          {teamSection &&
            teamSection
              .filter((media) =>
                isAdmin
                  ? !section || media.section == "team"
                  : media.status == "active"
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.section}
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>
                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="team"
                pageName="home"
                section="team"
                addMoreVideosButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          )} */}
          {/* START TEAM SECTION */}

          {/* start All Images Gallery Showcase */}
          {allImagesSection && allImagesSection.length >= 1 ? (
            <div className="section-title">
              <h2>Gallery Showcase</h2>
            </div>
          ) : isAdmin ? (
            <div className="section-title">
              <h2>Gallery Showcase</h2>
            </div>
          ) : (
            ""
          )}
          {allImagesSection &&
            allImagesSection
              .filter((media) =>
                isAdmin
                  ? !section || media.section == section
                  : media.status == "active" &&
                    (!section || media.section == section)
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3 mt-2 mb-2"
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.section}
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>
                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          <YoutubeVideos category="more" />
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="showcase"
                pageName="gallery"
                section="gallery"
                addMoreVideosButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          )}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="more"
                pageName="gallery"
                section="more"
                addMoreImagesButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          )}
          {/* End All Images Gallery Showcase */}

          {/* Upcoming Events section */}
          {upcomingEvents && upcomingEvents.length >= 1 ? (
            <div className="section-title">
              <h2>Upcoming Events</h2>
            </div>
          ) : isAdmin ? (
            <div className="section-title">
              <h2>Upcoming Events</h2>
            </div>
          ) : (
            ""
          )}
          {upcomingEvents &&
            upcomingEvents
              .filter((media) =>
                isAdmin
                  ? !section || media.section == "upcomingevents"
                  : media.status == "active"
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.section}
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>

                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          <YoutubeVideos category="upcomingevents" />
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="upcomingevents"
                pageName="gallery"
                section="upcomingevents"
                addMoreVideosButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          )}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="upcomingevents"
                pageName="gallery"
                section="upcomingevents"
                addMoreImagesButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          )}
          {/* End upcomingevents Gallery */}

          {/* blogsnews section */}
          {blogsNews && blogsNews.length >= 0 ? (
            <div className="section-title">
              <br/>
              <h2>Blogs & News</h2>
            </div>
          ) : isAdmin ? (
            <div className="section-title">
              <h2>Blogs & News</h2>
            </div>
          ) : (
            null
          )}
          {blogsNews &&
            blogsNews
              .filter((media) =>
                isAdmin
                  ? !section || media.section == "blogsnews"
                  : media.status == "active"
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.section}
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>

                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          <YoutubeVideos category="blogsnews" />
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="blogsnews"
                pageName="gallery"
                section="blogsnews"
                addMoreVideosButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          )}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="blogsnews"
                pageName="gallery"
                section="blogsnews"
                addMoreImagesButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          )}
          {/* End blogsnews Gallery */}
          {/* certificates section */}
          <br/>
          <br/>
          {certificates && certificates.length >= 1 ? (
            <div className="section-title">
              <h2>Certificates</h2>
            </div>
          ) : isAdmin ? (
            <div className="section-title">
              <h2>Certificates</h2>
            </div>
          ) : (
            ""
          )}
          {certificates &&
            certificates
              .filter((media) =>
                isAdmin
                  ? !section || media.section == "certificates"
                  : media.status == "active"
              )
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 col-lg-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="show_gallery_data">
                      <div style={{ position: "relative" }}>
                        <div className="gallery-fullscreen-icon">
                          <FaExpand onClick={() => handleImageClick(item)} />
                        </div>
                        <img
                          src={item.mediaUrl}
                          alt={item.section}
                          className="show_gallery_data_images"
                          onClick={() => handleImageClick(item)}
                        />
                      </div>

                      {isAdmin && (
                        <>
                          <MDBRow>
                            <MDBCol className="col-9">
                              <MDBCardText className="text-muted">
                                <div className="d-flex mt-2">
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Inactive
                                    </p>
                                  </div>
                                  <ReactSwitch
                                    checked={item.status === "active"}
                                    onChange={() =>
                                      handleToggleChange(item.id, item.status)
                                    }
                                    onColor="#149AA2"
                                    offColor="#F05B83"
                                    className="products-status-switch"
                                  />
                                  <div>
                                    <p
                                      className="active-inactive-text"
                                      style={{
                                        color: "var(--black-color)",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol className="col-3 mt-2">
                              <div>
                                <MDBCardText
                                  onClick={() => handleDeleteProduct(item.id)}
                                >
                                  <span
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: "23px",
                                    }}
                                  >
                                    delete
                                  </span>
                                </MDBCardText>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          {selectedImage && (
            <div className="fullscreen-overlay" onClick={closeFullScreen}>
              <img
                src={selectedImage.mediaUrl}
                alt={selectedImage.title}
                className="fullscreen-image"
              />
            </div>
          )}
          <YoutubeVideos category="certificates" />
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="certificates"
                pageName="gallery"
                section="certificates"
                addMoreVideosButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          )}
          {showAddMoreButton && user && user.role == "admin" && (
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px 0px" }}
            >
              <AddMedia
                category="certificates"
                pageName="gallery"
                section="certificates"
                addMoreImagesButton={false}
                addMoreTextContentButton={false}
              />
            </div>
          )}
          {/* End certificates Gallery */}
        </div>
      </div>
    </div>
      <CorporateTwoClients
        category="partners"
        pageName="gallery"
        section="partners"
        addMoreVideosButton={false}
        addMoreImagesButton={true}
        addMoreTextContentButton={false}
      />
    </>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import "swiper/swiper-bundle.css";
import ReactSwitch from "react-switch";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { projectStorage } from "../../firebase config/config";
import { useCollection } from "../../firebase files/hooks/useCollection";
// import "./Team.scss";
const PGTeam = () => {
  const { user } = useAuthContext();
  const isAdmin = user && user.role === "admin";

  const { documents: dbMediaDocuments } = useCollection("our_team");
  const { addDocument, updateDocument, deleteDocument } = useFirestore("our_team");

  const teamImagesDocuments = dbMediaDocuments
    ?.filter((item) => {
      if (isAdmin) {
        // Admin sees all images
        return item.mediaType.toUpperCase() === "IMAGE";
      }
      // Other roles see only active images
      return item.mediaType.toUpperCase() === "IMAGE" && item.status === "active";
    })
    .sort((a, b) => a.Sequence - b.Sequence) || [];


  if (teamImagesDocuments) {
    teamImagesDocuments.sort((a, b) => a.Sequence - b.Sequence);
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [pageUrl, setPageUrl] = useState("");
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // New state for "Uploading..." message

  const [editDetails, setEditDetails] = useState({
    id: "",
    mediaUrl: "",
    pageUrl: "",
    status: "inactive",
  });

  const handleAddTeamClick = () => {
    setIsPopupOpen(true);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirm = async () => {
    setIsUploading(true); // Show uploading message
    try {
      // Generate a unique file name for the image
      const fileName = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}.jpg`;

      // Upload the image to Firebase Storage
      const storageRef = projectStorage.ref(`team_images/${fileName}`);
      const uploadTask = await storageRef.putString(selectedImage, "data_url");

      // Get the download URL of the uploaded image
      const mediaUrl = await uploadTask.ref.getDownloadURL();

      // Add the document to Firestore
      await addDocument({
        mediaUrl,
        pageUrl,
        mediaType: "IMAGE",
        status: "active", // Default status
        createdAt: new Date(), // Optional: add a timestamp
      });

      alert("Image added successfully!");
    } catch (error) {
      console.error("Error adding image:", error);
      alert("Failed to add the image. Please try again.");
    }

    setIsUploading(false); // Hide uploading message
    setIsPopupOpen(false);
    setSelectedImage(null);
    setPageUrl("");
  };


  const handleCancel = () => {
    setIsPopupOpen(false);
    setSelectedImage(null);
    setIsEditPopupOpen(false);
    setPageUrl("");
  };

  const handleEditClick = (image) => {
    setEditDetails({
      id: image.id,
      mediaUrl: image.mediaUrl,
      pageUrl: image.pageUrl,
      status: image.status,
    });
    setIsEditPopupOpen(true);
  };

  const handleEditSave = async () => {
    const { id, pageUrl, status } = editDetails;

    try {
      await updateDocument(id, { pageUrl, status });
      alert("Details updated successfully!");
    } catch (error) {
      console.error("Error updating details:", error);
      alert("Failed to update details.");
    }
    setIsEditPopupOpen(false);
  };

  const handleEditDelete = async () => {
    try {
      await deleteDocument(editDetails.id);
      alert("Document deleted successfully!");
    } catch (error) {
      console.error("Error deleting document:", error);
      alert("Failed to delete document.");
    }
    setIsEditPopupOpen(false);
  };
  return (
    <div className="container mt-4">
      <div className="row">
        {teamImagesDocuments && teamImagesDocuments.map((teamImage) => (
          <div key={teamImage.id} className="col-6 col-md-4 col-lg-3 default_template_team_image_wrapper mt-2 mb-2 m-0">
            <Link to={teamImage.pageUrl}>
              <img
                src={teamImage.mediaUrl}
                alt="Offer"
                className="default_template_team_offer_img_carousel"
              />
            </Link>

            {isAdmin && (
              <span
                className="default_template_team_edit_icon material-symbols-outlined"
                onClick={() => handleEditClick(teamImage)}
              >
                edit
              </span>
            )}
          </div>

        ))}
      </div>

      <br />
      <div className="d-flex justify-content-center">
        {isAdmin && <button className="btn_fill" onClick={handleAddTeamClick}>
          Add Team
        </button>}
      </div>
      <br />

      {isPopupOpen && (
        <div className="default_template_team_popup_overlay">
          <div className="default_template_team_popup">
            <h3>Select an Image</h3>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="default_template_team_file_input"
            />
            {selectedImage && (
              <div>
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="default_template_team_selected_img"
                />
                <input
                  type="text"
                  placeholder="Enter page URL"
                  value={pageUrl}
                  onChange={(e) => setPageUrl(e.target.value)}
                  className="default_template_team_page_url_input"
                />
                <div className="d-flex justify-content-between">
                  {isUploading ? (
                    <span>Uploading...</span>
                  ) : (
                    <>
                      <button onClick={handleCancel} className="btn_border">
                        Cancel
                      </button>
                      <button onClick={handleConfirm} className="btn_fill">
                        Confirm
                      </button>
                    </>
                  )}
                </div>

              </div>
            )}
            {!selectedImage && (
              <>
                <button onClick={handleCancel} className="btn_border">
                  Cancel
                </button></>
            )}
          </div>
        </div>
      )}

      {isEditPopupOpen && (
        <div className="default_template_team_popup_overlay">
          <div className="default_template_team_popup_content">
            <img src={editDetails.mediaUrl} alt="Edit" />
            <input
              type="text"
              value={editDetails.pageUrl}
              onChange={(e) => setEditDetails({ ...editDetails, pageUrl: e.target.value })}
            />
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <p className="active-inactive-text" style={{ color: "red" }}>
                  Inactive
                </p>
                <ReactSwitch
                  checked={editDetails.status === "active"}
                  onChange={(checked) =>
                    setEditDetails({ ...editDetails, status: checked ? "active" : "inactive" })
                  }
                  onColor="#149AA2"
                  offColor="#F05B83"
                  className="products-status-switch"
                />
                <p className="active-inactive-text" style={{ color: "red" }}>
                  Active
                </p>
              </div>
              <span class="material-symbols-outlined" onClick={handleEditDelete}>
                delete
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <button onClick={handleCancel} className="btn_border">
                Cancel
              </button>
              <button onClick={handleEditSave} className="btn_fill">Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PGTeam;

import React, { useState } from "react";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import ReactSwitch from "react-switch";
import Popup from "./Popup";
import "./YoutubeVideos.css";

const YoutubeVideos = ({ category }) => {
  const { user } = useAuthContext();
  // const { documents: media } = useCollection("media");
  const { updateDocument } = useFirestore("media");
  const { deleteDocument } = useFirestore("media");

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const { documents: dbVideoDocuments, error: dbVideoError } = useCollection(
    "media",
    ["mediaType", "==", "video"]
  );
  // console.log("dbVideoDocuments:", dbVideoDocuments);
  // const offerVideoDocuments =
  //   dbVideoDocuments &&
  //   dbVideoDocuments.filter((item) => item.status.toUpperCase() == "ACTIVE");

  const getEmbedUrl = (mediaUrl) => {
    if (typeof mediaUrl === "string") {
      // Check for YouTube shorts URL
      const shortsMatch = mediaUrl.match(/youtube\.com\/shorts\/([\w-]+)\??/);
      if (shortsMatch) {
        const mediaId = shortsMatch[1];
        return `https://www.youtube.com/embed/${mediaId}`;
      }

      // Check for regular YouTube media URL
      const regularMatch = mediaUrl.match(/youtu\.be\/([\w-]+)/);
      if (regularMatch) {
        const mediaId = regularMatch[1];
        return `https://www.youtube.com/embed/${mediaId}`;
      }
    }
    // Add other social platforms as needed
    // Example for Vimeo: if (mediaUrl.includes("vimeo.com/")) return "vimeo embed URL";
    // Default case
    return mediaUrl;
  };

  const handleToggleChange = async (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    // Use the updateDocument function to update the status
    const updatedDocument = await updateDocument(productId, {
      status: newStatus,
    });

    if (updatedDocument && updatedDocument.success) {
      console.log("Video status updated successfully");
    } else {
      console.error("Error updating media status");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      setIsDeletePopupOpen(true);
      setProductIdToDelete(productId);
    } catch (error) {
      console.error("Error opening delete confirmation popup:", error);
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      console.log("Deleting media with productId:", productIdToDelete);

      if (productIdToDelete) {
        await deleteDocument(productIdToDelete);
        console.log("Video deleted successfully", productIdToDelete);
      } else {
        console.error("No productId specified for deletion");
      }
    } catch (error) {
      console.error("Error deleting media:", error);
    } finally {
      setIsDeletePopupOpen(false);
      setProductIdToDelete(null);
    }
  };

  return (
    <div className="utube_video_parent">
      <Popup
        showPopupFlag={isDeletePopupOpen}
        setShowPopupFlag={setIsDeletePopupOpen}
        msg="Are you sure you want to delete this youtube video?"
        setPopupReturn={(confirmed) => {
          if (confirmed) {
            handleConfirmDeleteProduct();
          }
        }}
      />
      <div>
        <div className="row">
          {dbVideoDocuments &&
            dbVideoDocuments
              .filter((media) =>
                user && user.role == "admin"
                  ? !category || media.category == category
                  : media.status == "active" &&
                    (!category || media.category == category)
              )
              .map((media, index) => (
                <div key={index} className="col-lg-3 col-md-4 col-6 mb-4">
                  {/* Adjust the column sizes based on screen size */}
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      title={`Social Video ${index + 1}`}
                      // width="100%"
                      // height="300"
                      src={getEmbedUrl(media.mediaUrl)}
                      frameBorder="0"
                      allowFullScreen
                      className="youtube-media-style"
                    ></iframe>
                    {user && user.role == "admin" && (
                      <>
                        <MDBRow>
                          <MDBCol className="col-9">
                            <MDBCardText className="text-muted">
                              <div className="d-flex align-items-center justify-content-center">
                                {/* <MDBCardText>
                                  Status:&nbsp;&nbsp;&nbsp;{" "}
                                </MDBCardText> */}
                                <div>
                                  <p className="active-inactive-text">
                                    Inactive
                                  </p>
                                </div>
                                <ReactSwitch
                                  checked={media.status === "active"}
                                  onChange={() =>
                                    handleToggleChange(media.id, media.status)
                                  }
                                  onColor="#149AA2"
                                  offColor="#F05B83"
                                  className="products-status-switch"
                                />
                                <div>
                                  <p className="active-inactive-text">Active</p>
                                </div>
                              </div>
                            </MDBCardText>
                          </MDBCol>
                          <MDBCol className="col-3 d-flex align-items-center">
                            <div>
                              <MDBCardText
                                onClick={() => handleDeleteProduct(media.id)}
                              >
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    fontSize: "23px",
                                  }}
                                >
                                  delete
                                </span>
                              </MDBCardText>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default YoutubeVideos;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./BusinessThreeBanners.scss";
import PGLoaders from "../../../../default pages/PGLoaders/PGLoaders";
import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";

// FIREBASE FILES
import { useCollection } from "../../../../firebase files/hooks/useCollection";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../../../../firebase config/config";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../../firebase files/hooks/useDocument";

const BusinessThreeBanners = () => {
  const { user } = useAuthContext();

  const [banners, setBanners] = useState([]);
  const [showAllBanners, setShowAllBanners] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [selectedPage, setSelectedPage] = useState("");
  const [selectedNewTab, setSelectedNewTab] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [pageUrl, setPageUrl] = useState("");
  const [newBanner, setNewBanner] = useState({
    image: "",
    heading: "",
    subheading: "",
  });
  const [buttonChecked, setButtonChecked] = useState(false);
  const [newTabChecked, setNewTabChecked] = useState(false);
  const [selectedBannerImage, setBannerSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentBanner, setCurrentBanner] = useState(0);
  const [errorMessage, setErrorMessage] = useState();


  //SET ALL BANNERS COMPONENTS START
  const bannerComponents = [
    { bannerUrl: "/assets/img/Screenshot 2024-08-13 143956.png", bannerName: "BusinessThreeBanners" },
    { bannerUrl: "/assets/img/Screenshot 2024-08-13 144245.png", bannerName: "BusinessOneBanner" },
    { bannerUrl: "/assets/img/Screenshot 2024-08-13 144116.png", bannerName: "CorporateTwoBanner" },
    { bannerUrl: "/assets/img/Screenshot 2024-08-13 144033.png", bannerName: "CorporateOneBanner" },
    { bannerUrl: "/assets/img/Screenshot 2024-08-13 144416.png", bannerName: "HomeHeroBanner" }
  ];

  const handleNoteStackClick = () => {
    setShowAllBanners(!showAllBanners);
  };
  const handleImageClick = async (clickedBannerName) => {
    console.log("Clicked Banner:", clickedBannerName);

    if (dbBannersDocuments) {
      const updatedBanners = dbBannersDocuments.banners.map((banner) => {
        if (banner.templateName === activeTemplate) {
          return { ...banner, activeBanner: clickedBannerName };
        } else {
          return banner;
        }
      });

      // Update the Firestore document
      try {
        await updateDocument("banners", { banners: updatedBanners });
        console.log("Banner updated successfully");
      } catch (error) {
        console.error("Error updating banner:", error);
      }
    }
  };

  // SET ALL BANNERS COMPONENTS END

  // BANNERS DOCUMENT
  const { document: dbBannersDocuments, error: dbBannersError } =
    useDocument("settings", "banners");


  // ACTIVE TEMPLATE DOCUMENT
  const { document: dbActiveTemplateDocuments, error: dbActiveTemplateError } =
    useDocument("settings", "templates");

  const { documents: homeBanners, error: homeBannersError } =
    useCollection("banners");

  const { updateDocument, response } = useFirestore("settings");

  let activeTemplate = dbActiveTemplateDocuments && dbActiveTemplateDocuments.activeTemplate;

  console.log("activeTemplate", activeTemplate);


  const [isBannerLoading, setIsBannerLoading] = useState(true);

  useEffect(() => {
    setIsBannerLoading(!homeBanners);
  }, [homeBanners]);

  useEffect(() => {
    if (homeBanners) {
      setBanners(homeBanners);
    }
  }, [homeBanners]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
    }, 7000);
    return () => clearInterval(interval);
  }, [banners]);

  const handleAddBanner = () => {
    setShowPopup(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewBanner({ ...newBanner, image: URL.createObjectURL(file) });
      setBannerSelectedImage(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "externalurl") {
      let url = value.trim();

      // Automatically add "https://" if the URL doesn't start with "https://" or "http://"
      if (url && !url.startsWith("https://") && !url.startsWith("http://")) {
        url = "https://" + url;
      }

      setPageUrl(url);
      setNewBanner({ ...newBanner, externalurl: url });
    } else {
      setNewBanner({ ...newBanner, [name]: value });
    }
  };

  const handleConfirm = async () => {
    if (buttonChecked && !buttonName) {
      setErrorMessage("Button Name cannot be empty if add button is selected.");
      return;
    }

    if (!selectedBannerImage) {
      setErrorMessage("Please select an image before upload");
      return;
    }

    setIsLoading(true);

    try {
      const newBannerDoc = await projectFirestore.collection("banners").add({
        pageName: "home",
        mediaType: "image",
        heading: newBanner.heading,
        subHeading: newBanner.subheading,
        category: "Banner",
        section: "Banner",
        status: "active",
        createdAt: timestamp.fromDate(new Date()),
        imageUrl: "",
        newTab: selectedNewTab,
        buttonName: buttonChecked ? buttonName : "",
        pageUrl: buttonChecked ? pageUrl : "",
      });

      const storageRef = projectStorage.ref(
        `banners/${newBannerDoc.id}/${selectedBannerImage.name}`
      );
      const uploadTask = storageRef.put(selectedBannerImage);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Error uploading image:", error);
          setIsLoading(false);
        },
        async () => {
          const imageUrl = await uploadTask.snapshot.ref.getDownloadURL();
          await newBannerDoc.update({ imageUrl });
          setIsLoading(false);
          setShowPopup(false);

          setBanners((prevBanners) => [
            ...prevBanners,
            {
              imageUrl,
              heading: newBanner.heading,
              subheading: newBanner.subheading,
            },
          ]);

          setNewBanner({
            image: "",
            heading: "",
            subheading: "",
          });
          setBannerSelectedImage(null);
          setErrorMessage("");
        }
      );
    } catch (error) {
      console.error("Error adding banner:", error);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setShowPopup(false);
    setNewBanner({
      image: "",
      heading: "",
      subheading: "",
    });
    setBannerSelectedImage(null);
    setErrorMessage("");
  };

  const handleButtonCheckboxChange = (e) => {
    setButtonChecked(e.target.checked);
    setShowSecondPopup(e.target.checked);
  };

  const handleTabCheckboxChange = (e) => {
    setNewTabChecked(e.target.checked);
    setSelectedNewTab(e.target.checked);
  };

  const handleButtonNameChange = (e) => {
    setButtonName(e.target.value);
  };

  const handleRadioChange = (e) => {
    const selectedPage = e.target.value;
    setSelectedPage(selectedPage);
    setPageUrl(selectedPage);
    setNewBanner((prev) => ({ ...prev, externalurl: "" }));
  };

  const handleSelectPage = (e) => {
    if (!selectedPage && !pageUrl) {
      setButtonChecked(false);
    } else {
      setButtonChecked(true);
    }
    setShowSecondPopup(false);
  };

  const handleCancelSelectPage = () => {
    setShowSecondPopup(false);
    setButtonChecked(false);
  };

  return (
    <div>
      <div className="business-three-home-banners-parent">
        {isBannerLoading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "400px" }}
          >
            <PGLoaders />
          </div>
        ) : (
          <div>
            {banners.length > 0 &&
              banners.map((banner, index) => (
                <div
                  key={index}
                  className={`${banner.heading === "" || banner.subheading === ""
                    ? `business-three-home-banner-without-text ${currentBanner === index ? "visible" : "hidden"
                    }`
                    : `business-three-home-banners-banner ${currentBanner === index ? "visible" : "hidden"
                    }`
                    }`}
                >
                  <img src={banner.imageUrl} alt={banner.heading} />
                  <div className="business-three-home-banners-banner-content">
                    <h2>{banner.heading}</h2>
                    <p>{banner.subHeading}</p>
                    {banner.buttonName && (
                      <Link
                        to={banner.pageUrl}
                        target={banner.newTab ? "_blank" : "_self"}
                      >
                        <button className="btn_fill">
                          {banner.buttonName}
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              ))}

            {showAllBanners && (
              <div className={`business-three-all-banners-container container ${showAllBanners ? '' : 'hide'}`}>
                <div className="row">
                  {bannerComponents.map((banner, index) => (
                    <div key={index} className="col-6 col-md-4 col-lg-3">
                      <img src={banner.bannerUrl} alt={banner.bannerName} onClick={() => handleImageClick(banner.bannerName)}
                        style={{ animationDelay: `${index * 0.2}s` }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {user && user.role === "admin" && (
              <span class="material-symbols-outlined note-stack-icon" onClick={handleNoteStackClick}
                title="Change Banner Design"  >
                note_stack
              </span>
            )}
          </div>
        )}
      </div>

      {/* ADD BANNER BTN */}
      {user && user.role == "admin" && (
        <div className="d-flex align-items-center">
          <button className="btn_fill" onClick={handleAddBanner}>
            Add Banner
          </button>
        </div>
      )}

      {/* START POPUP FOR ADD BANNER */}
      {showPopup && (
        <div className="business-three-home-banners-popup">
          <div className="business-three-home-banners-popup-content">
            <h3>New Banner</h3>
            <div className="business-three-home-banners-image-upload-container">
              {newBanner.image ? (
                <div className="business-three-home-banners-selected-image">
                  <img
                    src={newBanner.image}
                    alt="Selected"
                    className={isLoading ? "uploading" : ""}
                  />
                  {isLoading && (
                    <div className="uploading-gif-container">
                      <img
                        src="/assets/img/cloud-upload.gif"
                        alt="Uploading"
                        className="uploading-gif"
                      />
                    </div>
                  )}
                  <label
                    htmlFor="imageUpload"
                    className="business-three-home-banners-edit-icon"
                  >
                    <input
                      type="file"
                      id="imageUpload"
                      onChange={handleImageChange}
                    />
                    <span
                      className="material-symbols-outlined"
                      style={{ color: "white" }}
                    >
                      edit
                    </span>
                  </label>
                  <div
                    className={`${newBanner.heading || newBanner.subheading
                      ? "business-three-home-banners-image-text"
                      : ""
                      }`}
                  >
                    <h2>{newBanner.heading}</h2>
                    <p>{newBanner.subheading}</p>
                    {buttonName && (
                      <button className="btn_fill" style={{ width: "40%" }}>
                        {buttonName}
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <label
                  htmlFor="imageUpload"
                  className="business-three-home-banners-edit-icon-placeholder"
                >
                  <input
                    type="file"
                    id="imageUpload"
                    onChange={handleImageChange}
                  />
                  <span className="material-symbols-outlined">
                    add_photo_alternate
                  </span>
                </label>
              )}
            </div>
            <input
              type="text"
              name="heading"
              placeholder="Heading"
              value={newBanner.heading}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="subheading"
              placeholder="Subheading"
              value={newBanner.subheading}
              onChange={handleInputChange}
            />

            <div className="business-three-banner-btn-div d-flex">
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  checked={buttonChecked}
                  onChange={handleButtonCheckboxChange}
                />
                &nbsp; Add button on banner
              </label>
            </div>
            {buttonChecked && (
              <input
                type="text"
                name="buttonName"
                placeholder="Button Name"
                value={newBanner.buttonname}
                onChange={handleButtonNameChange}
              />
            )}

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="d-flex justify-content-between">
              <button className="btn_fill" onClick={handleConfirm}>
                Confirm
              </button>
              <button className="btn_border" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
          {showSecondPopup && (
            <div className="business-three-home-banners-popup-two">
              <div className="business-three-home-banners-popup-content-two">
                <h4>Select Button Location</h4>
                <br />
                <div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="/home"
                        checked={selectedPage === "/home"}
                        onChange={handleRadioChange}
                      />
                      Home
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="/bookinglist"
                        checked={selectedPage === "/bookinglist"}
                        onChange={handleRadioChange}
                      />
                      Bookings
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="/gallery"
                        checked={selectedPage === "/gallery"}
                        onChange={handleRadioChange}
                      />
                      Gallery
                    </label>
                  </div>

                  <div>
                    <label>
                      <input
                        type="radio"
                        value="/notifications"
                        checked={selectedPage === "/notifications"}
                        onChange={handleRadioChange}
                      />
                      Notification
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="//products"
                        checked={selectedPage === "/services"}
                        onChange={handleRadioChange}
                      />
                      Products
                    </label>
                  </div>

                  <div>
                    <label>
                      <input
                        type="radio"
                        value="/contact"
                        checked={selectedPage === "/contact"}
                        onChange={handleRadioChange}
                      />
                      Contact Us
                    </label>
                  </div>

                  <div>
                    <label>
                      <input
                        type="radio"
                        value="/aboutus"
                        checked={selectedPage === "/aboutus"}
                        onChange={handleRadioChange}
                      />
                      About Us
                    </label>
                  </div>
                </div>

                <input
                  type="text"
                  name="externalurl"
                  placeholder="External URL"
                  value={newBanner.externalurl}
                  onChange={handleInputChange}
                />

                <div className="d-flex">
                  <input
                    type="checkbox"
                    checked={newTabChecked}
                    onChange={handleTabCheckboxChange}
                  />
                  <p>Open in new tab</p>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <button
                    className="btn_border"
                    onClick={handleCancelSelectPage}
                  >
                    Cancel
                  </button>
                  <button className="btn_fill" onClick={handleSelectPage}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* END POPUP FOR ADD BANNER */}
    </div>
  );
};

export default BusinessThreeBanners;

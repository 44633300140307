import React, { useState } from "react";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import useStaffAssignments from "../firebase files/hooks/useStaffAssignments";
import "./StaffAssignment.scss";

const OrderDetails = ({ order, isExpanded, onToggle }) => {
  return (
    <div className="order-details-accordion">
      <button className="accordion-header" onClick={onToggle}>
        <span>Order Details</span>
        <span
          className={`material-symbols-outlined ${
            isExpanded ? "expanded" : ""
          }`}
        >
          expand_more
        </span>
      </button>

      {isExpanded && (
        <div className="accordion-content">
          <div className="products-list">
            <h4>Ordered Products</h4>
            {order.products.map((product, index) => (
              <div key={index} className="product-item">
                <img src={product.images[0]} alt={product.name} />
                <div className="product-info">
                  <p className="product-name">{product.name}</p>
                  <p className="product-quantity">
                    Quantity: {product.quantity}
                  </p>
                  <p className="product-price">{product.price}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="order-summary">
            <h4>Order Summary</h4>
            <p>
              Total Items:{" "}
              {order.products.reduce((acc, curr) => acc + curr.quantity, 0)}
            </p>
            <p>Order Total: {order.totalPrice}</p>
            {order.notes && <p>Notes: {order.notes}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

const StaffAssignment = () => {
  const { user } = useAuthContext();
  const { assignedOrders, isLoading, markTaskComplete } = useStaffAssignments();
  const [activeTab, setActiveTab] = useState("pickup");
  const [expandedOrders, setExpandedOrders] = useState(new Set());

  const toggleOrderDetails = (orderId) => {
    setExpandedOrders((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(orderId)) {
        newSet.delete(orderId);
      } else {
        newSet.add(orderId);
      }
      return newSet;
    });
  };

  if (isLoading) {
    return (
      <div className="staff-assignments">
        <div className="loading">Loading assignments...</div>
      </div>
    );
  }

  return (
    <div className="staff-assignments">
      <div className="tabs">
        <button
          className={`tab ${activeTab === "pickup" ? "active" : ""}`}
          onClick={() => setActiveTab("pickup")}
        >
          <span className="material-symbols-outlined">local_shipping</span>
          Pickup Tasks ({assignedOrders?.pickup?.length || 0})
        </button>
        <button
          className={`tab ${activeTab === "delivery" ? "active" : ""}`}
          onClick={() => setActiveTab("delivery")}
        >
          <span className="material-symbols-outlined">local_post_office</span>
          Delivery Tasks ({assignedOrders?.delivery?.length || 0})
        </button>
        <button
          className={`tab ${activeTab === "history" ? "active" : ""}`}
          onClick={() => setActiveTab("history")}
        >
          <span className="material-symbols-outlined">history</span>
          History ({assignedOrders?.history?.length || 0})
        </button>
      </div>

      {!assignedOrders[activeTab]?.length ? (
        <div className="no-assignments">
          <span className="material-symbols-outlined">assignment</span>
          <p>No {activeTab} tasks</p>
        </div>
      ) : (
        <div className="assignments-list">
          {assignedOrders[activeTab].map((order) => {
            const isPickup =
              activeTab === "pickup" ||
              (activeTab === "history" && order.completedTaskType === "pickup");
            const isCompleted = activeTab === "history";

            return (
              <div
                key={`${order.id}-${isPickup ? "pickup" : "delivery"}`}
                className="assignment-card"
              >
                <div className="assignment-header">
                  <div className="role-badge">
                    <span className="material-symbols-outlined">
                      {isPickup ? "local_shipping" : "local_post_office"}
                    </span>
                    {isPickup ? "Pickup Assignment" : "Delivery Assignment"}
                  </div>
                  <div className="order-id">#{order.id}</div>
                </div>

                <div className="assignment-details">
                  <div className="detail-item">
                    <span className="label">Address:</span>
                    <p>
                      {order.shippingAddress.name} -{" "}
                      {order.shippingAddress.houseNumber},{" "}
                      {order.shippingAddress.streetLineOne}, {""}
                      {order.shippingAddress.streetLineTwo},{" "}
                      {order.shippingAddress.landmark}, {""}
                      {order.shippingAddress.city}, {""}
                      {order.shippingAddress.pincode},{" "}
                      {order.shippingAddress.state}
                    </p>
                  </div>
                  <div className="detail-item">
                    <span className="label">Customer Contact:</span>
                    <p>
                      {order.assignedUserDetails?.displayName || "N/A"} -{" "}
                      {order.assignedUserDetails?.phoneNumber || "N/A"}
                    </p>
                  </div>

                  <OrderDetails
                    order={order}
                    isExpanded={expandedOrders.has(order.id)}
                    onToggle={() => toggleOrderDetails(order.id)}
                  />
                </div>

                {!isCompleted && (
                  <div className="assignment-actions">
                    <button
                      className="complete-btn"
                      onClick={() =>
                        markTaskComplete(
                          order,
                          isPickup ? "pickup" : "delivery"
                        )
                      }
                    >
                      <span className="material-symbols-outlined">
                        check_circle
                      </span>
                      Mark {isPickup ? "Pickup" : "Delivery"} Complete
                    </button>
                  </div>
                )}
                {isCompleted && (
                  <div className="completion-info">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Completed on{" "}
                    {(() => {
                      const timestamp = isPickup
                        ? order.orderStages?.itemsPickedUp?.timestamp
                        : order.orderStages?.delivered?.timestamp;

                      if (!timestamp?.toDate) return "Date not available";

                      return timestamp.toDate().toLocaleDateString();
                    })()}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StaffAssignment;

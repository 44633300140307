import React, { useState } from "react";
import { Line, Pie } from "react-chartjs-2";
import { 
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { FormControl, Select, MenuItem } from "@mui/material";
import { subWeeks, format } from "date-fns";
import './AdminHome.scss';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

const AdminHome = () => {
    const [selectedWeek, setSelectedWeek] = useState(0);

    // Sample data - replace with actual API data
    const orderData = {
        labels: [...Array(7)].map((_, i) => 
            format(subWeeks(new Date(), selectedWeek).setDate(
                new Date().getDate() - 6 + i
            ), 'MMM dd')
        ),
        datasets: [{
            label: 'Daily Orders',
            data: [12, 19, 15, 25, 22, 30, 28],
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    };

    const orderSourceData = {
        labels: ['Ironing', 'Dry cleaning','Wash & Fold','Steam Cleaning'],
        datasets: [{
            data: [15, 25, 35, 25],
            backgroundColor: [
                'rgb(54, 162, 235)',
                'rgb(255, 99, 132)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)'
            ]
        }]
    };

    // Sample data for recent orders
    const recentOrders = [
        { id: '#ORD-123', customer: 'John Doe', date: '2024-03-15', status: 'Completed', amount: '$156.00' },
        { id: '#ORD-124', customer: 'Jane Smith', date: '2024-03-14', status: 'Processing', amount: '$89.99' },
        { id: '#ORD-125', customer: 'Mike Johnson', date: '2024-03-14', status: 'Pending', amount: '$245.50' },
        { id: '#ORD-126', customer: 'Sarah Williams', date: '2024-03-13', status: 'Completed', amount: '$124.00' },
        { id: '#ORD-127', customer: 'Tom Brown', date: '2024-03-13', status: 'Processing', amount: '$78.50' },
    ];

    // Updated sample data for vendor statistics
    const vendorStats = [
        { name: 'Vendor 1', orders: 156 },
        { name: 'Vendor 2', orders: 129 },
        { name: 'Vendor 3', orders: 98 },
        { name: 'Vendor 4', orders: 87 },
        { name: 'Vendor 5', orders: 76 },
    ];

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            }
        },
        height: 300 // specify height
    };

    return (
        <div className="admin-home">
            <h1>Dashboard Overview</h1>

            <div className="admin-home-section admin-home-cards-section">
                <div className="admin-home-card">
                    <div className="admin-home-card-header">
                        <span className="material-symbols-outlined">currency_rupee</span>
                        <h2>Total Revenue</h2>
                    </div>
                    <div className="admin-home-card-body">
                        <h3>53,00,000 /-</h3>
                        <span className="material-symbols-outlined" style={{color: 'green'}}>arrow_outward</span>
                    </div>
                </div>

                <div className="admin-home-card">
                    <div className="admin-home-card-header">
                        <span className="material-symbols-outlined">shopping_cart</span>
                        <h2>Total Orders</h2>
                    </div>
                    <div className="admin-home-card-body">
                        <h3>15720</h3>
                        <span className="material-symbols-outlined" style={{color: 'green'}}>arrow_outward</span>
                    </div>
                </div>

                <div className="admin-home-card">
                    <div className="admin-home-card-header">
                        <span className="material-symbols-outlined">groups</span>
                        <h2>Total Customers</h2>
                    </div>
                    <div className="admin-home-card-body">
                        <h3>7500</h3>
                        <span className="material-symbols-outlined" style={{color: 'green'}}>arrow_outward</span>
                    </div>
                </div>

                <div className="admin-home-card">
                    <div className="admin-home-card-header">
                        <span className="material-symbols-outlined">percent</span>
                        <h2>Profit Percentage</h2>
                    </div>
                    <div className="admin-home-card-body">
                        <h3>10%</h3>
                        <span className="material-symbols-outlined" style={{color: 'green'}}>arrow_outward</span>
                    </div>
                </div>

            </div>
            
            <div className="admin-home-section admin-home-charts-section">
                <div className="chart-container">
                    <div className="chart-header">
                        <h2>Weekly Order Trends</h2>
                        <FormControl size="small">
                            <Select
                                value={selectedWeek}
                                onChange={(e) => setSelectedWeek(e.target.value)}
                            >
                                {[0, 1, 2, 3].map((week) => (
                                    <MenuItem key={week} value={week}>
                                        {week === 0 ? 'Current Week' : `${week} Week${week > 1 ? 's' : ''} Ago`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="chart-wrapper">
                        <Line data={orderData} options={chartOptions} />
                    </div>
                </div>

                <div className="chart-container">
                    <h2>Categories Distribution</h2>
                    <div className="chart-wrapper">
                        <Pie data={orderSourceData} options={chartOptions} />
                    </div>
                </div>
            </div>

            <div className="admin-home-section admin-home-info-section">
                <div className="recent-orders">
                    <div className="section-header">
                        <h2>Recent Orders</h2>
                        <button className="view-all">View All</button>
                    </div>
                    <div className="orders-list">
                        {recentOrders.map((order) => (
                            <div key={order.id} className="order-item">
                                <div className="order-main">
                                    <span className="order-id">{order.id}</span>
                                    <span className="customer">{order.customer}</span>
                                </div>
                                <div className="order-details">
                                    <span className="date">{order.date}</span>
                                    <span className={`status ${order.status.toLowerCase()}`}>
                                        {order.status}
                                    </span>
                                    <span className="amount">{order.amount}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="vendor-stats">
                    <h2>Top Vendors</h2>
                    <div className="vendors-list">
                        {vendorStats.map((vendor, index) => (
                            <div key={index} className="vendor-item">
                                <span className="vendor-name">{vendor.name}</span>
                                <span className="order-count">{vendor.orders} orders</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminHome;
import React from "react";
import "./CorporateTwoOrderStats.scss";
import useOrders from "../../../../../firebase files/hooks/useOrders";
import { useAdminOrders } from "../../../../../firebase files/hooks/useAdminOrders";
import useStaffAssignments from "../../../../../firebase files/hooks/useStaffAssignments";
import { useAuthContext } from "../../../../../firebase files/hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

const CorporateTwoOrderStats = () => {
  const { user } = useAuthContext();
  const { orders: userOrders } = useOrders();
  const { orders: adminOrders } = useAdminOrders();
  const { assignedOrders: staffAssignedOrders } = useStaffAssignments();
  const navigate = useNavigate();

  const lengthOfCurrentUserOrders = userOrders.filter(
    (order) => order.orderStages.delivered.completed !== true
  ).length;

  const lengthOfActiveAdminOrders = adminOrders.filter(
    (order) => order.orderStages.delivered.completed !== true
  ).length;

  return (
    <div className="corporateTwo-order-stats-container">
      <div className="stats-card">
        <div className="background-icons">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="material-symbols-outlined bg-stats-icon icon-left"
            viewBox="0 -960 960 960"
          >
            <path d="M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h480q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80H240Zm0-80h480v-640H240v640Zm240-40q83 0 141.5-58.5T680-400q0-83-58.5-141.5T480-600q-83 0-141.5 58.5T280-400q0 83 58.5 141.5T480-200Zm0-68q-26 0-50.5-9.5T386-306l188-188q19 19 28.5 43.5T612-400q0 55-38.5 93.5T480-268ZM320-680q17 0 28.5-11.5T360-720q0-17-11.5-28.5T320-760q-17 0-28.5 11.5T280-720q0 17 11.5 28.5T320-680Zm120 0q17 0 28.5-11.5T480-720q0-17-11.5-28.5T440-760q-17 0-28.5 11.5T400-720q0 17 11.5 28.5T440-680ZM240-160v-640 640Z" />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" className="material-symbols-outlined bg-stats-icon icon-right" viewBox="0 -960 960 960" fill="currentColor">
          <path d="M280-80v-240h-64q-40 0-68-28t-28-68q0-29 16-53.5t42-36.5l262-116v-26q-36-13-58-43.5T360-760q0-50 35-85t85-35q50 0 85 35t35 85h-80q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760q0 17 11.5 28.5T480-720t28.5 11.5Q520-697 520-680v58l262 116q26 12 42 36.5t16 53.5q0 40-28 68t-68 28h-64v240H280Zm-64-320h64v-40h400v40h64q7 0 11.5-5t4.5-13q0-5-2.5-8.5T750-432L480-552 210-432q-5 2-7.5 5.5T200-418q0 8 4.5 13t11.5 5Zm144 240h240v-200H360v200Zm0-200h240-240Z" />
          </svg>
        </div>

        <div className="stats-header">
          <h2>Active Orders</h2>
        </div>

        <div className="stats-content">
          <div className="stats-number">
            {user.role === "admin" ? (
              <h3>{lengthOfActiveAdminOrders}</h3>
            ) : user.role === "driver" ? (
              <h3>
                {staffAssignedOrders?.pickup.length ||
                  staffAssignedOrders?.delivery.length}
              </h3>
            ) : (
              <h3>{lengthOfCurrentUserOrders}</h3>
            )}
          </div>

          <button onClick={() => navigate("/orders")} className="stats-button">
            <span className="material-symbols-outlined">visibility</span>
            View Orders
          </button>
        </div>
      </div>
    </div>
  );
};

export default CorporateTwoOrderStats;
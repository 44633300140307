import { React, useState, useEffect } from "react";
import "./TemplateSettings.scss";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
const TemplateSettings = () => {
  const { user } = useAuthContext();

  const { updateDocument } = useFirestore("settings");
  const [activeTemplate, setActiveTemplate] = useState("");

  const { document: templatesTypeDoc } = useDocument("settings", "templates");

  useEffect(() => {
    if (templatesTypeDoc) {
      setActiveTemplate(templatesTypeDoc.activeTemplate || "");
    }
  }, [templatesTypeDoc]);

  const templateTypeLabels = {
    defaultTemplate: "Default Template",
    firstTemplate: "First Template",
    // secondTemplate: "Second Template",
    // thirdTemplate: "Third Template",
    fourthTemplate: "Fourth Template",
    fifthTemplate: "Fifth Template",
    eventOne: "Event One",
    educationOne:"Education One",
    educationTwo:"Education Two",
    securityOne:"Accessfy",
    appStoreOne:"App Store",
  };

  const handleTemplateChange = async (templateTypeLabel) => {
    try {
      const selectedTemplate = templateTypeLabel;
      await updateDocument("templates", { activeTemplate: selectedTemplate });
    } catch (error) {
      console.error("Error updating user nav type:", error);
    }
  };
  return (
    <div>
      <div className="container">
        {user && user.role==="admin" && (
          <div className="role">
            <div className="section-title">
              <p>Template Setting</p>
              <h2>Select Your Best Template</h2>
            </div>
            <div className="radio-container">
              {Object.keys(templateTypeLabels).map((templateType, index) => (
                <label
                  key={index}
                  className={`radio-container ${
                    activeTemplate === templateType
                      ? "radio_btn_checked"
                      : "service_details_radion_btn"
                  }`}
                  style={{
                    color:
                      activeTemplate === templateType
                        ? "var(--white-color)"
                        : "inherit",
                    backgroundColor:
                      activeTemplate === templateType
                        ? "var(--click-color)"
                        : "inherit",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="nav"
                      value={templateType}
                      onChange={() => {
                        setActiveTemplate(templateType);
                        handleTemplateChange(templateType);
                      }}
                      className="radio-button"
                    />
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "21px" }}
                    >
                      {activeTemplate === templateType ? "done" : "add"}
                    </span>
                    {templateTypeLabels[templateType]}
                  </div>
                </label>
              ))}
            </div>
          </div>
        )}
        <br />
        <p style={{ fontSize: "13px" }}>
          <span style={{ fontWeight: "bold", color: "var(--red-color)" }}>
            Note:
          </span>
          Template Design: After selecting the template button, you can explore
          the design of the selected template on the website.
        </p>
      </div>
    </div>
  );
};

export default TemplateSettings;

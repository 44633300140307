import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useOrders from '../firebase files/hooks/useOrders';
import './OrderDetails.scss';
import { useAuthContext } from '../firebase files/hooks/useAuthContext';
import { useCollection } from '../firebase files/hooks/useCollection';
import { useDocument } from '../firebase files/hooks/useDocument';
import { useNavigate } from 'react-router-dom';

const OrderDetails = () => {
    const { user } = useAuthContext()
    const { id } = useParams();
    const { documents: orders, error: ordersError } = useCollection("orders");
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const { documents: usersDocuments } = useCollection("users");
    const { document: currencyDocument, error: currencyError } = useDocument(
        "settings",
        "currency"
    );

    const navigate = useNavigate();

    const getUserDetails = (userId) => {
        if (!usersDocuments) return { fullName: "N/A", phoneNumber: "N/A" };
        const user = usersDocuments.find(user => user.id === userId);
        return user ? { fullName: user.fullName, phoneNumber: user.phoneNumber } : { fullName: "N/A", phoneNumber: "N/A" };
    };

    useEffect(() => {
        const findOrder = () => {
            const foundOrder = orders.find(order => order.id === id);
            setOrder(foundOrder || null);
            setLoading(false);
        };

        if (orders) {
            findOrder();
        }
    }, [id, orders]);

    // if(!user){
    //     navigate("/login")
    // }

    if (loading || !currencyDocument || !order) {
        return <div className="loading"></div>;
    }

    // if (!order) {
    //     return <div className="error">Order not found</div>;
    // }
    
    return (
        <div className="order-details-page-container">
            <div className="order-details-card">
                <h2>Order Details</h2>
                {order.barcode && (
                    <div className="order-barcode">
                        <img 
                            src={order.barcode.imageUrl} 
                            alt={`Barcode: ${order.barcode.number}`}
                            className="barcode-image"
                        />
                        <span className="barcode-number">{order.barcode.number}</span>
                    </div>
                )}
                <div className="order-info">
                    <div className="info-row">
                        <span className="label">Order ID:</span>
                        <span className="value">{order.id}</span>
                    </div>
                    <div className="info-row">
                        <span className="label">Order Date:</span>
                        <span className="value">
                            {order.createdAt.toDate().toLocaleString("Ind")}
                        </span>
                    </div>
                    <div className="info-row">
                        <span className="label">Total Amount:</span>
                        <span className="value">{currencyDocument.symbol} {order.totalPrice}</span>
                    </div>

                    <div className="customer-info">
                        <h3>Customer Information</h3>
                        {order.adminCreatedOrder ? (
                            <>
                                <div className="info-row">
                                    <span className="label">Assigned To:</span>
                                    <span className="value">
                                        {getUserDetails(order.orderAssignedTo).fullName}
                                    </span>
                                </div>
                                <div className="info-row">
                                    <span className="label">Contact Number:</span>
                                    <span className="value">
                                        {getUserDetails(order.orderAssignedTo).phoneNumber}
                                    </span>
                                </div>
                                <div className="info-row">
                                    <span className="label">Assigned On:</span>
                                    <span className="value">
                                        {order.orderAssignedOn?.toDate().toLocaleString("Ind")}
                                    </span>
                                </div>
                            </>
                        ) : (
                            <div className="info-row">
                                <span className="label">Order Status:</span>
                                <span className="value">{order.orderStatus}</span>
                            </div>
                        )}
                    </div>

                    <div className="products-list">
                        <h3>Ordered Products</h3>
                        {order.products.map((product) => (
                            <div key={product.id} className="product-item">
                                <span className="product-name">{product.name}</span>
                                <span className="product-quantity">x {product.quantity}</span>
                                {/* <span className="product-price">{currencyDocument.symbol} {product.price}</span> */}
                            </div>
                        ))}
                    </div>

                    <div className="shipping-info">
                        <h3>Shipping Address</h3>
                        <p>
                            {order.shippingAddress.name}, <br />
                            {order.shippingAddress.houseNumber}, {order.shippingAddress.streetLineOne}, <br />
                            {order.shippingAddress.streetLineTwo && `${order.shippingAddress.streetLineTwo}, `}<br />
                            {order.shippingAddress.landmark && `${order.shippingAddress.landmark}, `}<br />
                            {order.shippingAddress.city}, {order.shippingAddress.state}, <br />
                            {order.shippingAddress.pincode}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetails;
import React from 'react';
import {Routes,Route} from 'react-router-dom';
import AdminPanelLayout from './adminPanelLayout/AdminPanelLayout';
import AdminHome from './adminPanelPages/adminHome/AdminHome';
import AdminServices from './adminPanelPages/adminServices/AdminServices';
import AdminOrders from './adminPanelPages/adminOrders/AdminOrders';
import AdminDrivers from './adminPanelPages/adminDrivers/AdminDrivers';
import AdminVendors from './adminPanelPages/adminVendors/AdminVendors';
import AdminOrdersDetail from './adminPanelPages/adminOrdersDetail/AdminOrdersDetail';

const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<AdminPanelLayout />}>
                <Route index element={<AdminHome/>}/> 
                <Route path="home" element={<AdminHome />}/>
                <Route path="services" element={<AdminServices />}/>
                <Route path="orders" element={<AdminOrders />}/>
                <Route path="orders/:orderId" element={<AdminOrdersDetail />}/>
                <Route path="drivers" element={<AdminDrivers/>}/>
                <Route path="locations" element={<AdminVendors />}/>
            </Route>
        </Routes>
    )
}

export default AdminRoutes;

import React, { useState, useEffect } from 'react';
import { useAuthContext } from './useAuthContext';
import { projectFirestore, timestamp } from '../../firebase config/config';
import { useFirestore } from './useFirestore';
import { generateBarcodeNumber, generateBarcodeDataURL } from '../../utility/barcodeGenerator'


const useOrders = () => {
    const [orders, setOrders] = useState([]);
    const { user } = useAuthContext();
    const { addDocument, updateDocument, deleteDocument } = useFirestore('orders');

    useEffect(() => {
        if (user) {
            const unsubscribe = projectFirestore
                .collection('orders')
                .where('userId', '==', user.uid)
                .onSnapshot((snapshot) => {
                    const items = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setOrders(items);
                });

            const unsubscribeAssigned = projectFirestore
                .collection('orders')
                .where('orderAssignedTo', '==', user.uid)
                .onSnapshot((snapshot) => {
                    const assignedItems = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setOrders(prevOrders => {
                        const allOrders = [...prevOrders, ...assignedItems];
                        return Array.from(new Map(allOrders.map(order => [order.id, order])).values());
                    });
                });

            return () => {
                unsubscribe();
                unsubscribeAssigned();
            };
        }
    }, [user])

    const createOrder = async (orderProducts, orderPrice, address, paymentMethod) => {

        // const barcodeNumber = generateBarcodeNumber();
        // const barcodeImage = generateBarcodeDataURL(barcodeNumber);

        const orderData = {
            products: orderProducts,
            totalPrice: orderPrice,
            shippingAddress: address,
            paymentMethod: paymentMethod,
            orderStatus: "pending",
            currentOrderStage: "orderPlaced",
            orderStages: {
                orderPlaced: {
                    completed: true,
                    timestamp: timestamp.fromDate(new Date())
                },
                itemsPickedUp: {
                    completed: false,
                    timestamp: null
                },
                processing: {
                    completed: false,
                    timestamp: null
                },
                delivered: {
                    completed: false,
                    timestamp: null
                }
            },



            // Add staff assignment fields
            pickupStaff: null,
            processingStaff: null,
            deliveryStaff: null,

            //START PAYMENT RELATED FIELDS
            transactionid: "",
            transactionstatus: "",
            transactiontimestamp: "",
            transactionamount: "",
            isrefunded: "",
            refundedamount: "",
            refundedtimestamp: "",
            refundstatus: "",
            //END PAYMENT RELATED FIELDS

            createdAt: timestamp.fromDate(new Date()),
            userId: user.uid,
            adminCreatedOrder: user.role === 'admin',
            // Initialize timeline array for all orders, not just admin-created ones
            timeline: [{
                id: `ORDER_CREATED_${Date.now()}`,
                eventType: 'ORDER_CREATED',
                details: {
                    createdByAdmin: user.role === 'admin',
                    orderTotal: orderPrice,
                    productCount: orderProducts.length
                },
                timestamp: timestamp.fromDate(new Date()),
                createdBy: {
                    userId: user.uid,
                    userName: user.fullName || user.email || 'System',
                    role: user.role
                }
            }]
        };

        try {
            const addedDocRef = await addDocument(orderData);
            const orderId = addedDocRef.id;

            // Generate QR code
            const qrCodeImage = await generateBarcodeDataURL(orderId);

            // Update the document with the QR code
            await addedDocRef.update({
                barcode: {
                    number: orderId,
                    imageUrl: qrCodeImage
                }
            });

            const addedDocSnap = await addedDocRef.get();
            return { id: addedDocSnap.id, ...addedDocSnap.data() };
        } catch (error) {
            console.error("Error creating order:", error);
            return null;
        }
    }

    const getOrderInfo = async (id) => {
        const order = orders.find((order) => order.id === id);
        return order;
    }

    const updateOrder = async (id, status) => {
        try {
            await updateDocument(id, { orderStatus: status })
        } catch (err) {
            console.log("Error updating order", err)
        }
    }

    const addTimelineEvent = async (orderId, eventType, details) => {
        try {
            // Get current order
            const orderRef = projectFirestore.collection('orders').doc(orderId);
            const orderDoc = await orderRef.get();
            const currentOrder = orderDoc.data();

            // Create new event
            const newEvent = {
                id: Date.now().toString(), // Simple unique ID
                eventType,
                details,
                timestamp: timestamp.fromDate(new Date()),
                createdBy: {
                    userId: user.uid,
                    userName: user.fullName || user.email || 'System',
                    role: user.role
                }
            };

            // Add event to timeline array (create if doesn't exist)
            const timeline = currentOrder.timeline || [];
            timeline.unshift(newEvent); // Add to beginning of array

            // Update order document
            await updateDocument(orderId, { timeline });

            return newEvent;
        } catch (error) {
            console.error('Error adding timeline event:', error);
            throw error;
        }
    };

    const assignOrder = async (orderId, assignedToUserId) => {
        try {
            await updateDocument(orderId, {
                orderAssignedTo: assignedToUserId,
                orderAssignedOn: timestamp.fromDate(new Date())
            });

            // Add timeline event
            await addTimelineEvent(orderId, 'ORDER_ASSIGNED', {
                assignedToUserId
            });
        } catch (err) {
            console.log("Error assigning order", err);
            throw err;
        }
    };

    return { orders, createOrder, getOrderInfo, updateOrder, assignOrder, addTimelineEvent };

}

export default useOrders;

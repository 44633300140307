import { useState, useEffect } from 'react';
import { useAuthContext } from './useAuthContext';
import { projectFirestore, timestamp } from '../../firebase config/config';
import { useFirestore } from './useFirestore';
import useOrders from './useOrders'; // Add this import

const useStaffAssignments = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [assignedOrders, setAssignedOrders] = useState({
        pickup: [],
        delivery: [],
        history: []
    });
    const { user } = useAuthContext();
    const { updateDocument } = useFirestore('orders');

    const { addTimelineEvent } = useOrders();

    useEffect(() => {
        setIsLoading(true);
        if (user) {
            const unsubscribe = projectFirestore
                .collection('orders')
                .onSnapshot(async (snapshot) => {
                    const allOrders = await Promise.all(snapshot.docs.map(async doc => {
                        const orderData = doc.data();
                        // Fetch assigned user details
                        let assignedUserDetails = null;
                        if (orderData.orderAssignedTo) {
                            const userDoc = await projectFirestore
                                .collection('users')
                                .doc(orderData.orderAssignedTo)
                                .get();
                            if (userDoc.exists) {
                                assignedUserDetails = userDoc.data();
                            }
                        } else {
                            const userDoc = await projectFirestore
                                .collection('users')
                                .doc(orderData.createdBy)
                                .get();
                            if (userDoc.exists) {
                                assignedUserDetails = userDoc.data();
                            }
                        }
                        return {
                            id: doc.id,
                            ...orderData,
                            assignedUserDetails
                        };
                    }));

                    // Filter pickup orders
                    const pickupOrders = allOrders.filter(order =>
                        order.pickupStaff?.staffId === user.uid &&
                        !order.orderStages?.itemsPickedUp?.completed
                    );

                    // Filter delivery orders with more precise conditions
                    const deliveryOrders = allOrders.filter(order =>
                        order.deliveryStaff?.staffId === user.uid
                        &&
                        order.currentOrderStage === 'processing' && // Explicitly check processing stage
                        order.orderStages?.itemsPickedUp?.completed && // Pickup must be complete
                        !order.orderStages?.delivered?.completed // Delivery not yet completed
                    );

                    // History logic for completed tasks
                    const completedOrders = allOrders.reduce((acc, order) => {
                        const completedTasks = [];

                        // Check for completed pickup
                        if (order.pickupStaff?.staffId === user.uid &&
                            order.orderStages?.itemsPickedUp?.completed) {
                            completedTasks.push({
                                ...order,
                                completedTaskType: 'pickup'
                            });
                        }

                        // Check for completed delivery
                        if (order.deliveryStaff?.staffId === user.uid &&
                            order.orderStages?.delivered?.completed) {
                            completedTasks.push({
                                ...order,
                                completedTaskType: 'delivery'
                            });
                        }

                        return [...acc, ...completedTasks];
                    }, []);

                    setAssignedOrders({
                        pickup: pickupOrders || [],
                        delivery: deliveryOrders || [],
                        history: completedOrders || []
                    });
                    setIsLoading(false);
                }, (error) => {
                    console.error("Error fetching orders:", error);
                    setIsLoading(false);
                });

            return () => unsubscribe();
        } else {
            setIsLoading(false);
        }
    }, [user]);

    const markTaskComplete = async (order, taskType) => {
        try {
            if (taskType === 'pickup') {
                await updateDocument(order.id, {
                    currentOrderStage: 'itemsPickedUp',
                    'orderStages.itemsPickedUp': {
                        timestamp: timestamp.fromDate(new Date()),
                        completed: true
                    }
                });

                await addTimelineEvent(order.id, 'PICKUP_COMPLETED', {
                    staffId: user.uid,
                    timestamp: new Date().toISOString(),
                    location: order.shippingAddress
                });

            } else if (taskType === 'delivery') {
                await updateDocument(order.id, {
                    currentOrderStage: 'delivered',
                    orderStatus: 'completed',
                    'orderStages.delivered': {
                        timestamp: timestamp.fromDate(new Date()),
                        completed: true
                    }
                });

                await addTimelineEvent(order.id, 'DELIVERY_COMPLETED', {
                    staffId: user.uid,
                    timestamp: new Date().toISOString(),
                    location: order.shippingAddress,
                    orderTotal: order.totalPrice
                });
            }
        } catch (error) {
            console.error('Error marking task complete:', error);
        }
    };

    return { assignedOrders, isLoading, markTaskComplete };
};

export default useStaffAssignments;
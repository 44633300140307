import QRCode from 'qrcode';

// Get the base URL of your application
const getBaseUrl = () => {
    // For local development
    if (typeof window === 'undefined') return '';
    return window.location.hostname === 'localhost'
        ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
        : window.location.origin;
};

export const generateBarcodeDataURL = async (orderId) => {
    if (!orderId) return null;

    const baseUrl = getBaseUrl();
    const orderUrl = `${baseUrl}/order-details/${orderId}`;

    try {
        // Generate QR code with options
        const qrDataUrl = await QRCode.toDataURL(orderUrl, {
            width: 200,
            margin: 2,
            color: {
                dark: '#000000',  // Black dots
                light: '#ffffff' // White background
            },
            errorCorrectionLevel: 'H' // Highest error correction capability
        });
        return qrDataUrl;
    } catch (error) {
        console.error("Error generating QR code:", error);
        return null;
    }
};

// Keep the original generateBarcodeNumber function as a fallback
export const generateBarcodeNumber = (orderId) => {
    const timestamp = new Date().getTime();
    const randomDigits = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    return `ORD${timestamp}${randomDigits}`;
};
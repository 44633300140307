import React, { useState, useEffect } from "react";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import DarkModeToggle from "react-dark-mode-toggle";
// css
import "./NavSettings.css";

const NavSettings = () => {
  const { user } = useAuthContext();
  const { updateDocument } = useFirestore("settings");
  const { document: navTypeDoc } = useDocument("settings", "navbarType");
  const { document: NavMenuNames } = useDocument("settings", "EditNavbarMenus");
  const { document: roleNames } = useDocument("m_roles", "rolesDoc");
  const { updateDocument: updateMenuDocument } = useFirestore("settings");

  const [activeNav, setActiveNav] = useState("");

  const [popupVisible, setPopupVisible] = useState(false);
  const [subMenuPopupVisible, setSubMenuPopupVisible] = useState(false);
  const [setMobileMenuPopupVisible, mobileMenuPopupVisible] = useState(false);

  const [menuName, setMenuName] = useState("");
  const [menuIcon, setMenuIcon] = useState("");
  const [pageUrl, setPageUrl] = useState("");

  const [subMenuName, setSubMenuName] = useState("");
  const [subMenuIcon, setSubMenuIcon] = useState("");
  const [subMenuPageUrl, setSubMenuPageUrl] = useState("");

  const [editingField, setEditingField] = useState(null);
  const [editingSubMenuField, setEditingSubMenuField] = useState(null);

  const [selectedMenus, setSelectedMenus] = useState([]); // Track selected menu names

  const [tempSelectedRole, setTempSelectedRole] = useState(null);
  const [subMenuFor, setSubMenuFor] = useState("allRoles"); // Default to allRoles

  useEffect(() => {
    if (navTypeDoc) {
      setActiveNav(navTypeDoc.activeNav || "");
    }
  }, [navTypeDoc]);

  const navTypeLabels = {
    LeftNav: "Left Navigation",
    RightNav: "Right Navigation",
    BottomNav: "Bottom Navigation",
    TopNav: "Top Navigation",
    FixNav: "Fixed Navigation",
    secondaryNav: "Secondary Navigation"
  };

  const handleRoleChange = async (navTypeLabel) => {
    try {
      await updateDocument("navbarType", { activeNav: navTypeLabel });
    } catch (error) {
      console.error("Error updating user nav type:", error);
    }
  };

  const handleAddMenu = async () => {
    if (!menuName || !menuIcon || !pageUrl) {
      alert("Please fill out all fields before adding a menu.");
      return;
    }

    const newMenuItem = { iconName: menuIcon, menuName: menuName, pageUrl: pageUrl, forMobileBottom: false, orderForMobileBottom: 0, menuFor: tempSelectedRole, menuStatus: "active", visibilityInLaptopNav: false, visibilityInProfilePage: true, };

    try {
      const updatedMenuDetails = Array.isArray(NavMenuNames?.menuDetails)
        ? [...NavMenuNames.menuDetails, newMenuItem]
        : [newMenuItem];

      await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });
      console.log("Menu added successfully:", newMenuItem);
      setPopupVisible(false);
      resetMenuForm();
    } catch (error) {
      console.error("Error adding menu:", error);
      alert("There was an error adding the menu. Please try again.");
    }
  };

  const handleEditButtonClick = (index) => {
    const menu = NavMenuNames.menuDetails[index];
    setMenuName(menu.menuName);
    setMenuIcon(menu.iconName);
    setPageUrl(menu.pageUrl);
    setEditingField(index);
    setTempSelectedRole(menu.menuFor); // Set current role in temporary state
    setPopupVisible(true);
  };

  const handleEditMenu = (menuIndex, updatedMenu) => {
    // Update the selected menu object with the edited values
    const updatedMenuDetails = [...NavMenuNames.menuDetails];
    updatedMenuDetails[menuIndex] = {
      ...updatedMenuDetails[menuIndex], // Keep existing values
      menuName: updatedMenu.menuName,   // Update name
      iconName: updatedMenu.iconName,   // Update icon
      pageUrl: updatedMenu.pageUrl,     // Update URL
      menuFor: tempSelectedRole,
    };

    // Update the Firestore document
    updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails })
      .then(() => {
        console.log("Menu updated successfully");
        setPopupVisible(false);
        resetMenuForm();
      })
      .catch((error) => {
        console.error("Error updating menu:", error);
        alert("There was an error updating the menu. Please try again.");
      });
  };

  const resetMenuForm = () => {
    setMenuName("");
    setMenuIcon("");
    setPageUrl("");
    setEditingField(null);
  };

  const handleAddNewMenuClick = () => {
    setPopupVisible(true);
    setTempSelectedRole("allRoles"); // Default role for new menu
    resetMenuForm(); // Reset the form fields for a new menu
  };

  const handleDeleteMenu = async (menuIndex) => {
    try {
      // Create a copy of the menu details array
      const updatedMenuDetails = [...NavMenuNames.menuDetails];

      // Remove the menu at the given index
      updatedMenuDetails.splice(menuIndex, 1);

      // Update the Firestore document with the new menu details array
      await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });

      console.log("Menu deleted successfully");
      setPopupVisible(false); // Close the popup after deletion
    } catch (error) {
      console.error("Error deleting menu:", error);
      alert("There was an error deleting the menu. Please try again.");
    }
  };

  // START ADDING NEW SUB MENU INSIDE MAIN MENU
  const handleAddSubMenuClick = () => {
    resetSubMenuForm();
    setSubMenuPopupVisible(true);
  };

  const handleAddSubMenu = async () => {
    if (!subMenuName || !subMenuIcon || !subMenuPageUrl) {
      alert("Please fill out all fields before adding a submenu.");
      return;
    }

    const newSubMenu = {
      subMenuName,
      subMenuIcon,
      subMenuPageUrl,
      subMenuFor,
      visibilityInLaptopNav: true,
      visibilityInProfilePage: true,
      subMenuStatus: "active"
    };

    const updatedMenuDetails = [...NavMenuNames.menuDetails];
    const menuToEdit = updatedMenuDetails[editingField];

    if (!menuToEdit.subMenu) {
      menuToEdit.subMenu = [];
    }

    // Add new submenu to the selected menu's subMenu array
    menuToEdit.subMenu.push(newSubMenu);

    try {
      await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });
      console.log("SubMenu added successfully");
      setSubMenuPopupVisible(false);
      setPopupVisible(false);
      resetSubMenuForm();
    } catch (error) {
      console.error("Error adding submenu:", error);
      alert("There was an error adding the submenu. Please try again.");
    }
  };


  // Handle Edit SubMenu Click
  const handleEditSubMenuClick = (menuIndex, subMenuIndex) => {
    const menu = NavMenuNames.menuDetails[menuIndex];
    const subMenu = menu.subMenu[subMenuIndex];
    setSubMenuFor(subMenu.subMenuFor || "allRoles"); // Default to allRoles if not defined
    setSubMenuName(subMenu.subMenuName);
    setSubMenuIcon(subMenu.subMenuIcon);
    setSubMenuPageUrl(subMenu.subMenuPageUrl);
    setEditingField(menuIndex);
    setEditingSubMenuField(subMenuIndex);
    setSubMenuPopupVisible(true);
  };

  // Edit SubMenu Logic
  const handleEditSubMenu = async () => {
    if (!subMenuName || !subMenuIcon || !subMenuPageUrl) {
      alert("Please fill out all fields before saving the submenu.");
      return;
    }

    const updatedMenuDetails = [...NavMenuNames.menuDetails];
    const menuToEdit = updatedMenuDetails[editingField];

    if (editingSubMenuField !== null && menuToEdit.subMenu) {
      menuToEdit.subMenu[editingSubMenuField] = {
        subMenuName,
        subMenuIcon,
        subMenuPageUrl,
        subMenuFor,
      };
    }

    try {
      await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });
      console.log("SubMenu updated successfully");
      setSubMenuPopupVisible(false);
      resetSubMenuForm();
    } catch (error) {
      console.error("Error updating submenu:", error);
      alert("There was an error updating the submenu. Please try again.");
    }
  };

  // Handle Delete SubMenu
  const handleDeleteSubMenu = async (menuIndex, subMenuIndex) => {
    try {
      // Create a copy of the menu details array
      const updatedMenuDetails = [...NavMenuNames.menuDetails];

      // Locate the specific menu
      const menuToEdit = updatedMenuDetails[menuIndex];

      if (menuToEdit && menuToEdit.subMenu) {
        // Remove the submenu at the given index
        menuToEdit.subMenu.splice(subMenuIndex, 1);

        // Update Firestore with the new menu details
        await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });

        console.log("SubMenu deleted successfully");
        setSubMenuPopupVisible(false); // Close the popup after deletion
      }
    } catch (error) {
      console.error("Error deleting submenu:", error);
      alert("There was an error deleting the submenu. Please try again.");
    }
  };

  // Reset SubMenu Form
  const resetSubMenuForm = () => {
    setSubMenuName("");
    setSubMenuIcon("");
    setSubMenuPageUrl("");
    setEditingSubMenuField(null);
    setSubMenuFor("allRoles"); // Reset to default
  };
  // END ADDING NEW SUB MENU INSIDE MAIN MENU


  {/*START EDIT MOBILE BOTTOM MENUS */ }
  useEffect(() => {
    if (NavMenuNames && NavMenuNames.menuDetails) {
      const preselected = NavMenuNames.menuDetails
        .filter((menu) => menu.forMobileBottom)
        .map((menu) => menu.menuName);
      setSelectedMenus(preselected);
    }
  }, [NavMenuNames]);


  const handleMenuSelect = (menuName, index) => {
    const updatedSelection = [...selectedMenus];
    updatedSelection[index] = menuName;
    setSelectedMenus(updatedSelection);
  };

  const handleConfirmSelection = async () => {
    const updatedMenuDetails = NavMenuNames.menuDetails.map((menu) => {
      const selectedIndex = selectedMenus.indexOf(menu.menuName);

      return {
        ...menu,
        forMobileBottom: selectedIndex !== -1, // true if the menu is selected
        orderForMobileBottom: selectedIndex !== -1 ? selectedIndex + 1 : 0, // Set order or reset to 0
      };
    });

    const trueCount = updatedMenuDetails.filter((menu) => menu.forMobileBottom).length;

    if (trueCount > 4) {
      alert("You can only select up to 4 menus for the mobile bottom navigation.");
      return;
    }

    try {
      await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });
      console.log("Menus updated successfully");
      mobileMenuPopupVisible(false); // Close the popup
    } catch (error) {
      console.error("Error updating menus:", error);
      alert("There was an error updating the menus. Please try again.");
    }
  };

  {/*END EDIT MOBILE BOTTOM MENUS */ }

  const handleRoleSelect = (role) => {
    setTempSelectedRole(role); // Update temporary role state
  };

  // Add this function to toggle visibilityInLaptopNav
  const handleMenuLaptopVisibilityToggle = async (menuIndex) => {
    const updatedMenuDetails = [...NavMenuNames.menuDetails];
    const currentVisibility = updatedMenuDetails[menuIndex]?.visibilityInLaptopNav || false;

    // Toggle the visibility
    updatedMenuDetails[menuIndex].visibilityInLaptopNav = !currentVisibility;

    try {
      // Update Firestore document
      await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });
      console.log("Visibility updated successfully");
    } catch (error) {
      console.error("Error updating visibility:", error);
      alert("There was an error updating the visibility. Please try again.");
    }
  };

  // Add this function to toggle visibilityInLaptopNav
  const menuStatusToggle = async (menuIndex) => {
    const updatedMenuDetails = [...NavMenuNames.menuDetails];

    // Get current status and toggle it
    const currentStatus = updatedMenuDetails[menuIndex]?.menuStatus;

    // Toggle between "active" and "inactive"
    const newStatus = currentStatus === "active" ? "inactive" : "active";

    updatedMenuDetails[menuIndex].menuStatus = newStatus;

    try {
      // Update Firestore document with the new status
      await updateMenuDocument("EditNavbarMenus", {
        menuDetails: updatedMenuDetails,
      });

      console.log(`Menu status updated successfully: ${newStatus}`);
    } catch (error) {
      console.error("Error updating menu status:", error);
      alert("There was an error updating the menu status. Please try again.");
    }
  };

  const handleSubMenuStatusToggle = async (mainMenuIndex, subMenuIndex) => {
    // Clone the menuDetails array to ensure immutability
    const updatedMenuDetails = [...NavMenuNames.menuDetails];

    // Check if subMenu exists and update its status
    const subMenuToUpdate = updatedMenuDetails[mainMenuIndex]?.subMenu?.[subMenuIndex];

    if (subMenuToUpdate) {
      // Toggle the subMenuStatus for the selected subMenu
      subMenuToUpdate.subMenuStatus = subMenuToUpdate.subMenuStatus === 'active' ? 'inactive' : 'active';

      try {
        // Update Firestore with the new menuDetails array
        await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });
        console.log("SubMenu Status updated successfully");
      } catch (error) {
        console.error("Error updating SubMenu status:", error);
        alert("There was an error updating the submenu status. Please try again.");
      }
    } else {
      console.error("SubMenu not found");
    }
  };
  const handleSubMenuLaptopVisibilityToggle = async (mainMenuIndex, subMenuIndex) => {
    // Clone the menuDetails array to ensure immutability
    const updatedMenuDetails = [...NavMenuNames.menuDetails];

    // Check if subMenu exists and update its visibility
    const subMenuToUpdate = updatedMenuDetails[mainMenuIndex]?.subMenu?.[subMenuIndex];

    if (subMenuToUpdate) {
      // Toggle the visibilityInLaptopNav for the selected subMenu
      subMenuToUpdate.visibilityInLaptopNav = !subMenuToUpdate.visibilityInLaptopNav;

      try {
        // Update Firestore with the new menuDetails array
        await updateMenuDocument("EditNavbarMenus", { menuDetails: updatedMenuDetails });
        console.log("SubMenu Laptop Visibility updated successfully");
      } catch (error) {
        console.error("Error updating SubMenu laptop visibility:", error);
        alert("There was an error updating the submenu visibility. Please try again.");
      }
    } else {
      console.error("SubMenu not found");
    }
  };

  return (
    <div className="container nav-setting-container nav-setting-role">
      {/* Popup add & edit menus */}
      {popupVisible && (
        <div className="nav-setting-menu-popup-overlay">
          <div className="nav-setting-menu-popup-container">
            <div className="nav-setting-menu-popup-header">
              <div>
                <h6>{editingField !== null ? "Edit Menu" : "Add New Menu"}</h6>
              </div>
              {/* Main Menu Status Toggle */}
              {editingField !== null && (
                <div className="d-flex align-items-center justify-content-between">
                  Inactive &nbsp;
                  <DarkModeToggle
                    onChange={() => menuStatusToggle(editingField)} // Call the toggle function
                    checked={NavMenuNames?.menuDetails?.[editingField]?.menuStatus === "active"}
                    size={30}
                  />
                  &nbsp;Active
                </div>
              )}
              <span
                className="material-symbols-outlined nav-setting-menu-popup-close"
                onClick={() => setPopupVisible(false)}
              >
                close
              </span>
            </div>

            <div>
              {roleNames?.roles?.map((role, roleIndex) => (
                <label
                  key={roleIndex}
                  className={`radio-container ${tempSelectedRole === role ? "radio_btn_checked" : "service_details_radion_btn"}`}
                  style={{ padding: "0px 10px" }}
                >
                  <input
                    type="radio"
                    name={`menu-role-${editingField !== null ? editingField : "new"}`}
                    value={role}
                    checked={tempSelectedRole === role}
                    onChange={() => handleRoleSelect(role)}
                    className="radio-button"
                  />
                  {role}
                </label>
              ))}
            </div>

            <div className="nav-setting-menu-popup-body">
              <div className="nav-setting-menu-popup-field">
                <label>Menu Name:</label>
                <input
                  type="text"
                  value={menuName}
                  onChange={(e) => setMenuName(e.target.value)}
                  className="nav-setting-menu-popup-input"
                  required
                  placeholder="Menu name"
                />
              </div>
              <div className="nav-setting-menu-popup-field">
                <label>Menu Icon SVG:</label>
                <input
                  type="text"
                  value={menuIcon}
                  onChange={(e) => setMenuIcon(e.target.value)}
                  className="nav-setting-menu-popup-input"
                  required
                  placeholder="Accept only google icon SVG"
                />
                <div className="d-flex justify-content-end">
                  <a style={{ color: "blue", fontSize: "13px" }} href="https://fonts.google.com/icons" target="blank_">Google Icons</a>
                </div>
              </div>
              <div className="nav-setting-menu-popup-field">
                <label>Page URL:</label>
                <input
                  type="text"
                  value={pageUrl}
                  onChange={(e) => setPageUrl(e.target.value)}
                  className="nav-setting-menu-popup-input"
                  required
                  placeholder="Add Slash Mark(/) before every page url"
                />
              </div>
              <div className="d-flex align-items-center">
                {editingField !== null && (
                  <>
                    <p>Hide for laptop Nav :&nbsp;</p>
                    <div className="d-flex align-items-center justify-content-between">
                      <DarkModeToggle
                        onChange={() => handleMenuLaptopVisibilityToggle(editingField)}
                        checked={NavMenuNames?.menuDetails?.[editingField]?.visibilityInLaptopNav || false}
                        size={30}
                      />
                    </div></>
                )}
              </div>
            </div>
            <div className="nav-setting-menu-popup-footer">
              {editingField !== null && (
                <span
                  className="material-symbols-outlined"
                  onClick={() => handleDeleteMenu(editingField)}
                  style={{ cursor: "pointer", color: "var(--click-color)" }}
                >
                  delete
                </span>
              )}
              {editingField !== null && (
                <button onClick={handleAddSubMenuClick} className="btn_border">Add Sub Menu</button>
              )}

              <button
                className="btn_fill"
                onClick={editingField !== null ? () => handleEditMenu(editingField, { menuName, iconName: menuIcon, pageUrl }) : handleAddMenu}
              >
                {editingField !== null ? "Save" : "Add"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Popup for add & edit submenus */}
      {subMenuPopupVisible && (
        <div className="nav-setting-menu-popup-overlay">
          <div className="nav-setting-menu-popup-container">
            <div className="nav-setting-menu-popup-header">
              <h6>{editingSubMenuField !== null ? "Edit Sub Menu" : "Add New Sub Menu"}</h6>
              {/* Sub-Main Menu Status Toggle */}
              {editingSubMenuField !== null && (
                <div className="d-flex align-items-center justify-content-between">
                  <p>Inactive</p>&nbsp;
                  <DarkModeToggle
                    onChange={() => handleSubMenuStatusToggle(editingField, editingSubMenuField)} // Update only the selected submenu's status
                    checked={NavMenuNames?.menuDetails?.[editingField]?.subMenu?.[editingSubMenuField]?.subMenuStatus === "active"}
                    size={30}
                  />
                  &nbsp; <p>Active</p>
                </div>
              )}
              <span
                className="material-symbols-outlined nav-setting-menu-popup-close"
                onClick={() => setSubMenuPopupVisible(false)}
              >
                close
              </span>
            </div>
            <div>
              {roleNames?.roles?.map((role, roleIndex) => (
                <label
                  key={roleIndex}
                  className={`radio-container ${subMenuFor === role ? "radio_btn_checked" : "service_details_radion_btn"}`}
                  style={{ padding: "0px 10px" }}
                >
                  <input
                    type="radio"
                    name="submenu-role"
                    value={role}
                    checked={subMenuFor === role}
                    onChange={() => setSubMenuFor(role)}
                    className="radio-button"
                  />
                  {role}
                </label>
              ))}
            </div>

            <div className="nav-setting-menu-popup-body">
              <div className="nav-setting-menu-popup-field">
                <label>Sub Menu Name:</label>
                <input
                  type="text"
                  value={subMenuName}
                  onChange={(e) => setSubMenuName(e.target.value)}
                  className="nav-setting-menu-popup-input"
                  required
                  placeholder="Sub Menu name"
                />
              </div>
              <div className="nav-setting-menu-popup-field">
                <label>Sub Menu Icon SVG:</label>
                <input
                  type="text"
                  value={subMenuIcon}
                  onChange={(e) => setSubMenuIcon(e.target.value)}
                  className="nav-setting-menu-popup-input"
                  required
                  placeholder="Accept only google icon SVG"
                />
                <div className="d-flex justify-content-end">
                  <a style={{ color: "blue", fontSize: "13px" }} href="https://fonts.google.com/icons" target="_blank" rel="noopener noreferrer">
                    Google Icons
                  </a>
                </div>
              </div>
              <div className="nav-setting-menu-popup-field">
                <label>Sub Menu Page URL:</label>
                <input
                  type="text"
                  value={subMenuPageUrl}
                  onChange={(e) => setSubMenuPageUrl(e.target.value)}
                  className="nav-setting-menu-popup-input"
                  required
                  placeholder="Add Slash Mark(/) before every page URL"
                />
              </div>
              <div className="d-flex">
                {editingSubMenuField !== null && (
                  <>
                    <p>Hide for laptop Navbar :&nbsp;</p>
                    <div className="d-flex align-items-center justify-content-between">
                      <DarkModeToggle
                        onChange={() => handleSubMenuLaptopVisibilityToggle(editingField, editingSubMenuField)} // Pass the correct indices
                        checked={NavMenuNames?.menuDetails?.[editingField]?.subMenu[editingSubMenuField]?.visibilityInLaptopNav || false}
                        size={30}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="nav-setting-menu-popup-footer">
              {editingSubMenuField !== null && (
                <span
                  className="material-symbols-outlined"
                  onClick={() => handleDeleteSubMenu(editingField, editingSubMenuField)} // Pass the correct indices
                  style={{ cursor: "pointer", color: "var(--click-color)" }}
                >
                  delete
                </span>
              )}
              <button
                className="btn_fill"
                onClick={editingSubMenuField !== null ? handleEditSubMenu : handleAddSubMenu}
              >
                {editingSubMenuField !== null ? "Save" : "Add"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* POPUP FOR EDIT MOBILE BOTTOM MENUS */}
      {setMobileMenuPopupVisible && (
        <div className="nav-setting-menu-popup-overlay">
          <div className="nav-setting-menu-popup-container">
            <div className="nav-setting-menu-popup-header">
              <div>
                <h5>Edit Mobile Bottom Menus</h5>
              </div>
              <span
                className="material-symbols-outlined nav-setting-menu-popup-close"
                onClick={() => mobileMenuPopupVisible(false)}
              >
                close
              </span>
            </div>
            <div className="nav-setting-menu-popup-body">
              {[...Array(4)].map((_, index) => (
                <div className="nav-setting-menu-popup-field" key={index}>
                  <label>{`Menu ${index + 1}:`}</label>
                  <select
                    className="nav-setting-menu-popup-input"
                    value={selectedMenus[index] || ""}
                    onChange={(e) => handleMenuSelect(e.target.value, index)}
                  >
                    <option value="">Select Menu</option>
                    {NavMenuNames?.menuDetails?.map((menu, i) => (
                      <option key={i} value={menu.menuName}>
                        {menu.menuName}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
            <div className="nav-setting-menu-popup-footer">
              <button
                className="btn_fill"
                onClick={handleConfirmSelection}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="menu-and-submenu-table-container">
        {/* CHANGE NAVBAR STYLE */}
        <div className="section-title">
          <p>Navbar Setting</p>
          <h2>Select Your Nav Style</h2>
        </div>
        <br />
        <div className="nav-setting-radio-container">
          {Object.keys(navTypeLabels).map((navType, index) => (
            <label
              key={index}
              className={`nav-setting-radio-container ${activeNav === navType
                ? "radio_btn_checked"
                : "service_details_radion_btn"
                }`}
              style={{
                color:
                  activeNav === navType ? "var(--white-color)" : "inherit",
                backgroundColor:
                  activeNav === navType ? "var(--click-color)" : "inherit",
              }}
            >
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  name="nav"
                  value={navType}
                  onChange={() => {
                    setActiveNav(navType);
                    handleRoleChange(navType);
                  }}
                  className="radio-button"
                />
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "21px" }}
                >
                  {activeNav === navType ? "done" : "add"}
                </span>
                {navTypeLabels[navType]}
              </div>
            </label>
          ))}
        </div>
      </div>

      {/* START TABLE FOR MENU AND SUB MENU */}
      <div className="menu-and-submenu-table-container">
        <div className="section-title d-flex justify-content-between align-items-center">
          <h2 style={{ paddingBottom: "0px" }}>Menu and Sub-menus</h2>
          <button className="btn_fill" onClick={handleAddNewMenuClick}>Add new menu</button>
        </div>
        <br />
        <table className="menu-and-submenu-table">
          <thead>
            <tr>
              <th>Main Menu</th>
              <th>Sub Menus</th>
            </tr>
          </thead>
          <tbody>
            {NavMenuNames?.menuDetails?.map((menu, index) => (
              <tr key={index}>
                <td className="d-flex align-items-center">
                  {/* <span
                    className="material-symbols-outlined"
                    onClick={() => handleEditButtonClick(index)}
                    style={{ fontSize: "21px" }}
                  >
                    {menu.iconName}
                  </span> */}
                  <span
                    dangerouslySetInnerHTML={{ __html: menu.iconName }}
                  />
                  &nbsp;
                  {menu.menuName}
                  &nbsp;
                  <span
                    className="material-symbols-outlined"
                    onClick={() => handleEditButtonClick(index)}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  >
                    edit
                  </span>
                </td>
                <td>
                  {menu.subMenu && menu.subMenu.length > 0 ? (
                    <div className="nav-setting-submenu-row">
                      {menu.subMenu?.map((subMenu, subMenuIndex) => (
                        <div key={subMenuIndex} className="nav-setting-submenu-cell">
                          {/* <span
                            className="material-symbols-outlined"
                            style={{ fontSize: "21px" }}
                          >
                            {subMenu.subMenuIcon}
                          </span> */}
                          <span
                            dangerouslySetInnerHTML={{ __html: subMenu.subMenuIcon }}
                          />
                          &nbsp;
                          {subMenu.subMenuName}
                          &nbsp;
                          <span
                            className="material-symbols-outlined"
                            onClick={() => handleEditSubMenuClick(index, subMenuIndex)}
                            style={{ fontSize: "18px", cursor: "pointer" }}
                          >
                            edit
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="nav-setting-submenu-row">
                      <div className="nav-setting-submenu-cell">No Sub Menu</div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* END TABLE FOR MENU AND SUB MENU */}


      {/*START EDIT MOBILE BOTTOM MENUS */}
      <div className="menu-and-submenu-table-container">
        <div className="section-title">
          <h2>Change Layout Of Mobile Bottom Menus </h2>
        </div>
        <button className="btn_fill" onClick={(e) => mobileMenuPopupVisible(true)}>
          Edit Mobile Bottom Menus
        </button>
      </div>
      {/*END EDIT MOBILE BOTTOM MENUS */}
    </div>
  );
};

export default NavSettings;

import React from 'react';
import './AppStoreOnePrivacy.scss';

const AppStoreOnePrivacy = () => {
    return (
        <div className="app-store-one-privacy-parent row ">
            <div className="app-store-one-privacy-left col-12 col-md-6">
                {/* Tilted Bars */}
                <div className="app-store-one-privacy-bars">
                    <img src='assets/img/static-bars.png' />
                </div>
            </div>

            <div className="app-store-one-privacy-right col-12 col-md-6">
                <h1 className="app-store-one-privacy-header app-store-one-heading"><span>// A Privacy</span>-First Platform.</h1>
                <p className="app-store-one-privacy-description app-store-one-sub-heading">
                    Agree is redefining privacy standards in digital acceptance, ensuring your transactions and agreements remain safeguarded at all times.
                </p>
                <ul className="app-store-one-privacy-features">
                    <li className="app-store-one-privacy-feature">
                        <svg
                            style={{
                                backgroundColor: "#0bd1dc",
                                borderRadius: "50%",
                                width: "37px",
                                height: "22px",
                                padding: "2px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="white"
                        >
                            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                        </svg>
                        &nbsp;&nbsp;
                        <div>
                            <strong>Productivity Increase</strong> <br />
                          <span className='app-store-one-sub-heading'>Automate repetitive tasks and focus on what truly matters. Boost your team's output effortlessly.</span>
                        </div>
                    </li>
                    <li className="app-store-one-privacy-feature">
                        <svg
                            style={{
                                backgroundColor: "rgb(234 170 28)",
                                borderRadius: "50%",
                                width: "37px",
                                height: "22px",
                                padding: "2px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="white"
                        >
                            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                        </svg>
                        &nbsp;&nbsp;
                        <div>
                            <strong>Time Saving</strong> <br />
                            <span className='app-store-one-sub-heading'>Simplify workflows and reduce delays. Save hours every day with seamless, efficient processes.</span>
                        </div>
                    </li>
                    <li className="app-store-one-privacy-feature">
                        <svg
                            style={{
                                backgroundColor: "rgb(174 109 239)",
                                borderRadius: "50%",
                                width: "37px",
                                height: "22px",
                                padding: "2px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="white"
                        >
                            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                        </svg>
                        &nbsp;&nbsp;
                        <div>
                            <strong>Cost Save</strong> <br />
                            <span className='app-store-one-sub-heading'>Optimize resource usage and minimize waste. Experience significant savings without compromising quality.</span>
                        </div>
                    </li>
                </ul>
                <div className="app-store-one-privacy-buttons">
                    <button className="app-store-one-privacy-btn btn_fill">Get a demo</button>
                    <button className="btn_border">Get started free</button>
                </div>
            </div>
        </div>
    );
};

export default AppStoreOnePrivacy;

import React from 'react';
import './AdminOrders.scss';
import { useNavigate } from 'react-router-dom';
import { useCollection } from '../../../../firebase files/hooks/useCollection';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const AdminOrders = () => {
    const { documents: users, error: usersError } = useCollection('users');
    const { documents: orders, error: ordersError } = useCollection('orders');

    const navigate = useNavigate();

    // Define columns for the table
    const columns = [
        { field: 'srNo', headerName: 'Sr No', width: 100 },
        { field: 'itemCount', headerName: 'Items Count', width: 100 },
        { field: 'totalAmount', headerName: 'Total Amount', width: 130 },
        {field:'currentOrderStage',headerName:'Order Stage',width:130},
        {field:'orderStatus',headerName:'Order Status',width:130},
        { field: 'orderDate', headerName: 'Order Date & Time', width: 150 },
        { field: 'orderedCreatedBy', headerName: 'Ordered By', width: 200 },
        {
            field: 'actions',
            headerName: 'See Details',
            width: 70,
            renderCell: (params) => (
                <IconButton onClick={() => handleViewDetails(params.row.id)}>
                    <VisibilityIcon />
                </IconButton>
            ),
        },
    ];

    // Transform orders data for the table
    const rows = orders?.map((order, index) => {
        // Convert Firebase timestamp to Date
        const orderDate = order.createdAt?.toDate();
        // Format date to Indian format
        const formattedDate = orderDate?.toLocaleString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        // find user details
        const findUserName = (userId) => {
            const userDetails = users?.find((user) => {
                return user.id === userId;
            })
            return `${capitalizeFirstLetter(userDetails?.fullName)}`
        }

        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        
        const orderCreatedBy = order.adminCreatedOrder ? "Vendor":findUserName(order.createdBy)

        return {
            id: order.id,
            srNo: index + 1,
            itemCount: order.products.length,
            totalAmount: `₹${order.totalPrice}`,
            currentOrderStage:capitalizeFirstLetter(order.currentOrderStage),
            orderStatus:capitalizeFirstLetter(order.orderStatus),
            orderDate: formattedDate,
            orderedCreatedBy: orderCreatedBy,
        };
    }) || [];

    const handleViewDetails = (orderId) => {
        navigate(`/admin-panel/orders/${orderId}`);
    };

    return (
        <div className="admin-orders">
            <h1>Admin Orders Page</h1>
            <div className="admin-orders-table">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                />
            </div>
        </div>
    );
};

export default AdminOrders;
import React from 'react';
import './AdminPanelLayout.scss';
import AdminPanelSideBar from '../adminPanelSideBar/AdminPanelSideBar';
import { Outlet } from 'react-router-dom';

const AdminPanelLayout = () => {
    return (
        <div className="admin-panel-layout">
            <AdminPanelSideBar />
            <div className="admin-panel-layout-content">
                <Outlet/>
            </div>
        </div>
    )
}

export default AdminPanelLayout;
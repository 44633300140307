import React from 'react';
import { Link } from 'react-router-dom';
import "./AppStoreOneMoreMenus.scss";

// FIREBASE 
import { useDocument } from '../../../../firebase files/hooks/useDocument';

const AppStoreOneMoreMenus = () => {
    const { document: socialMediaDoc } = useDocument("settings", "socialmedia");

    // Declare socialMediaFields before using it in JSX
    let socialMediaFields = null;

    // Ensure socialMediaDoc exists and the socialmedia field is an object
    if (socialMediaDoc && socialMediaDoc.socialmedia) {
        // Destructure the socialmedia object to map over its fields
        socialMediaFields = socialMediaDoc.socialmedia;
    }
  // Define paths and names
    const iconPaths = {
        facebook: {
            path: "/assets/img/icons/footer_facebook.png",
            url: socialMediaFields?.facebook || "#",
            iconName: "Facebook"
        },
        whatsapp: {
            path: "/assets/img/icons/footer_whatsapp.png",
            url: socialMediaFields?.whatsapp || "#",
            iconName: "Whatsapp"
        },
        instagram: {
            path: "/assets/img/icons/footer_instagram.png",
            url: socialMediaFields?.instagram || "#",
            iconName: "Instagram"
        },
        youtube: {
            path: "/assets/img/icons/footer_youtube.png",
            url: socialMediaFields?.youtube || "#",
            iconName: "Youtube"
        },
        linkedin: {
            path: "/assets/img/icons/footer_linkedin.png",
            url: socialMediaFields?.linkedin || "#",
            iconName: "LinkedIn"
        }
    };
    // Print all fields of iconPaths in the console
    console.log(iconPaths);

    return (
        <div className="container">
        <div className="app-store-one-more-menus-parent row">
            <div className='d-flex justify-content-center align-items-center col-12 col-md-6'>
                <div className="app-store-one-more-menus-info">
                    <h2 className='app-store-one-heading'>// Want to go?</h2>
                    <h3>Click and connect with us & Apps!</h3>
                    <ul>
                        <li><strong>35+</strong> <br /><p>Data Sources</p></li>
                        <li><strong>200+</strong> <br /><p>Business Profiles</p></li>
                        <li><strong>50+</strong> <br /><p>Companies</p></li>
                    </ul>
                    <br />
                    <button className="btn_fill">Start Now</button>
                </div>
            </div>

            <div className='d-flex justify-content-center col-12 col-md-6'>
                <div className="app-store-one-more-menus-circle">
                    {/* Outer circle */}
                    <div>
                        <Link to="/products">
                            <div className="app-store-one-more-menus-item app-store-one-more-menus-item-1">
                                <div className="icon-container">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="28px"
                                        viewBox="0 -960 960 960"
                                        width="28px"
                                        fill="var(--white-color)"
                                    >
                                        <path d="M200-80q-33 0-56.5-23.5T120-160v-480q0-33 23.5-56.5T200-720h80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720h80q33 0 56.5 23.5T840-640v480q0 33-23.5 56.5T760-80H200Zm0-80h560v-480H200v480Zm280-240q83 0 141.5-58.5T680-600h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85h-80q0 83 58.5 141.5T480-400ZM360-720h240q0-50-35-85t-85-35q-50 0-85 35t-35 85ZM200-160v-480 480Z" />
                                    </svg>
                                </div>
                                <p>Apps</p>
                            </div>
                        </Link>
                        <Link to="/about">
                            <div className="app-store-one-more-menus-item app-store-one-more-menus-item-2">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 -960 960 960" width="28px" fill="var(--white-color)"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                </div>
                                <p>About</p>
                            </div>
                        </Link>
                        <Link to="/contact">
                            <div className="app-store-one-more-menus-item app-store-one-more-menus-item-3">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 -960 960 960" width="28px" fill="var(--white-color)"><path d="M760-480q0-117-81.5-198.5T480-760v-80q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480h-80Zm-160 0q0-50-35-85t-85-35v-80q83 0 141.5 58.5T680-480h-80Zm198 360q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" /></svg>
                                </div>
                                <p>Contact</p>
                            </div>
                        </Link>
                        <Link to="/faq">
                            <div className="app-store-one-more-menus-item app-store-one-more-menus-item-4">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 -960 960 960" width="28px" fill="var(--white-color)"><path d="M240-80v-172q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l52 205q5 19-7 34.5T840-360h-80v120q0 33-23.5 56.5T680-160h-80v80h-80v-160h160v-200h108l-38-155q-23-91-98-148t-172-57q-116 0-198 81t-82 197q0 60 24.5 114t69.5 96l26 24v208h-80Zm254-360Zm-14 120q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Zm-30-128h61q0-25 6.5-40.5T544-526q18-20 35-40.5t17-53.5q0-42-32.5-71T483-720q-40 0-72.5 23T365-637l55 23q7-22 24.5-35.5T483-663q22 0 36.5 12t14.5 31q0 21-12.5 37.5T492-549q-20 21-31 42t-11 59Z" /></svg>
                                </div>
                                <p>Faq</p>
                            </div>
                        </Link>
                        <Link to="/privacy-policy">
                            <div className="app-store-one-more-menus-item app-store-one-more-menus-item-5">
                                <div className="icon-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 -960 960 960" width="28px" fill="var(--white-color)"><path d="m438-338 226-226-57-57-169 169-84-84-57 57 141 141Zm42 258q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-84q104-33 172-132t68-220v-189l-240-90-240 90v189q0 121 68 220t172 132Zm0-316Z" /></svg>
                                </div>
                                <p>Privacy</p>
                            </div>
                        </Link>
                    </div>

                    {/* Inner Circle */}
                    <div className="app-store-one-more-menus-inner-circle">
                        {/* Inner Circle Menu Items */}
                        <div>
                        {socialMediaFields?.facebook && (
                            <Link to={iconPaths.facebook.url} target='_blank'>
                                <div className="app-store-one-more-menus-item app-store-one-more-menus-item-1">
                                    <div className="icon-container">
                                        <img src={iconPaths.facebook.path} alt={iconPaths.facebook.iconName} />
                                    </div>
                                    <p>{iconPaths.facebook.iconName}</p>
                                </div>
                            </Link>
                        )}
                           {socialMediaFields?.whatsapp && (
                            <Link to={iconPaths.whatsapp.url} target='_blank'>
                                <div className="app-store-one-more-menus-item app-store-one-more-menus-item-2">
                                    <div className="icon-container">
                                        <img src={iconPaths.whatsapp.path} alt={iconPaths.whatsapp.iconName} />
                                    </div>
                                    <p>{iconPaths.whatsapp.iconName}</p>
                                </div>
                            </Link>
                        )}
                        {socialMediaFields?.instagram && (
                            <Link to={iconPaths.instagram.url} target='_blank'>
                                <div className="app-store-one-more-menus-item app-store-one-more-menus-item-3">
                                    <div className="icon-container">
                                        <img src={iconPaths.instagram.path} alt={iconPaths.instagram.iconName} />
                                    </div>
                                    <p>{iconPaths.instagram.iconName}</p>
                                </div>
                            </Link>
                        )}
                        {socialMediaFields?.youtube && (
                            <Link to={iconPaths.youtube.url} target='_blank'>
                                <div className="app-store-one-more-menus-item app-store-one-more-menus-item-4">
                                    <div className="icon-container">
                                        <img src={iconPaths.youtube.path} alt={iconPaths.youtube.iconName} />
                                    </div>
                                    <p>{iconPaths.youtube.iconName}</p>
                                </div>
                            </Link>
                        )}
                        {socialMediaFields?.linkedin && (
                            <Link to={iconPaths.linkedin.url} target='_blank'>
                                <div className="app-store-one-more-menus-item app-store-one-more-menus-item-5">
                                    <div className="icon-container">
                                        <img src={iconPaths.linkedin.path} alt={iconPaths.linkedin.iconName} />
                                    </div>
                                    <p>{iconPaths.linkedin.iconName}</p>
                                </div>
                            </Link>
                        )}
                        </div>
                        {/* Center Icon */}
                        <div className='app-store-one-more-menus-center-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 -960 960 960" width="50px">
                                <path d="M80-480v-80h120v80H80Zm136 222-56-58 84-84 58 56-86 86Zm28-382-84-84 56-58 86 86-58 56Zm476 480L530-350l-50 150-120-400 400 120-148 52 188 188-80 80ZM400-720v-120h80v120h-80Zm236 80-58-56 86-86 56 56-84 86Z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
};

export default AppStoreOneMoreMenus;

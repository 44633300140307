import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./MyGridLayout.scss";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import RichTextEditor from "react-rte";
import { useFirestore } from "../../firebase files/hooks/useFirestore";
import { useDocument } from "../../firebase files/hooks/useDocument";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext";
import { projectStorage } from "../../firebase config/config";
const ResponsiveGridLayout = WidthProvider(Responsive);

const MyGridLayout = () => {
    const { user } = useAuthContext();
    const { updateDocument } = useFirestore("pages-data");
    const { document } = useDocument("pages-data", "new-grid-page");
    const fileInputRef = useRef();

    const [items, setItems] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    const [suggestionBox, setSuggestionBox] = useState(null); // To store the position of the suggestion box
    const [isDraggable, setIsDraggable] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState(""); // Add preview URL state


    // Sync items with Firestore
    useEffect(() => {
        if (document && document.items) setItems(document.items);
    }, [document]);

    // Handle undo and redo
    const handleUndo = () => {
        if (undoStack.length > 0) {
            const prevState = undoStack.pop();
            setRedoStack([...redoStack, items]);
            setItems(prevState);
        }
    };

    const handleRedo = () => {
        if (redoStack.length > 0) {
            const nextState = redoStack.pop();
            setUndoStack([...undoStack, items]);
            setItems(nextState);
        }
    };

    // Add new item
    const addItem = (type) => {
        const newId = `${type}-${Date.now()}`;
        const newItem = {
            id: newId,
            type,
            content: type === "text" ? "Click to edit text" : "",
            x: 0,
            y: Infinity,
            w: 3,
            h: 3,
            ...(type === "image" && { imageUrl: "" }), // Add imageUrl for images
            ...(type === "video" && { videoUrl: "" }), // Add videoUrl for videos
            ...(type === "button" && {
                pageUrl: "",
                buttonName: "",
                newTab: false // Add newTab boolean field to the button item
            }),
        };

        setUndoStack([...undoStack, items]);
        setRedoStack([]);
        setItems([...items, newItem]);
    };

    // Render left sidebar with a list of components
    const renderSidebar = () => {
        if (user && user.role === "admin") {
            return (
                <div className="sidebar">
                    <h4>Components</h4>
                    <button onClick={() => addItem("text")}>Text</button>
                    <button onClick={() => addItem("image")}>Image</button>
                    <button onClick={() => addItem("video")}>Video</button>
                    <button onClick={() => addItem("button")}>Button</button>
                    <button onClick={() => addItem("spacer")}>Spacer</button>
                </div>
            );
        }
        return null; // In case the user is not an admin, nothing will be rendered.
    };


    // Render top bar
    const renderTopBar = () => {
        if (user && user.role === "admin") {
            return (
                <div className="top-bar">
                    <div className="d-flex align-items-center">
                        <span
                            className="material-symbols-outlined"
                            style={{ cursor: "pointer" }}
                            onClick={handleUndo}
                            disabled={undoStack.length === 0}
                        >
                            undo
                        </span>
                        <span
                            className="material-symbols-outlined"
                            style={{ cursor: "pointer" }}
                            onClick={handleRedo} // Fixed onClick for redo
                            disabled={redoStack.length === 0}
                        >
                            redo
                        </span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <p>Page: My Grid Page</p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">

                        {user && user.role === "admin" && (
                            <button onClick={handleUpdateClick} className="btn_fill">
                                Update
                            </button>
                        )}
                    </div>
                </div>
            );
        }
        return null; // In case the user is not an admin, nothing will be rendered
    };

    // Render editor for selected item
    const renderEditorPanel = () => {
        if (user && user.role === "admin") {
            const selectedItem = items.find((item) => item.id === selectedItemId);

            if (!selectedItem) {
                return <div className="editor-panel">Select an item to edit.</div>;
            }
            return (
                <div className="editor-panel">
                    <h4>Edit: {selectedItem.type}</h4>
                    {selectedItem.type === "text" && (
                        <>
                            <RichTextEditor
                                value={editorValue}
                                onChange={setEditorValue}
                            />
                            <button onClick={() => handleSave(selectedItemId)}>Save</button>
                        </>
                    )}
                    {selectedItem.type === "image" && (
                        <>
                            <button
                                className="btn_fill"
                                onClick={() => fileInputRef.current.click()}
                            >
                                {previewImageUrl ? `Change Image` : "Upload Image"}
                            </button>
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                onChange={(e) => selectImage(e, selectedItemId)}
                            />
                        </>
                    )}
                    {selectedItem.type === "video" && (
                        <>
                            <input
                                type="file"
                                accept="video/*"
                                onChange={(e) => handleVideoUpload(e, selectedItemId)}
                            />
                            <p>Video URL: {selectedItem.videoUrl}</p>
                        </>
                    )}
                    {selectedItem.type === "button" && (
                        <>
                            <input
                                type="text"
                                value={selectedItem.pageUrl}
                                onChange={(e) => handleUrlChange(e, selectedItemId, "pageUrl")}
                            />
                            <p>Button URL: {selectedItem.pageUrl}</p>
                            <br />
                            open in new tab  <input
                                type="checkbox"
                                checked={selectedItem.newTab}
                                onChange={(e) => handleNewTabChange(e, selectedItemId)}
                            />
                            <br />
                            <input
                                type="text"
                                value={selectedItem.buttonName}
                                onChange={(e) => handleBtnNameChange(e, selectedItemId, "buttonName")}
                            />
                        </>
                    )}
                </div>
            );
        }
        return null;
    };

    // Handle URL change for button
    const handleUrlChange = (e, id, field) => {
        const updatedItems = items.map((item) =>
            item.id === id ? { ...item, [field]: e.target.value } : item
        );
        setUndoStack([...undoStack, items]);
        setRedoStack([]);
        setItems(updatedItems);
    };

    const handleNewTabChange = (e, id) => {
        const updatedItems = items.map((item) =>
            item.id === id ? { ...item, newTab: e.target.checked } : item
        );
        setUndoStack([...undoStack, items]);
        setRedoStack([]);
        setItems(updatedItems);
    };

    // Handle btn name change for button
    const handleBtnNameChange = (e, id, field) => {
        const updatedItems = items.map((item) =>
            item.id === id ? { ...item, [field]: e.target.value } : item
        );
        setUndoStack([...undoStack, items]);
        setRedoStack([]);
        setItems(updatedItems);
    };


    // Save changes to the selected item
    const handleSave = (id) => {
        const updatedItems = items.map((item) =>
            item.id === id
                ? { ...item, content: editorValue.toString("html") }
                : item
        );
        setUndoStack([...undoStack, items]);
        setRedoStack([]);
        setItems(updatedItems);
        updateDocument("new-grid-page", { items: updatedItems });
    };

    const selectImage = (e, id) => {
        const file = e.target.files[0];
        if (file) {
            // Create a URL for the selected image
            const imageUrl = URL.createObjectURL(file);
            setPreviewImageUrl(imageUrl); // Update preview URL state

            // Optionally, you can update the imageUrl field in the item
            const updatedItems = items.map((item) =>
                item.id === id ? { ...item, imageUrl } : item
            );
            setUndoStack([...undoStack, items]);
            setRedoStack([]);
            setItems(updatedItems);
        }
    };


    // Handle image upload
    const handleImageUpload = async (e, id) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            try {
                // Get the document ID for the folder name (assuming it's the "new-grid-page" doc ID)
                const docId = "new-grid-page"; // Change this if your document ID is dynamic

                // Upload the image to Firebase Storage inside a folder with the document ID
                const filePath = `${docId}/${file.name}`;
                const imageUrl = await uploadImage(file, filePath);

                // Update the imageUrl in the selected item
                const updatedItems = items.map((item) =>
                    item.id === id
                        ? { ...item, imageUrl } // Set the imageUrl with the newly uploaded URL
                        : item
                );

                // Update local state and Firestore
                setUndoStack([...undoStack, items]);
                setRedoStack([]);
                setItems(updatedItems);

                console.log("Image uploaded and URL updated:", imageUrl);
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }
    };

    const uploadImage = async (file, path) => {
        const storageRef = projectStorage.ref(`images/${path}`);
        const uploadTask = storageRef.put(file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                null,
                (error) => reject(error), // Handle error
                async () => {
                    // Get the download URL after successful upload
                    const url = await uploadTask.snapshot.ref.getDownloadURL();
                    resolve(url);
                }
            );
        });
    };


    const handleVideoUpload = (e, id) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const updatedItems = items.map((item) =>
                    item.id === id
                        ? { ...item, videoUrl: reader.result } // Update videoUrl
                        : item
                );
                setUndoStack([...undoStack, items]);
                setRedoStack([]);
                setItems(updatedItems);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpdateClick = async () => {
        try {
            // Get the latest totalCreatedElements value
            const totalCreatedElements = items.length; // This will be the total number of items in the local state

            // Update Firestore with the new items and totalCreatedElements count
            await updateDocument("new-grid-page", {
                items: items,
                totalCreatedElements: totalCreatedElements,
            });

            // Clear both undo and redo stacks
            setUndoStack([]);
            setRedoStack([]);

            console.log("Firestore updated successfully.");
        } catch (error) {
            console.error("Error updating Firestore:", error);
        }
    };

    // Handle resize stop event
    const handleResizeStop = (layout, oldItem, newItem) => {
        const updatedItems = items.map((item) => {
            if (item.id === newItem.i) {
                return { ...item, w: newItem.w, h: newItem.h };
            }
            return item;
        });
        setUndoStack([...undoStack, items]);
        setRedoStack([]); // Clear redo stack on new action
        setItems(updatedItems);
        // updateDocument("new-grid-page", { items: updatedItems });
    };

    const enableDragging = () => {
        console.log("dragging start");

        setIsDraggable(true);
        setUndoStack([...undoStack, items]);
        setRedoStack([]); // Clear redo stack on new action
    };

    // Update the suggestion box position during drag
    const handleDrag = (layout, oldItem, newItem) => {
        const redBoxPosition = {
            x: newItem.x,
            y: newItem.y,
            w: newItem.w,
            h: newItem.h,
        };
        // Add the current state to undoStack before deleting
        setUndoStack([...undoStack, items]);
        setRedoStack([]); // Clear redo stack on new action
        setSuggestionBox(redBoxPosition); // Save the red box position
    };

    // Detect collisions and adjust positions of related elements
    const handleDragStop = (layout, oldItem, newItem) => {
        const updatedItems = items.map((item) => {
            if (item.id === newItem.i) {
                return { ...item, x: newItem.x, y: newItem.y };
            }

            // Detect collision
            const isColliding =
                item.x < newItem.x + newItem.w &&
                item.x + item.w > newItem.x &&
                item.y < newItem.y + newItem.h &&
                item.y + item.h > newItem.y;

            if (isColliding) {
                // Adjust the position of the colliding item
                const updatedX = item.x + newItem.w; // Move it to the right of the dragged item
                const updatedY = item.y; // Keep the same row (or adjust further if needed)

                return { ...item, x: updatedX, y: updatedY };
            }
            setUndoStack([...undoStack, items]);
            setRedoStack([]); // Clear redo stack on new action
            return item;
        });

        setItems(updatedItems);
        // updateDocument("new-grid-page", { items: updatedItems });
    };

    // Handle delete icon click
    const handleDelete = (id) => {
        const updatedItems = items.filter((item) => item.id !== id);
        // Add the current state to undoStack before deleting
        setUndoStack([...undoStack, items]);
        setRedoStack([]); // Clear redo stack on new action

        setItems(updatedItems);
        setSelectedItemId(null);
    };


    return (
        <div className="my-grid-layout">
            {renderTopBar()}
            <div className="main-content">
                {renderSidebar()}
                <div className="grid-layout">
                    <ResponsiveGridLayout
                        className="my-grid-layout-main-div-layout"
                        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                        cols={{ lg: 12, md: 12, sm: 12, xs: 2, xxs: 2 }}
                        rowHeight={50}
                        isDraggable={true} // Enable dragging globally
                        isResizable={user && user.role === "admin"} // Only allow resizing if user is admin
                        onResizeStop={handleResizeStop}
                        onDrag={handleDrag}
                        onDragStop={handleDragStop}
                        draggableHandle=".drag-icon"
                    >
                        {items.map((item) => (
                            <div key={item.id} data-grid={{ ...item }}>
                                <div onClick={() => setSelectedItemId(item.id)} className={user && user.role === "admin" ? "my-grid-layout-main-div-layout-hover" : "my-grid-layout-main-div-layout-hover-none"}                                >
                                    {user && user.role === "admin" && (
                                        <span
                                            className="material-symbols-outlined drag-icon"
                                            onClick={enableDragging} // Enable dragging only via icon
                                            style={{ cursor: "move", zIndex: "990999", position: "absolute" }}
                                        >
                                            apps
                                        </span>
                                    )}
                                    {item.type === "text" && <div dangerouslySetInnerHTML={{ __html: item.content }} />}

                                    {item.type === "image" && (
                                        <div
                                            style={{ width: "100%", height: "100%" }}
                                            onClick={() => setSelectedItemId(item.id)}                                                                              >
                                            {item.imageUrl && (
                                                <img
                                                    src={item.imageUrl}
                                                    alt={`image ${item.id}`}
                                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                />
                                            )}
                                        </div>
                                    )}

                                    {item.type === "video" && <video src={item.content} controls style={{ width: "100%" }} />}

                                    {item.type === "button" && (
                                        user.role !== "admin" ? (
                                            <Link to={item.pageUrl} target={item.newTab ? "_blank" : "_self"}>
                                                <button className="btn_fill" style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                                                    {item.buttonName || "Button"}
                                                </button>
                                            </Link>
                                        ) : (
                                            <button className="btn_fill" style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                                                {item.buttonName || "Button"}
                                            </button>
                                        ))}

                                    {user && user.role === "admin" && (
                                        <>
                                            {selectedItemId === item.id && (
                                                <span class="material-symbols-outlined" onClick={() => handleDelete(item.id)}
                                                    style={{
                                                        position: "absolute",
                                                        top: "5px",
                                                        right: "5px",
                                                        cursor: "pointer",
                                                        zIndex: 10000,
                                                    }}
                                                >
                                                    delete
                                                </span>
                                            )}

                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: "5px",
                                                    right: "5px",
                                                    background: "rgba(0, 0, 0, 0.5)",
                                                    color: "white",
                                                    padding: "2px 5px",
                                                    borderRadius: "3px",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {`W: ${item.w || 1}, H: ${item.h || 1}`}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </ResponsiveGridLayout>
                </div>
                {renderEditorPanel()}
            </div>
        </div>
    );
};

export default MyGridLayout;

import { Route, Routes } from "react-router-dom";
import { useAuthContext } from "./firebase files/hooks/useAuthContext";
import { useEffect, useState } from "react";
// components
import Navbar from "./default components/Navbar"
import Footer from "./default components/Footer";
import BusinessThreeNavbar from "./templates/business/businessThree/businessThreeComponents/BusinessThreeNavbar";
// css
import "./TemplateOneCss/CssOne.scss";

// Home Two
import Home from "./default pages/home/home one/Home";

import "./App.css";

import FooterUpper from "./default components/FooterUpper";
import { useDocument } from "./firebase files/hooks/useDocument";
import GlobalRoute from "./global route/GlobalRoute";
import PGBusinessThreeProfile from "./templates/business/businessThree/businessThreePages/PGBusinessThreeProfile";
import PGAboutUs from "./default pages/about us/PGAboutUs";

function RouteOne() {
  const { user } = useAuthContext();

  const [currentModeStatus, setCurrentModeStatus] = useState("dark");

  const { document: dbTextContentDocuments, error: dbTextContentError } =
    useDocument("settings", "PWA");

  const { document: dbDisplayModeDocuments, error: dbDisplayModeError } =
    useDocument("settings", "mode");

  const { document: dbLaunchingSoonDocuments, error: dbLaunchingSoonError } =
    useDocument("settings", "launchingPage");

  const { document: dbPagesControlDocuments, error: dbPagesControlError } =
    useDocument("settings", "pagesControl");

  const { document: loginPopupDocuments, error: loginPopupError } = useDocument(
    "settings",
    "LoginPopup"
  );

  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );

  const { document: navTypeDoc } = useDocument("settings", "navbarType");


  const { document: dbTemplatesDocuments, error: dbTemplatesError } =
    useDocument("settings", "templates");

  // const [isLoading, setIsLoading] = useState(true);
  const [activeTemplate, setActiveTemplate] = useState(null);

  useEffect(() => {
    const fetchActiveTemplate = async () => {
      try {
        // Access the activeTemplate value from the fetched document
        if (dbTemplatesDocuments) {
          setActiveTemplate(dbTemplatesDocuments.activeTemplate);
        }
      } catch (error) {
        console.error("Error fetching active template:", error);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchActiveTemplate();
  }, [dbTemplatesDocuments]);

  // PWA CODE START
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  function startChromeInstall() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    setinstallPopupDiv(false);
  }

  const [installPopupDiv, setinstallPopupDiv] = useState(true);
  const closeInstallPopup = () => {
    setinstallPopupDiv(false);
  };
  // PWA CODE END

  useEffect(() => {
    //First time set display mode from db to useState variable and also store the same value in localStorage
    if (
      localStorage.getItem("mode") === null ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      // console.log('dbdisplaymode: ', dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode)
      setCurrentModeStatus(
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
      localStorage.setItem(
        "mode",
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
    }
    // Function to handle changes in localStorage
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("mode");
      setCurrentModeStatus(storedValue);
    };

    // Call the function initially
    handleStorageChange();

    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dbDisplayModeDocuments]);

  return (
    <>
      {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? <Navbar /> : <BusinessThreeNavbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
            path="/profile"
            element={<PGBusinessThreeProfile />}
          />
          <Route path="/aboutus" element={<PGAboutUs/>}/>
      </Routes>
      <GlobalRoute />
      <FooterUpper />
      <Footer />
    </>
  );
}

export default RouteOne;

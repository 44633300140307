import React, { useEffect, useState, useRef } from 'react';
import "./AppStoreOneCounter.scss";
import { useDocument } from '../../../../firebase files/hooks/useDocument';

const AppStoreOneCounter = () => {
  // Fetch counters from Firestore
  const { document: counterNumbers, error: counterNumbersError } = useDocument(
    "counters",
    "homeCounter"
  );

  const [isVisible, setIsVisible] = useState(false); // To track if the section is in view
  const counterRef = useRef(null); // Ref for the counter section

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Section is visible
        } else {
          setIsVisible(false); // Section is out of view
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  // Prepare data for rendering
  const counters = counterNumbers
    ? Object.entries(counterNumbers)
        .filter(([key, value]) => typeof value === "object" && value?.number)
        .map(([key, counter]) => {
          const numberString = counter?.number || "0";
          const match = numberString.match(/^(\d+)([a-zA-Z]*)$/); // Separate number and suffix
          const number = match ? parseInt(match[1], 10) : 0;
          const suffix = match ? match[2] : "";

          return {
            id: key,
            iconName: counter?.iconName || "help_outline", // Default icon
            name: counter?.name || "Unknown",
            number,
            suffix,
          };
        })
    : [];

  // Counter animation logic
  const animateValue = (start, end, duration, callback) => {
    let startTime = null;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      const value = Math.floor(progress * (end - start) + start);
      callback(value);

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  };

  return (
    <div ref={counterRef} className="app-store-one-counter-parent">
      <div className="app-store-one-counter-counter-stats">
        {counters.map(({ id, iconName, name, number, suffix }) => (
          <CounterItem
            key={id}
            iconName={iconName}
            name={name}
            number={number}
            suffix={suffix}
            isVisible={isVisible}
            animateValue={animateValue}
          />
        ))}
      </div>
    </div>
  );
};

// CounterItem Component
const CounterItem = ({ iconName, name, number, suffix, isVisible, animateValue }) => {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    if (isVisible) {
      animateValue(0, number, 2000, setCurrentNumber); // Animate from 0 to `number` over 2 seconds
    }
  }, [isVisible, number, animateValue]);

  return (
    <div className="app-store-one-counter-stat-item">
      <div className="app-store-one-counter-icon">
        <span dangerouslySetInnerHTML={{ __html: iconName }} />
      </div>
      <h3>
        {currentNumber}
        {suffix}+
      </h3>
      <p>{name}</p>
    </div>
  );
};

export default AppStoreOneCounter;

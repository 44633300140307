import React, { useState } from "react";
import "./AppStoreOneVideo.scss";

const AppStoreOneVideo = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hover state for each card

  const features = [
    {
      title: "No-Code Visual Builder",
      description:
        "Drag, drop and design everything using Divi's visual interface without having to be an experienced designer or developer.",
      icon: "M320-240 80-480l240-240 57 57-184 184 183 183-56 56Zm320 0-57-57 184-184-183-183 56-56 240 240-240 240Z",
    },
    {
      title: "Hundreds Of Content Elements",
      description:
        "It’s easy to build any type of website thanks to Divi's large collection of content elements.",
      icon: "M120-120v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Z",
    },
    {
      title: "300+ Pre-Built Website Templates",
      description:
        "Get a website up and running quickly thanks to our huge collection of pre-made designs.",
      icon: "M120-280v-400q0-33 23.5-56.5T200-760h560q33 0 56.5 23.5T840-680v400q0 33-23.5 56.5T760-200H200q-33 0-56.5-23.5T120-280Zm293-240h347v-160H413v160Zm214 240h133v-160H627v160Zm-214 0h134v-160H413v160Zm-213 0h133v-400H200v400Z",
    },
    {
      title: "Ecommerce Integration",
      description:
        "Build and customize your online store with dozens of ecommerce modules and thousands of design settings.",
      icon: "M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z",
    },
    {
      title: "Marketing & Lead Generation Tools",
      description:
        "Boost conversions with tools like A/B Testing, Condition Options, and support for dozens of email marketing services.",
      icon: "M160-720v-80h640v80H160Zm0 560v-240h-40v-80l40-200h640l40 200v80h-40v240h-80v-240H560v240H160Zm80-80h240v-160H240v160Zm-38-240h556-556Zm0 0h556l-24-120H226l-24 120Z",
    },
    {
      title: "24/7 Live Chat Support",
      description:
        "Build with confidence knowing you have a support team with years of experience in your corner.",
      icon: "M440-120v-80h320v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v244h-40q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H440Zm-80-280q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z",
    },
  ];

  return (
    <div className="app-store-one-video-container">
      <div className="container">
      <div className="app-store-one-video-section">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="app-store-one-video-main-video"
          >
            <source src="https://www.elegantthemes.com/videos/divi-builder-reel.mp4" />
          </video>
        </div>
        <div className="app-store-one-video-features">
          {features.map((feature, index) => (
            <div
              className="app-store-one-video-feature"
              key={index}
              onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
              onMouseLeave={() => setHoveredIndex(null)} // Reset hover
            >
              <div className="app-store-one-video-single-service">
                <div className="app-store-one-video-content">
                  <span className="app-store-one-video-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 -960 960 960"
                      height="30"
                      width="30"
                      style={{
                        fill:
                          hoveredIndex === index
                            ? "var(--logo-primary-color)"
                            : "var(--white-color)", // Change only hovered icon
                        transition: "fill 0.3s ease",
                      }}
                    >
                      <path d={feature.icon} />
                    </svg>
                  </span>
                  <h3 className="app-store-one-video-title">
                    {feature.title}
                  </h3>
                  <p className="app-store-one-video-description">
                    {feature.description}
                  </p>
                </div>
                <span className="app-store-one-video-circle-before"></span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppStoreOneVideo;

import React from 'react';
import { useParams } from 'react-router-dom';
import './AdminOrdersDetail.scss';
import { useDocument } from '../../../../firebase files/hooks/useDocument';
import {
    Paper,
    Grid,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Divider,
    Box,
    Chip,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const orderStages = ['orderPlaced', 'itemsPickedUp', 'processing', 'delivered'];

const AdminOrdersDetail = () => {
    const navigate = useNavigate();

    const { orderId } = useParams();
    const { document: order, error: orderError } = useDocument('orders', orderId);

    if (orderError) {
        return <div className="error">Error: {orderError}</div>;
    }

    if (!order) {
        return <div className="loading"></div>;
    }

    // Helper function for fallback values
    const getFallbackValue = (value, fallback = 'N/A') => value || fallback;

    // Get current stage index for stepper
    const currentStageIndex = orderStages.indexOf(order.currentOrderStage);

    // Format date helper
    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        return moment(timestamp.toDate()).format('DD/MM/YYYY hh:mm A');
    };

    return (
        <div className='admin-orders-detail'>
            <div className='admin-orders-detail-header'>



                <button className='admin-orders-detail-header-back-button' onClick={() => navigate(-1)}>Back</button>


                <h1>
                    Order Details #{getFallbackValue(order.barcode?.number)}
                </h1>
            </div>

            {/* Order Status and Timeline */}
            <Paper elevation={3} className="section">
                <Typography variant="h6" gutterBottom>Order Progress</Typography>
                <Stepper activeStep={currentStageIndex} alternativeLabel>
                    {orderStages.map((stage) => (
                        <Step key={stage}>
                            <StepLabel>{stage.replace(/([A-Z])/g, ' $1').toLowerCase()}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Paper>

            <Grid container spacing={3} className="mt-20">
                {/* Order Info */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} className="section">
                        <Typography variant="h6" gutterBottom>Order Information</Typography>
                        <Box>
                            <Typography><strong>Order Date:</strong> {formatDate(order.createdAt)}</Typography>
                            <Typography><strong>Order Status:</strong>
                                <Chip
                                    label={getFallbackValue(order.orderStatus)}
                                    color={order.orderStatus === 'completed' ? 'success' : 'primary'}
                                    size="small"
                                    className="status-chip"
                                />
                            </Typography>
                            <Typography><strong>Payment Method:</strong> {getFallbackValue(order.paymentMethod).toUpperCase()}</Typography>
                            <Typography><strong>Total Amount:</strong> ₹{getFallbackValue(order.totalPrice)}</Typography>
                        </Box>
                    </Paper>
                </Grid>

                {/* Shipping Address */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} className="section">
                        <Typography variant="h6" gutterBottom>Shipping Address</Typography>
                        <Box>
                            <Typography>{getFallbackValue(order.shippingAddress?.name)}</Typography>
                            <Typography>{getFallbackValue(order.shippingAddress?.streetLineOne)}</Typography>
                            <Typography>
                                {getFallbackValue(order.shippingAddress?.city)}, {getFallbackValue(order.shippingAddress?.state)}
                            </Typography>
                            <Typography>PIN: {getFallbackValue(order.shippingAddress?.pincode)}</Typography>
                            <Typography><strong>Type:</strong> {getFallbackValue(order.shippingAddress?.type)}</Typography>
                        </Box>
                    </Paper>
                </Grid>

                {/* Products Table */}
                <Grid item xs={12}>
                    <Paper elevation={3} className="section">
                        <Typography variant="h6" gutterBottom>Order Items</Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product Name</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order.products.map((product, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{getFallbackValue(product.name)}</TableCell>
                                            <TableCell align="right">{getFallbackValue(product.quantity)}</TableCell>
                                            <TableCell align="right">₹{getFallbackValue(product.price)}</TableCell>
                                            <TableCell align="right">
                                                ₹{getFallbackValue(product.price * product.quantity)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={3} align="right"><strong>Total Amount</strong></TableCell>
                                        <TableCell align="right"><strong>₹{order.totalPrice}</strong></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default AdminOrdersDetail;
import React, { useEffect, useState } from "react";
import "./OrdersPage.scss";
import { useDocument } from "../firebase files/hooks/useDocument";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import useOrders from "../firebase files/hooks/useOrders";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { pdf } from "@react-pdf/renderer";
import OrderReceipt from "./OrderReceipt";
import { useAdminOrders } from "../firebase files/hooks/useAdminOrders";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { timestamp } from "../firebase config/config";
import StaffAssignments from "./StaffAssignment";

// Define a consistent stage mapping object at the top of the file
const STAGE_MAPPING = {
  "Order Placed": "orderPlaced",
  "Items Picked Up": "itemsPickedUp",
  Processing: "processing",
  Delivered: "delivered",
};

// Add this helper function at the top of the file, outside the component
const getDateRangeStart = (period) => {
  const now = new Date();
  switch (period) {
    case "thisMonth":
      return new Date(now.getFullYear(), now.getMonth(), 1);
    case "1month":
      return new Date(now.setMonth(now.getMonth() - 1));
    case "3months":
      return new Date(now.setMonth(now.getMonth() - 3));
    case "6months":
      return new Date(now.setMonth(now.getMonth() - 6));
    case "thisYear":
      return new Date(now.getFullYear(), 0, 1);
    case "2023":
      return new Date("2023-01-01");
    case "2022":
      return new Date("2022-01-01");
    default:
      return null;
  }
};

// Add this helper function at the top of the file with other helpers
const getStageNumber = (currentOrderStage) => {
  const stageOrder = [
    "orderPlaced",
    "itemsPickedUp",
    "processing",
    "delivered",
  ];
  return currentOrderStage ? stageOrder.indexOf(currentOrderStage) + 1 : 1;
};

const OrdersPage = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const { orders: userOrders, assignOrder, addTimelineEvent } = useOrders();
  const { orders: adminOrders } = useAdminOrders();

  const { updateDocument } = useFirestore("orders");

  const [searchTerm, setSearchTerm] = useState("");

  // Use admin orders if user is admin, otherwise use user orders
  const orders = user.role === "admin" ? adminOrders : userOrders;

  const { document: currencyDocument, error: currencyError } = useDocument(
    "settings",
    "currency"
  );

  const { documents: usersDocuments, error: usersError } =
    useCollection("users");

  console.log(usersDocuments);

  const [isLoading, setIsLoading] = useState(true);

  // State to track which order's product list is open
  const [openOrderId, setOpenOrderId] = useState(null);

  // Add new state for order status
  const [orderStatus, setOrderStatus] = useState("all");

  // Replace orderDate state with timePeriod
  const [timePeriod, setTimePeriod] = useState("all");

  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedOrderForAssignment, setSelectedOrderForAssignment] =
    useState(null);
  const [searchUserTerm, setSearchUserTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  // Filter users based on search term
  const filteredUsers =
    usersDocuments?.filter(
      (user) =>
        user.fullName.toLowerCase().includes(searchUserTerm.toLowerCase()) ||
        user.phoneNumber.includes(searchUserTerm)
    ) || [];

  // Add this useEffect to update selectedOrderForAssignment when orders change
  useEffect(() => {
    if (selectedOrderForAssignment && orders) {
      const updatedOrder = orders.find(
        (order) => order.id === selectedOrderForAssignment.id
      );
      if (updatedOrder) {
        setSelectedOrderForAssignment(updatedOrder);
      }
    }
  }, [orders]);

  const handleAssignOrder = async () => {
    if (!selectedUser || !selectedOrderForAssignment) return;

    try {
      await assignOrder(selectedOrderForAssignment.id, selectedUser.id);

      // Add timeline event (but don't update state manually)
      // await addTimelineEvent(selectedOrderForAssignment.id, 'ORDER_ASSIGNED', {
      //   assignedToUserId: selectedUser.id,
      //   assignedToName: selectedUser.fullName
      // });

      // Reset selection states
      setSelectedUser(null);
      setSearchUserTerm("");
    } catch (error) {
      console.error("Error assigning order:", error);
    }
  };

  // Function to toggle the product list visibility
  const toggleProductList = (orderId) => {
    setOpenOrderId(openOrderId === orderId ? null : orderId);
  };

  console.log(orders);

  const [ordersData, setOrdersData] = useState(orders);

  console.log(ordersData);

  console.log("Date", timePeriod);

  // Add this with other state declarations
  const [orderType, setOrderType] = useState("all");

  const [activeTab, setActiveTab] = useState("timeline");
  const [staffRole, setStaffRole] = useState("pickup");

  const [searchStaffTerm, setSearchStaffTerm] = useState("");
  const [selectedStaff, setSelectedStaff] = useState(null);

  // Filter staff based on search term
  const filteredStaff =
    usersDocuments?.filter(
      (user) =>
        user.fullName.toLowerCase().includes(searchStaffTerm.toLowerCase()) ||
        user.phoneNumber.includes(searchStaffTerm)
    ) || [];

  const handleOrderStageChange = (newStage) => {
    // Add your logic to update order stage
    console.log("Updating order stage to:", newStage);
  };

  useEffect(() => {
    if (currencyDocument && orders && usersDocuments) {
      setOrdersData(orders);
      setIsLoading(false);
    }
  }, [currencyDocument, orders, usersDocuments]);

  useEffect(() => {
    if (orders) {
      let filteredOrders = [...orders];

      // Add order type filter
      if (orderType !== "all") {
        filteredOrders = filteredOrders.filter((order) =>
          orderType === "admin"
            ? order.adminCreatedOrder
            : !order.adminCreatedOrder
        );
      }

      // Filter by time period if not 'all'
      if (timePeriod !== "all") {
        const startDate = getDateRangeStart(timePeriod);
        const endDate = timePeriod.includes("202")
          ? new Date(parseInt(timePeriod) + 1, 0, 1)
          : new Date();

        filteredOrders = filteredOrders.filter((order) => {
          const orderDate = order.createdAt.toDate();
          return orderDate >= startDate && orderDate < endDate;
        });
      }

      // Only apply status filter if not 'all'
      if (orderStatus !== "all") {
        filteredOrders = filteredOrders.filter(
          (order) => order.orderStatus === orderStatus
        );
      }

      if (searchTerm.trim()) {
        filteredOrders = filteredOrders.filter((order) => {
          const userDetails = getUserDetails(order.createdBy);
          const searchString = searchTerm.toLowerCase();

          return (
            userDetails.fullName.toLowerCase().includes(searchString) ||
            userDetails.phoneNumber.toLowerCase().includes(searchString) ||
            order.id.toLowerCase().includes(searchString)
          );
        });
      }

      setOrdersData(filteredOrders);
    }
  }, [timePeriod, orders, orderStatus, searchTerm, orderType]);

  // Add this function to handle receipt generation and opening in new tab
  const handleReceiptClick = async (order) => {
    try {
      // Generate the PDF blob
      const blob = await pdf(
        <OrderReceipt order={order} currencySymbol={currencyDocument.symbol} />
      ).toBlob();

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Open in new tab
      window.open(url, "_blank");

      // Clean up the URL after opening
      setTimeout(() => URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  // Helper function to get user details
  const getUserDetails = (userId) => {
    if (!usersDocuments) return { fullName: "N/A", phoneNumber: "N/A" };

    const user = usersDocuments.find((user) => user.id === userId);
    return user
      ? { fullName: user.fullName, phoneNumber: user.phoneNumber }
      : { fullName: "N/A", phoneNumber: "N/A" };
  };

  // Add this helper function for date formatting
  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  };

  const handleStaffAssignment = async (role) => {
    if (!selectedStaff || !selectedOrderForAssignment) return;

    const staffField = {
      pickup: "pickupStaff",
      processing: "processingStaff",
      delivery: "deliveryStaff",
    }[role];

    try {
      await updateDocument(selectedOrderForAssignment.id, {
        [staffField]: {
          staffId: selectedStaff.id,
          assignedAt: timestamp.fromDate(new Date()),
        },
      });

      // Add timeline event (but don't update state manually)
      await addTimelineEvent(selectedOrderForAssignment.id, "STAFF_ASSIGNED", {
        role,
        staffId: selectedStaff.id,
        staffName: selectedStaff.fullName,
      });

      // Reset selection states
      setSelectedStaff(null);
      setSearchStaffTerm("");
      setEditingStaffRole((prev) => ({
        ...prev,
        [role]: false,
      }));
    } catch (error) {
      console.error("Error assigning staff:", error);
    }
  };

  const [editingStaffRole, setEditingStaffRole] = useState({
    pickup: false,
    processing: false,
    delivery: false,
  });

  const [isChangingStage, setIsChangingStage] = useState(false);
  const [pendingStage, setPendingStage] = useState(null);

  const handleStageChange = (direction) => {
    const stages = Object.values(STAGE_MAPPING);

    // Initialize orderStages if it doesn't exist
    if (!selectedOrderForAssignment.orderStages) {
      selectedOrderForAssignment.orderStages = {
        orderPlaced: {
          completed: true,
          timestamp: selectedOrderForAssignment.createdAt,
        },
        itemsPickedUp: { completed: false },
        processing: { completed: false },
        delivered: { completed: false },
      };
    }

    const currentIndex = stages.indexOf(
      selectedOrderForAssignment.currentOrderStage || "orderPlaced"
    );
    const newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;

    if (newIndex >= 0 && newIndex < stages.length) {
      setPendingStage(stages[newIndex]);
      setIsChangingStage(true);
    }
  };

  const handleStageUpdate = async () => {
    if (!pendingStage || !selectedOrderForAssignment) return;

    try {
      const stages = Object.values(STAGE_MAPPING);
      const updatedStages = {
        ...(selectedOrderForAssignment.orderStages || {
          orderPlaced: {
            completed: true,
            timestamp: selectedOrderForAssignment.createdAt,
          },
          itemsPickedUp: { completed: false },
          processing: { completed: false },
          delivered: { completed: false },
        }),
      };

      // Mark all previous stages as completed
      const pendingStageIndex = stages.indexOf(pendingStage);

      stages.forEach((stage, index) => {
        if (index <= pendingStageIndex) {
          updatedStages[stage] = {
            completed: true,
            timestamp:
              index === pendingStageIndex
                ? timestamp.fromDate(new Date())
                : updatedStages[stage]?.timestamp ||
                selectedOrderForAssignment.createdAt,
          };
        } else {
          // Ensure future stages are marked as not completed
          updatedStages[stage] = {
            completed: false,
            timestamp: null,
          };
        }
      });

      const updateData = {
        currentOrderStage: pendingStage,
        orderStages: updatedStages,
      };

      // Update order stages
      await updateDocument(selectedOrderForAssignment.id, updateData);

      // Add timeline event (but don't update state manually)
      await addTimelineEvent(selectedOrderForAssignment.id, "STAGE_CHANGED", {
        fromStage: selectedOrderForAssignment.currentOrderStage,
        toStage: pendingStage,
      });

      // Only update the stage-related state
      setIsChangingStage(false);
      setPendingStage(null);
    } catch (error) {
      console.error("Error updating order stage:", error);
    }
  };

  // Render timeline content
  const renderTimelineContent = () => {
    const timeline = selectedOrderForAssignment?.timeline || [];

    if (timeline.length === 0) {
      return (
        <div className="empty-timeline">
          <span className="material-symbols-outlined">history</span>
          <p>No timeline events yet</p>
        </div>
      );
    }

    return (
      <div className="order-timeline">
        {timeline.map((event) => (
          <div key={event.id} className="timeline-event">
            <div className="timeline-icon">
              <span className="material-symbols-outlined">
                {getEventIcon(event.eventType)}
              </span>
            </div>
            <div className="timeline-content">
              <div className="event-header">
                <h4>{getEventTitle(event.eventType)}</h4>
                <span className="event-time">
                  {formatDate(event.timestamp)}
                </span>
              </div>
              {renderEventDetails(event)}
              <div className="event-creator">
                by {event.createdBy.userName} , {event.createdBy.role}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Helper functions for event display
  const getEventIcon = (eventType) =>
  ({
    ORDER_CREATED: "shopping_cart",
    ORDER_ASSIGNED: "person_add",
    STAGE_CHANGED: "timeline",
    STAFF_ASSIGNED: "badge",
    PICKUP_COMPLETED: "local_shipping",
    DELIVERY_COMPLETED: "local_post_office"
  }[eventType] || "info");

  const getEventTitle = (eventType) => {
    const titles = {
      ORDER_CREATED: "Order Created",
      ORDER_ASSIGNED: "Order Assigned",
      STAGE_CHANGED: "Stage Updated",
      STAFF_ASSIGNED: "Staff Assigned",
      PICKUP_COMPLETED: "Items Picked Up",
      DELIVERY_COMPLETED: "Items Delivered"
    };
    return titles[eventType] || "Event";
  };

  const renderEventDetails = (event) => {
    switch (event.eventType) {
      case "ORDER_ASSIGNED":
        return (
          <p>
            Order assigned to{" "}
            {getUserDetails(event.details.assignedToUserId).fullName}
          </p>
        );
      case "STAGE_CHANGED":
        return (
          <p>
            Order moved from <strong>{event.details.fromStage}</strong> to{" "}
            <strong>{event.details.toStage}</strong>
          </p>
        );
      case "STAFF_ASSIGNED":
        return (
          <p>
            {event.details.role.charAt(0).toUpperCase() +
              event.details.role.slice(1)}{" "}
            staff assigned: <strong>{event.details.staffName}</strong>
          </p>
        );
      default:
        return null;
    }
  };

  // Modify how orders are rendered to handle incomplete orders
  const renderOrderItem = (order) => {
    // Check if order has complete barcode data
    const isBarcodeReady = order.barcode && order.barcode.imageUrl;

    return (
      <div className="order-item" key={order.id}>
        <div className="order-item-header">
          <div className="order-header-left">
            <h5># {order.id}</h5>
            {user.role === "admin" && (
              <div className="order-user-info">
                {order.adminCreatedOrder ? (
                  <>
                    <span className="user-name">
                      <i className="material-symbols-outlined">
                        person
                      </i>
                      Admin Created Order
                    </span>
                    <span
                      className="assign-user-icon"
                      onClick={() => {
                        setSelectedOrderForAssignment(order);
                        setShowAssignModal(true);
                      }}
                    >
                      <i className="material-symbols-outlined">
                        {order.orderAssignedTo
                          ? "view_timeline"
                          : "person_add"}
                      </i>
                      {order.orderAssignedTo
                        ? "Admin center"
                        : "Assign User"}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="user-name">
                      <i className="material-symbols-outlined">
                        person
                      </i>
                      {getUserDetails(order.createdBy).fullName}
                    </span>
                    <span className="user-phone">
                      <i className="material-symbols-outlined">
                        phone
                      </i>
                      {getUserDetails(order.createdBy).phoneNumber}
                    </span>
                    <span
                      className="assign-user-icon"
                      onClick={() => {
                        setSelectedOrderForAssignment(order);
                        setShowAssignModal(true);
                      }}
                    >
                      <i className="material-symbols-outlined">view_timeline</i>
                      Admin center
                    </span>
                  </>
                )}
              </div>
            )}
          </div>

          <div className="order-item-header-info">
            <div>
              <span>Order Placed On</span>
              <span>
                {new Intl.DateTimeFormat("en-IN", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }).format(order.createdAt.toDate())}
              </span>
            </div>

            <div>
              <span>Order Status</span>
              <span style={{ textTransform: "capitalize" }}>
                {order.orderStatus}
              </span>
            </div>

            <div>
              <span>Order Value</span>
              <span>
                {currencyDocument.symbol} {order.totalPrice}
              </span>
            </div>

            <div>
              <span>Payment Method</span>
              <span style={{ textTransform: "uppercase" }}>
                {order.paymentMethod}
              </span>
            </div>
          </div>
        </div>

        <div className="order-item-body">
          <div className="order-process-status">
            <div
              className="main-status-tracker"
              style={{
                "--current-stage": getStageNumber(
                  order.currentOrderStage
                ),
              }}
            >
              <div className="main-status-bar"></div>
              {[
                "Order Placed",
                "Items Picked Up",
                "Processing",
                "Delivered",
              ].map((stage, index) => {
                const stageKey = Object.entries(
                  STAGE_MAPPING
                ).find(([key]) => key === stage)?.[1];
                const isCompleted =
                  order.orderStages?.[stageKey]?.completed;
                const isCurrent =
                  order.currentOrderStage === stageKey;

                return (
                  <div
                    key={index}
                    className={`main-status-stage ${isCompleted ? "completed" : ""
                      } ${isCurrent ? "current" : ""}`}
                  >
                    <div className="main-status-dot"></div>
                    <span>{stage}</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="products-accordion">
            <div
              className="products-accordion-header"
              onClick={() => toggleProductList(order.id)}
            >
              <h4>
                Order Products -{" "}
                {`( ${order.products.length} )`}
              </h4>
              <span
                className={`toggle-icon ${openOrderId === order.id ? "open" : ""
                  }`}
              ></span>
            </div>
            <div
              className={`products-accordion-content ${openOrderId === order.id ? "open" : ""
                }`}
            >
              <div className="order-item-products">
                {order.products.map((product, index) => (
                  <div
                    className="order-item-product"
                    key={index}
                  >
                    <img
                      src={product.images[0]}
                      alt={product.name}
                    />
                    <div className="order-item-product-info">
                      <h3>{product.name}</h3>
                      <h3>
                        Price: {currencyDocument.symbol}{" "}
                        {product.price}
                      </h3>
                      <h3>Quantity: {product.quantity}</h3>
                      <h3>
                        Total: {currencyDocument.symbol}{" "}
                        {product.price * product.quantity}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="order-item-footer">
          <div className="order-item-footer-shipping-address">
            <span>Shipped To:</span>
            <p>
              {order.shippingAddress.name},{" "}
              {order.shippingAddress.houseNumber},{" "}
              {order.shippingAddress.streetLineOne},{" "}
              {order.shippingAddress.streetLineTwo},{" "}
              {order.shippingAddress.landmark},{" "}
              {order.shippingAddress.city},{" "}
              {order.shippingAddress.state},{" "}
              {order.shippingAddress.pincode}
            </p>
          </div>

          <div className="order-item-footer-buttons">
            <button
              className="order-item-footer-button"
              onClick={() => handleReceiptClick(order)}
            >
              <span className="material-symbols-outlined">
                receipt
              </span>
              <span>Receipt</span>
            </button>

            <button
              className="order-item-footer-button"
              onClick={() => navigate(`/order-details/${order.id}`)}
            >
              <span className="material-symbols-outlined">
                visibility
              </span>
              <span>View Details</span>
            </button>
          </div>
          <div className="order-barcode">
            {isBarcodeReady ? (
              <img
                src={order.barcode.imageUrl}
                alt={`Barcode: ${order.barcode.number}`}
                className="barcode-image"
              />
            ) : (
              <div className="barcode-loading">
                <span className="material-symbols-outlined">sync</span>
                <span>Generating QR Code...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div style={{ height: "100vh", padding: "10px" }}>
        <Skeleton count={5} height={100} className="mb-4" />
      </div>
    );
  }

  return (
    <>
      {!user ? (
        <div
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{
              backgroundColor: "var(--click-color)",
              color: "white",
              borderRadius: "20px",
              padding: "10px 20px",
              border: "none",
              outline: "none",
            }}
            onClick={() => navigate("/login")}
          >
            Please Login
          </button>
        </div>
      ) : (
        <>
          {/* Show StaffAssignments for staff members */}
          {user.role === "driver" && <StaffAssignments />}

          {/* Show regular orders page for admin and customers */}
          {user.role !== "driver" && (
            <div className="orders-page">
              <div className="orders-header">
                <h1>Orders</h1>
                <div className="search-container">
                  <input
                    type="text"
                    placeholder="Search by name, phone, or order ID..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                  />
                  <i className="material-symbols-outlined">search</i>
                </div>
              </div>

              <div className="orders-utilities">
                <div className="orders-utilities-item">
                  <p>Filter by status</p>

                  <div className="order-item-status-tabs">
                    <span
                      className={
                        orderStatus === "all" ? "active-order-status-tab" : ""
                      }
                      onClick={() => setOrderStatus("all")}
                    >
                      All
                    </span>
                    <span
                      className={
                        orderStatus === "pending"
                          ? "active-order-status-tab"
                          : ""
                      }
                      onClick={() => setOrderStatus("pending")}
                    >
                      Pending
                    </span>
                    <span
                      className={
                        orderStatus === "completed"
                          ? "active-order-status-tab"
                          : ""
                      }
                      onClick={() => setOrderStatus("completed")}
                    >
                      Completed
                    </span>
                    <span
                      className={
                        orderStatus === "cancelled"
                          ? "active-order-status-tab"
                          : ""
                      }
                      onClick={() => setOrderStatus("cancelled")}
                    >
                      Cancelled
                    </span>
                  </div>
                </div>

                <div className="orders-utilities-filter">
                  <div className="orders-utilities-filter-timeline">
                    <p>Filter by Time Period</p>
                    <select
                      value={timePeriod}
                      onChange={(e) => setTimePeriod(e.target.value)}
                      className="timeline-dropdown"
                    >
                      <option value="thisMonth">This Month</option>
                      <option value="1month">Last Month</option>
                      <option value="3months">Last 3 Months</option>
                      <option value="6months">Last 6 Months</option>
                      <option value="thisYear">This Year</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="orders-list">
                <div className="orders-list-header">
                  <h3>
                    {ordersData.length}{" "}
                    {ordersData.length === 1 ? "Order" : "Orders"} found
                  </h3>
                  {user.role === "admin" && (
                    <div className="order-type-dropdown">
                      <select
                        value={orderType}
                        onChange={(e) => setOrderType(e.target.value)}
                        className="type-dropdown"
                      >
                        <option value="all">All Orders</option>
                        <option value="normal">Normal Orders</option>
                        <option value="admin">Admin Created Orders</option>
                      </select>
                    </div>
                  )}
                </div>
                {ordersData.length > 0 ? (
                  ordersData.map((order) => renderOrderItem(order))
                ) : (
                  <h1>No Orders Found</h1>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {/* Assignment Modal */}
      {showAssignModal && (
        <div className="modal-overlay">
          <div className="assignment-modal">
            <div className="assignment-modal-header">
              <h2>Order #{selectedOrderForAssignment?.id}</h2>
              <span
                className="material-symbols-outlined close-icon"
                onClick={() => {
                  setShowAssignModal(false);
                  setSelectedUser(null);
                  setSearchUserTerm("");
                }}
              >
                close
              </span>
            </div>

            <div className="modal-tabs">
              <span
                className={`tab ${activeTab === "timeline" ? "active" : ""}`}
                onClick={() => setActiveTab("timeline")}
              >
                {selectedOrderForAssignment?.adminCreatedOrder &&
                  !selectedOrderForAssignment?.orderAssignedTo
                  ? "Assign Order"
                  : "Timeline"}
              </span>
              <span
                className={`tab ${activeTab === "stages" ? "active" : ""}`}
                onClick={() => setActiveTab("stages")}
              >
                Manage Order Stages
              </span>
              <span
                className={`tab ${activeTab === "staff" ? "active" : ""}`}
                onClick={() => setActiveTab("staff")}
              >
                Assign Staff
              </span>
            </div>

            <div className="modal-content-orders">
              {activeTab === "timeline" && (
                // Show assignment UI only for admin-created orders without assigned user
                selectedOrderForAssignment?.adminCreatedOrder &&
                  !selectedOrderForAssignment?.orderAssignedTo ? (
                  // Assignment UI
                  <>
                    <div className="search-container">
                      <input
                        type="text"
                        placeholder="Search users by name or phone..."
                        value={searchUserTerm}
                        onChange={(e) => setSearchUserTerm(e.target.value)}
                      />
                      <i className="material-symbols-outlined">search</i>
                    </div>

                    <div className="users-list">
                      {filteredUsers.map((user) => (
                        <div
                          key={user.id}
                          className={`user-item ${selectedUser?.id === user.id ? "selected" : ""
                            }`}
                          onClick={() => setSelectedUser(user)}
                        >
                          <div className="user-info">
                            <span className="material-symbols-outlined">
                              person
                            </span>
                            <div>
                              <h4>{user.fullName}</h4>
                              <p>{user.phoneNumber}</p>
                            </div>
                          </div>
                          {selectedUser?.id === user.id && (
                            <span className="material-symbols-outlined check-icon">
                              check_circle
                            </span>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="modal-actions">
                      <button
                        className="cancel-btn"
                        onClick={() => {
                          setShowAssignModal(false);
                          setSelectedUser(null);
                          setSearchUserTerm("");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="save-btn"
                        onClick={handleAssignOrder}
                        disabled={!selectedUser}
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  // Show timeline for normal orders and admin orders with assigned users
                  renderTimelineContent()
                )
              )}

              {activeTab === "stages" && (
                <div className="order-stages">
                  <div className="stepper">
                    {Object.entries(STAGE_MAPPING).map(
                      ([displayName, stageKey], index) => {
                        const orderStages =
                          selectedOrderForAssignment?.orderStages || {};
                        const isCompleted = orderStages[stageKey]?.completed;
                        const isCurrentStage =
                          selectedOrderForAssignment?.currentOrderStage ===
                          stageKey;

                        return (
                          <div
                            key={stageKey}
                            className={`stepper-item ${isCompleted ? "completed" : ""
                              } ${isCurrentStage ? "current" : ""}`}
                          >
                            <div className="step-counter">
                              {isCompleted ? (
                                <span className="material-symbols-outlined">
                                  check
                                </span>
                              ) : (
                                index + 1
                              )}
                            </div>
                            <div className="step-name">{displayName}</div>
                            {orderStages[stageKey]?.timestamp && (
                              <div className="step-timestamp">
                                {formatDate(orderStages[stageKey].timestamp)}
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>

                  {!isChangingStage ? (
                    <div className="stage-actions">
                      <button
                        className="prev-btn"
                        disabled={
                          !selectedOrderForAssignment?.currentOrderStage ||
                          selectedOrderForAssignment?.currentOrderStage ===
                          "orderPlaced"
                        }
                        onClick={() => handleStageChange("prev")}
                      >
                        <span className="material-symbols-outlined">
                          arrow_back
                        </span>
                        Previous Stage
                      </button>
                      <button
                        className="next-btn"
                        disabled={
                          selectedOrderForAssignment?.currentOrderStage ===
                          "delivered"
                        }
                        onClick={() => handleStageChange("next")}
                      >
                        Next Stage
                        <span className="material-symbols-outlined">
                          arrow_forward
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div className="stage-confirmation">
                      <p>
                        Confirm changing order stage to:{" "}
                        <strong>{pendingStage}</strong>
                      </p>
                      <div className="confirmation-actions">
                        <button
                          className="cancel-btn"
                          onClick={() => {
                            setIsChangingStage(false);
                            setPendingStage(null);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="save-btn"
                          onClick={handleStageUpdate}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {activeTab === "staff" && (
                <div className="staff-assignment">
                  <div className="staff-role-tabs">
                    <div
                      className={`role-tab ${staffRole === "pickup" ? "active" : ""
                        }`}
                      onClick={() => setStaffRole("pickup")}
                    >
                      <span className="material-symbols-outlined">
                        local_shipping
                      </span>
                      <span>Item Pickup</span>
                    </div>
                    <div
                      className={`role-tab ${staffRole === "processing" ? "active" : ""
                        }`}
                      onClick={() => setStaffRole("processing")}
                    >
                      <span className="material-symbols-outlined">
                        inventory_2
                      </span>
                      <span>Processing</span>
                    </div>
                    <div
                      className={`role-tab ${staffRole === "delivery" ? "active" : ""
                        }`}
                      onClick={() => setStaffRole("delivery")}
                    >
                      <span className="material-symbols-outlined">
                        local_post_office
                      </span>
                      <span>Delivery</span>
                    </div>
                  </div>

                  <div className="staff-selection-content">
                    {selectedOrderForAssignment?.[`${staffRole}Staff`]
                      ?.staffId && !editingStaffRole[staffRole] ? (
                      // Show assigned staff card with edit option
                      <div className="assigned-staff-card">
                        <div className="staff-info">
                          <div className="staff-avatar">
                            <span className="material-symbols-outlined">
                              person
                            </span>
                          </div>
                          <div className="staff-details">
                            <h4>
                              {
                                getUserDetails(
                                  selectedOrderForAssignment[
                                    `${staffRole}Staff`
                                  ].staffId
                                ).fullName
                              }
                            </h4>
                            <p>
                              {
                                getUserDetails(
                                  selectedOrderForAssignment[
                                    `${staffRole}Staff`
                                  ].staffId
                                ).phoneNumber
                              }
                            </p>
                          </div>
                        </div>
                        <button
                          className="edit-staff"
                          onClick={() => {
                            setEditingStaffRole((prev) => ({
                              ...prev,
                              [staffRole]: true,
                            }));
                            setSearchStaffTerm("");
                            setSelectedStaff(null);
                          }}
                        >
                          <span className="material-symbols-outlined">
                            edit
                          </span>
                        </button>
                      </div>
                    ) : (
                      <>
                        <div className="search-section">
                          <div className="search-container">
                            <input
                              type="text"
                              placeholder="Search staff by name or phone..."
                              value={searchStaffTerm}
                              onChange={(e) =>
                                setSearchStaffTerm(e.target.value)
                              }
                            />
                            <i className="material-symbols-outlined">search</i>
                          </div>
                          {editingStaffRole[staffRole] && !selectedStaff && (
                            <button
                              className="cancel-edit-btn"
                              onClick={() => {
                                setEditingStaffRole((prev) => ({
                                  ...prev,
                                  [staffRole]: false,
                                }));
                                setSearchStaffTerm("");
                              }}
                            >
                              Cancel
                            </button>
                          )}
                        </div>

                        {searchStaffTerm &&
                          filteredStaff.length > 0 &&
                          !selectedStaff && (
                            <div className="search-results">
                              {filteredStaff.map((staff) => (
                                <div
                                  key={staff.id}
                                  className="assigned-staff-card"
                                  onClick={() => setSelectedStaff(staff)}
                                >
                                  <div className="staff-info">
                                    <div className="staff-avatar">
                                      <span className="material-symbols-outlined">
                                        person
                                      </span>
                                    </div>
                                    <div className="staff-details">
                                      <h4>{staff.fullName}</h4>
                                      <p>{staff.phoneNumber}</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                        {selectedStaff && (
                          <div className="selected-staff-preview">
                            <div className="assigned-staff-card">
                              <div className="staff-info">
                                <div className="staff-avatar">
                                  <span className="material-symbols-outlined">
                                    person
                                  </span>
                                </div>
                                <div className="staff-details">
                                  <h4>{selectedStaff.fullName}</h4>
                                  <p>{selectedStaff.phoneNumber}</p>
                                </div>
                              </div>
                            </div>
                            <div className="modal-actions">
                              <button
                                className="cancel-btn"
                                onClick={() => {
                                  setSelectedStaff(null);
                                  setSearchStaffTerm("");
                                  setEditingStaffRole((prev) => ({
                                    ...prev,
                                    [staffRole]: false,
                                  }));
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="save-btn"
                                onClick={() => handleStaffAssignment(staffRole)}
                              >
                                {selectedOrderForAssignment?.[
                                  `${staffRole}Staff`
                                ]?.staffId
                                  ? "Update"
                                  : "Save"}
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrdersPage;
